.setadvisor-business-body {
  font-size: 0.9em;
  height: calc(100% - 6em);
  overflow-y: scroll;
  padding: 20px;
}

.setadvisor-business-body-row {
  padding-bottom: 40px;
}
