.eBanking-contract--basic--contract-box {
    width: 50%;
    padding: 30px;
    box-shadow: 2px 1px 15px -3px rgb(0 0 0 / 40%);
    border-radius: 10px;
    margin-top: 10px;
}

.eBanking-contract--container .react-form-radio-button-group > * {
    align-items: flex-start;
}

.eBanking-contract--wrapper {
    margin-top: 40px;
}

.eBanking-contract--contract-partner {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    padding: 30px;
}