.setadvisor-form-limit-calculation--document-preview {
  display: flex;
  align-items: center;
}

.setadvisor-form-limit-calculation--document-preview--icon {
  cursor: pointer;
  font-size: 1.7em;
  padding-right: 2rem;
  color: #666666;
}

.setadvisor-form-limit-calculation--document-preview--remove {
  cursor: pointer;
  color: #666666;
  padding-left: 1.4rem;
  font-size: 1.2em;
}

.setadvisor-form-limit-calculation--document-preview--file {
  font-size: 0.9em;
}

.setadvisor-form-limit-calculation--document-preview--file--filename {
  font-weight: 600;
}

.setadvisor-form-limit-calculation--document-preview--file--date {
  font-size: 0.9em;
}
