.workbench-dashboard-qualification-link {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--default-lila);
  cursor: pointer;
}

.workbench-dashboard-qualification-link--compact {
  padding-top: 0px;
  padding-bottom: 0px;

  text-decoration: none !important;
  color: var(--neutral-dark-grey) !important;
}

.workbench-dashboard-qualification-link--compact:hover {
  text-decoration: none !important;
  color: var(--neutral-dark-grey) !important;
}