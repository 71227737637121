.layout-header-user-profile--dropdown--themes--theme {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 2.4em;
  cursor: pointer;
}

.layout-header-user-profile--dropdown--themes--theme:hover {
  background-color: #e9ecef;
}
