.lead-board-column {
  position: relative;
}

.lead-board-column-virtualized::-webkit-scrollbar {
  display: none;
}

.lead-board-column--requesting-overlay {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: none;
}

.lead-board-column--requesting-overlay--requesting {
  display: flex;
  justify-content: center;
  padding-top: 8rem;
}
