.lead-board-link-dropdown .dropdown-menu {
  padding-left: 0.5rem;
}

.lead-board-link-dropdown .dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.lead-board-link-dropdown .dropdown-item .mdi {
  font-size: 1.2em;
  padding-right: 0.4rem;
}

.lead-board-link-dropdown .dropdown-item a:hover {
  color: unset;
  text-decoration: unset;
}

.lead-board-link-dropdown .mdi-dots-vertical {
  font-size: 1.3rem;
  cursor: pointer;
}
