.workguide-customer-relations-filter {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
}

.workguide-customer-relations-filter .form-code-select {
  width: 100%;
}
