.bpf-documents-static-documents-list--document {
  display: flex;
  align-items: center;
  border-left: unset;
  border-right: unset;
  background-color: inherit;
  padding-left: 2.8rem;
  padding-right: 3.2rem;
  width: 100%;
  justify-content: space-between;
}

.bpf-documents-static-documents-list--document .mdi::before {
  top: 2px;
}

.bpf-documents-static-documents-list--document:first-child {
  border-top: unset;
  padding-top: 0px;
}

.bpf-documents-static-documents-list--document:last-child {
  border-bottom: unset;
  padding-bottom: 0px;
}
