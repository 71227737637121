.setadvisor-limit-calculation--amount-list--item {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}

.setadvisor-limit-calculation--amount-list--item--delete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.1em;
  color: #666666;
}
