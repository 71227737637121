.workbench-overview-table-column-score {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  padding-right: 1.2em;
  padding-left: 1.2em;
  border: 1px solid #acacac;
}

.workbench-overview-table-column-score--inner {
  font-size: 0.8em;
  font-weight: bold;
}

.workbench-overview-table-column-score--ge80  {
  color: var(--leadScore100) !important;
  border-color: var(--leadScore100);
}

.workbench-overview-table-column-score--ge60 {
  color: var(--leadScore80) !important;
  border-color: var(--leadScore80);
}

.workbench-overview-table-column-score--le60 {
  color: var(--leadScore60) !important;
  border-color: var(--leadScore60);
}


