.workguide-listgroup-list-item {
  width: 100%;
}

.workguide-listgroup-checkbox {
  align-self: center;
  padding: 10px;
  height: 100%;
  font-size: 1.5em;
}

.workguide-listgroup-list-item-collapse {
  font-size: 1.4em;
  cursor: pointer;
}

.workguide-listgroup-check-disabled {
  opacity: 0.5;
}
