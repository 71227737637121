.party-remove-background {
  background-color: inherit !important;
  background: inherit !important;
}

.party-rainbow-diagonal-fast:first-child {
  background-color: inherit !important;
  background: inherit !important;
}

/* SLOW */
.party-rainbow-slow {
  /* min-height: 100%; */
  background:
  linear-gradient(rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%) 0 0/100% 200%;
  animation: rainbow 2s linear infinite;
}

.party-rainbow-horizontal-slow {
  /* min-height: 100%; */
  background:
  linear-gradient(to right, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%) 0 0/200% 100%;
  animation: rainbow-horizontal 2s linear infinite;
}

.party-blink-slow {
  animation: blink-animation 0.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 0.5s steps(5, start) infinite;
}

.party-shake-slow {
  animation: shake 2s;
  animation-iteration-count: infinite;
}

.party-wiggle-slow {
  animation: wiggle 2s;
  animation-iteration-count: infinite;
}

.party-roll-slow {
  -moz-animation-name: roll;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-name: roll;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

/* FAST */
.party-rainbow-fast {
  /* min-height: 100%; */
  background:
  linear-gradient(rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%) 0 0/100% 200%;
  animation: rainbow 1s linear infinite;
}

.party-rainbow-horizontal-fast {
  /* min-height: 100%; */
  background:
    linear-gradient(to right, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%) 0 0/200% 100%;
  animation: rainbow-horizontal 1s linear infinite;
}

.party-rainbow-diagonal-fast {
background: linear-gradient(307deg, #ff0000, rgba(79, 220, 74, 1), rgba(28, 127, 238, 1), #c300bc);
    background-size: 800% 800%;
    animation: rainbow-diagonal 1.5s ease infinite;
}

.party-blink-fast {
  animation: blink-animation 0.2s steps(5, start) infinite;
  -webkit-animation: blink-animation 0.2s steps(5, start) infinite;
}

.party-shake-fast {
  animation: shake 1.5s;
  animation-iteration-count: infinite;
}

.party-wiggle-fast {
  animation: wiggle 1s;
  animation-iteration-count: infinite;
}

.party-roll-fast {
  -moz-animation-name: roll;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-name: roll;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
}

@keyframes rainbow {
  to {
    background-position: 0 -200%
  }
}

@keyframes rainbow-horizontal {
  to {
    background-position: -200% 0
  }
}

 @keyframes rainbow-diagonal {
   0% {
     background-position: 0% 25%
   }

   25% {
    background-position: 25% 50%
   }

   50% {
     background-position: 100% 50%
   }

   100% {
     background-position: 0% 50%
   }
 }

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-webkit-keyframes roll {
  from {
    -webkit-transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg)
  }
}

@-moz-keyframes roll {
  from {
    -moz-transform: rotate(0deg)
  }

  to {
    -moz-transform: rotate(360deg)
  }
}

@keyframes roll {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

@keyframes wiggle {

  0%,
  7% {
    transform: rotateZ(0);
  }

  15% {
    transform: rotateZ(-8deg);
  }

  20% {
    transform: rotateZ(6deg);
  }

  25% {
    transform: rotateZ(-6deg);
  }

  30% {
    transform: rotateZ(4deg);
  }

  35% {
    transform: rotateZ(-2deg);
  }

  40%,
  100% {
    transform: rotateZ(0);
  }
}


@keyframes rainbow-colors {
  0% {
    color: hsl(0turn, 90%, 65%);
  }

  25% {
    color: hsl(.25turn, 90%, 65%);
  }

  50% {
    color: hsl(.5turn, 90%, 65%);
  }

  75% {
    color: hsl(.75turn, 90%, 65%);
  }

  100% {
    color: hsl(1turn, 90%, 65%);
  }
}
