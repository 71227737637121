.lead-board-filter-search {
}

.lead-board-filter-search .powersearch-select__control {
  border: 0px;
  width: auto;
  min-width: 20vw;
  max-width: 50vw;
  background-color: #E8E8E8;
  color: #818181;
  padding: 0 15px;
  transform: translate(0, 0);
  transition: all 0.5s;
  border-radius: 2px;
  overflow: hidden;
}

.lead-board-filter-search .powersearch-select__control::before {
  font-family: "Material Design Icons";
  content: "\F0349";
  -webkit-font-feature-settings: 'liga';
  font-size: 1.6em;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
  color: #969696;
}

.lead-board-filter-search .powersearch-select__control--is-focused {
  cursor: text;
}

.lead-board-filter-search .powersearch-list--title {
  color: black;
}

.lead-board-filter-search .powersearch-select__menu {
  width: 60vw;
  z-index: 99999;
}

.lead-board-filter-search .powersearch-selected--customer {
  color: #ffffff;
  background-color: #00a5d5;
  border: 0px;
}

.lead-board-filter-search .powersearch-selected--customer .powersearch-selected--divider {
  border-color: #00b0e6;
}

.lead-board-filter-search .powersearch-selected--customerTaskType {
  color: #ffffff;
  background-color: #7252ac;
  border: 0px;
}

.lead-board-filter-search .powersearch-selected--customerTaskType .powersearch-selected--divider {
  border-color: #8064b4;
}

.lead-board-filter-search .powersearch-list--customerTaskType .powersearch-list-children--item {
  color: #7252ac;
  padding-top: 10px;
  padding-bottom:10px;
}
