.customer-detail-content--financing--portfolios--portfolio-info-popover-icon {
  padding-left: 5px;
}

.customer-detail-content--financing--portfolios--portfolio-info-popover .popover {
  max-width: 500px;
}

.customer-detail-content--financing--portfolios--portfolio-info-popover--content {
  width: 40em;
}

.customer-detail-content--financing--portfolios--portfolio-info-popover--content-row {
  padding-bottom: 5px;
}