
.financing-estimation {
  margin-top: 20px;
}

.financing-estimation__toolbar {
  display: flex;
  margin-bottom: 20px;
}

.financing-estimation__toolbar .react-anchor {
  margin-right: 0;
}

.financing-estimation__fields {
  display: flex;
}

.financing-estimation__column {
  width: 400px;
  padding-right: 30px;
}
.modal-dialog.financing-estimation-info-popup {
  min-width: 1000px;
}
.modal-dialog.financing-estimation-detail-popup {
  min-width: 1000px;
}


.financing-estimation__results-table {
  margin-top: 20px;
}
