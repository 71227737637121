.notification-list-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-right: 15px;
  gap: 20px;

  .read-all-button {
    width: auto;
    display: flex;
    align-items: center;
    color: var(--neutral-dark-grey);
    cursor: pointer;
    padding-right: 1rem;
  
  }
  .read-all-button:hover {
    color: var(--default-red);
  }
}

.notification-list {
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .notification-list-card {
    overflow: hidden;
    border-radius: 4px;

    .card-header {
      border: none;
    }

    &.card {
      background-color: transparent;
      border: none;
    }


    .notification-list-header {
      cursor: pointer;
      background-color: transparent;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }

    .collapse {
      margin-left: 40px;
    }

  }

}
