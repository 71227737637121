.error-boundary-fallback--modal-header {
  border-color: #f22d54;
  color: #f22d54;
}

.error-boundary-fallback--modal-header .mdi-alert-circle-outline {
  vertical-align: middle;
  padding-right: 0.5em;
}

.error-boundary-fallback--modal-body {
  padding: 2em 3em 2em 3em;
}

.error-boundary-fallback--description-container {
  display: flex;
  flex-direction: column;
}

.error-boundary-fallback--button-container {
  padding-top: 2em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}