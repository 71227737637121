
  .button__box {
    text-align: center;
    color: #fff;
    padding: 13px 5px 10px;
    border-radius: 2px;
    height: 40px;
  }

  .button__box h5 {
    font-size: 16px;
  }

  .button__box p {
    font-size: 11px;
  }

  .button__box .box__inner {
    padding: 3px;
    height: 100%;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box--light .box__inner {
    border: 1px solid var(--neutral-dark-grey);;
    color: #212121;
  }
  .box--success .box__inner {

    border: 1px solid #9baa00;
    color: #fff;
  }
  .box--warning .box__inner {

    border: 1px solid #f3a91b;
    color: #fff;
  }
  .box--danger .box__inner {

    border: 1px solid #f22d54;
    color: #fff;
  }
  .box--default .box__inner {

    border: 1px solid #49a3d0;
    color: #fff;
  }

  .box--valiant .box__inner {

    border: 1px solid #6D53A7;
    color: #fff;
  }


  .box--light .box__inner__text {
    color: #212121;
  }
  .box--success .box__inner__text {
    color: #9baa00;
  }
  .box--warning .box__inner__text {
    color: #f3a91b;
  }
  .box--danger .box__inner__text {
    color: #f22d54;
  }
  .box--default .box__inner__text {
    color: #49a3d0;
  }

  .box--valiant .box__inner__text {
    color: #6D53A7;
  }
