.workbench-overview-table-column-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.workbench-overview-table-column-actions--action {
  font-size: 1.1em;
  cursor: pointer;
}

.workbench-overview-table-column-actions--action-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.workbench-overview-table-column-actions--action :first-child {
  padding-left: 5px;
}
