.customer-detail-header-customer-info {
  width: auto;
}

.customer-detail-header-customer-info--designation {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 1rem;
}

.customer-detail-header-customer-info--designation row div {
  width: auto;
}

.customer-detail-header-customer-info--designation--container{
  display: flex;
}

.customer-detail-header-customer-info--sub {
  display: flex;
  align-items: center;
}

.customer-detail-header-customer-info--actions {
    display: flex;
    align-items: center;
}
