.quick-filters--filter {
  display: flex;
  padding: 0px 12px;
  text-align: center;
  align-items: center;
  border-radius: 25px;
  font-weight: 700;
  justify-content: center;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border: 1px solid;
  text-transform: uppercase;
  min-height: 38px;
}

.quick-filters--filter .mdi-close-circle-outline::before {
  position: inherit;
}

.quick-filters--filter--label {
  display: flex;
  align-items: center;
}
.quick-filters--filter-disabled {
  opacity: 0.5;
  cursor: wait;
}

.quick-filters--filter--delete-icon {
  padding-left: 10px;
  cursor: pointer;
  font-size: 21px;
  display: flex;
  justify-content: center;
}

.quick-filters--filter--delete-icon .mdi::before {
  position: inherit;
}
