.certification-levels {
    display: flex;

    &__badge {
        display: inline-block;
        padding: 0.2em 0.5em;
        margin: 0.5em;
        border-radius: 0.25em;
        color: white;
        font-weight: bold;

        &:first-child {
            margin-left: 0;
        }

        &-lila {
            background-color:  var(--default-lila);
        }

        &-blue {
            background-color: var(--default-blue);;
        }

        &-green {
            background-color:  var(--default-green);
        }
    }
}
