.lead-board-lead-footer--right--linked-leads {
  font-size: 1.2rem;
  padding-left: 1.5rem
}

.lead-board-lead-footer--right--linked-leads--popover .popover {
  max-width: 40%;
}

.lead-board-lead-footer--right--linked-leads--popover--title {
  font-size: 1.1em;
  font-weight: bold;
  padding-bottom: 1.4rem;
}

.lead-board-lead-footer--right--linked-leads--popover--leads
.mdi::before {
  vertical-align: unset;
}
