.bpfdocument-customer-overview--row {
  padding-bottom: 3rem;
}

.bpfdocument-customer-overview--row--designation {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.bpfdocument-customer-overview--button {
  margin-right: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-size: 0.85em;
  line-height: 12px;
  font-weight: normal;
}

.bpfdocument-customer-overview--button--loading {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.bpfdocument-customer-overview--row{
  .react-table {
    .react-form-checkbox {
      .react-form-checkbox-icon {
        color: var(--default-blue);
      }
    }

    .document-preview-table-action-align-left{
      .table-column-actions{
        justify-content: flex-start;
      }
    }
  }
}

.bpfdocument-customer-overview--button-col {
  padding: 0;
}

.customer-detail-page .bpfdocument-customer-overview--title {
  margin-bottom: 2em;

}

.customer-detail-page .bpfdocument-customer-overview--title hr {
  display: none

}