.bpf-cms-profile-completion-fooby {
  position: relative;
}

.bpf-cms-profile-completion-error-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
}

.bpf-cms-profile-completion-error-overlay--content {
  text-align: center;
  color: #911b32;
}