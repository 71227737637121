.layout-header-user-profile--dropdown-item--limitations--summary {
  padding-left: 1.2em;
}

.layout-header-user-profile--dropdown-item--limitations--summary .error-status-badge {
  font-size: 0.5em;
}

.layout-header-user-profile--dropdown-item--limitations--summary .error-status-badge .error-status-badge--count {
  display: block;
  max-width: 0.5em;
  visibility: hidden;
}
