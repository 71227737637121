:root {
  --simulationCapitalColor: #ffce2d;
  --bs-simulationCapitalColor: #ffce2d;
  --simulationCalculatedColor: #5fa8e2;
  --bs-simulationCalculatedColor: #5fa8e2;
  --wealthOverviewChart1: #41538f;
  --bs--wealthOverviewChart1: #41538f;
  --wealthOverviewChart2: #5fa8e2;
  --bs--wealthOverviewChart2: #5fa8e2;
  --wealthOverviewChart3: #ff8640;
  --bs--wealthOverviewChart3: #ff8640;
  --wealthOverviewChart4: #62a769;
  --bs--wealthOverviewChart4: #62a769;
  --workguideStepColor: #6D53A7;
  --bs--workguideStepColor: #6D53A7;
  --workguideStepColorDisabled: #a38fc9;
  --bs--workguideStepColorDisabled: #a38fc9;
  --leadScore100: #de3e54;
  --leadScore80: #e7aa3f;
  --leadScore60: #9ca631;
  --cardBorder:  0.01em solid rgba(0, 0, 0, 0.04);
  --cardShadow: 13px 12px 18px -3px rgba(0,0,0,0.05);
  --cardBorderRadius: 4px;
  --backdrop-color: #66666690;
  --neutral-black: #323232;
  --neutral-dark-grey: #666666;
  --card-secondary-background-color: #66666615;
  --default-green: #D3E349;
  --default-lila: #6D53A7;
  --default-blue:  #49A3D0;
  --default-red:  #de3e54;
  --beatloader-color: #49A3D0;
  --expanded-row-background: 73, 163, 208;
  --expanded-row-content-background: 73, 163, 208;
  /* AG-Grid styling */
  --ag-primary-color: var(--default-lila);
  --ag-secondary-color: var(--default-blue);
  --ag-checkbox-checked-color: var(--default-lila);
  /* Form colors */
  --form-color-primary: #558baf;
  --form-color-primary-light: #bbd5fa;
  --form-color-primary-lighter: #cadffb;
  --form-color-secondary: #333333;
  --form-color-secondary-light: #8c8c8c;
  --form-color-secondary-lighter: #9d9d9d;
  --form-color-light: #ebebeb;
  --form-border-light: var(--form-color-light);
  --form-border-dark: var(--form-color-secondary);
  --form-border-active: var(--form-color-primary);
  --form-border-erroneous: #f22d54;
}
.ag-theme-material {
  --ag-material-primary-color: var(--default-lila) !important;
  /* --ag-material-secondary-color: var(--default-blue) !important; */
  --ag-material-accent-color: var(--default-lila) !important;
}

body {
  min-width: 1200px;
  background: #fff;
  font-family: Objektiv, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  color: #000;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: var(--default-lila)
}

a[href^="tel"]:hover {
  text-decoration: none;
}
a[href^="mailto"]:hover {
  text-decoration: none;
}
a[href^="/customer"]:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.65em;
}

p,
ul,
ol,
dl,
hr,
table,
blockquote {
  margin-bottom: 1.3em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0;
}

ol,
ul {
  padding-left: 0rem;
}

.zoom {
  zoom: 1;
  position: relative;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.lazyload-wrapper {
  display:inline;
}

.pointer {
  cursor: pointer;
}

.text-neutral-dark-grey {
  color: var(--neutral-dark-grey);
}

.bg-neutral-dark-grey {
  background-color: var(--neutral-dark-grey);
}


/* -------------------------------------------------------------*\
  Login Backgrounds
 \* -------------------------------------------------------------*/
 .login-container--background-1 {
   background-image: url(./images/sprite/login-background-1.jpg);
 }

 .login-container--background-2 {
   background-image: url(./images/sprite/login-background-2.jpg);
 }

 .login-container--background-3 {
   background-image: url(./images/sprite/login-background-3.jpg);
 }

 .login-container--background-4 {
   background-image: url(./images/sprite/login-background-4.jpg);
 }

 .login-container--background-5 {
   background-image: url(./images/sprite/login-background-5.jpg);
 }

 .login-container--background-6 {
   background-image: url(./images/sprite/login-background-6.jpg);
 }

 .login-container--background-7 {
   background-image: url(./images/sprite/login-background-7.jpg);
 }

 .login-container--background-8 {
   background-image: url(./images/sprite/login-background-8.jpg);
 }

 /* -------------------------------------------------------------*\
  Icon v-align overwrites
\* -------------------------------------------------------------*/
.mdi-icon-valign-top .mdi::before {
  vertical-align: top !important;
}

.mdi-icon-valign-baseline .mdi::before {
  vertical-align: baseline !important;
}

/* -------------------------------------------------------------*\
  Delete and Favicons
\* -------------------------------------------------------------*/
.deletex {
  color: lightslategray;
  cursor: pointer;
}

.deleteIcon:hover {
  color: red;
}

.deleteIconDisabled {
  color: lightgray;
  cursor: default;
}

.activeIcon {
  opacity: 0.2;
}

.activeIcon:hover {
  opacity: 1;
  color: #00a5d5;
}

/* -------------------------------------------------------------*\
  Bootstrap 5 Fixes after update
\* -------------------------------------------------------------*/
.form-group {
  margin-bottom: 1rem;
}

.workguide-step-count {
  color: #ffffff;
}

.ml-3, .mx-3 {
  margin-left: 1rem!important;
}

.entris-workguide-content .spacer-small {
  margin: 2rem 0;
}

.form-control.is-invalid {
  z-index: unset !important;
}

.btn-primary {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-outline-primary {
  color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-green {
  background-color: var(--default-green);
  border-color: var(--default-green);
}

/* --------------------------------------------------------------*\
  React Table
\* --------------------------------------------------------------*/
.react-table-bootstrap--table-container tbody {
  border-top: 0px !important;
}

.react-table-bootstrap .page-link {
  color: var(--bs-gray-900);
}

.react-table-bootstrap .page-link:hover {
  color: var(--bs-gray-900);
}

.react-table-bootstrap .react-table-footer-pagination--reload-icon {
  color: var(--neutral-dark-grey) !important;
}

.react-table-bootstrap .react-table-bootstrap-header-col--reload-icon {
  color: var(--neutral-dark-grey) !important;
}

.react-table-bootstrap-body-row--expanded {
  background-color: rgba(var(--expanded-row-background), 0.06);
}

.react-table-bootstrap-expanded-row {
  background-color: rgba(var(--expanded-row-content-background), 0.03);
}

.react-table-bootstrap .nav-tabs {
  border-bottom: 0.2em solid rgba(102, 102, 102, 0.25);
}

.react-table-bootstrap .nav-tabs .nav-link {
  border: 0px;
  font-weight: 600;
  color: #000000;
}

.react-table-bootstrap .nav-item .active {
  background-color: unset;
  background: rgba(var(--expanded-row-background), 0.14);
  border-bottom: 0.2em solid var(--bs-purple);
  color: var(--bs-purple);
  margin-bottom: -0.15em;
}

.react-table-bootstrap .tab-content {
  padding-top: 1.5em;
}

.react-table-bootstrap--table-container--no-data-overlay {
  font-size: 0.9em;
}

/* --------------------------------------------------------------*\
  AG Grid
\* --------------------------------------------------------------*/
.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

/* -------------------------------------------------------------*\
  Blockquotes
\* -------------------------------------------------------------*/

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eef3f6;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #858585;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: "\2014 \00A0";
}
.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eef3f6;
  border-left: 0;
}
.blockquote-reverse footer:before,
blockquote.pull-right footer:before,
.blockquote-reverse small:before,
blockquote.pull-right small:before,
.blockquote-reverse .small:before,
blockquote.pull-right .small:before {
  content: "";
}
.blockquote-reverse footer:after,
blockquote.pull-right footer:after,
.blockquote-reverse small:after,
blockquote.pull-right small:after,
.blockquote-reverse .small:after,
blockquote.pull-right .small:after {
  content: "\00A0 \2014";
}

.form-control:disabled {
  color: #333 !important;
  background-color: #f9f9f9;
}

.valiantolett {
  color: rgb(114, 82, 172);
}

.valiantolett-link {
  color: rgb(114, 82, 172);
  cursor: pointer;
}

.map-version-update {
  width: 80%;
  text-align: center;
}

/* -------------------------------------------------------------*\
  ShowInfo
\* -------------------------------------------------------------*/
.show-info {
  margin-top: 10px;
  display: block;
}

.show-info h1,
h2,
h3,
h4,
h5 {
  font-size: 1.2em;
}

.show-info img {
  max-width: 30%;
}

.show-info ul {
  padding-left: 10px;
  margin-left: 20px;
}

.show-info ol {
  padding-left: 10px;
  margin-left: 20px;
}

.show-info td,
.show-info th {
  padding-left: 10px;
}

.show-info td:last-child {
  padding-right: 10px;
}

.show-info tr {
  border-bottom: 1px solid gray;
}

.show-info a {
  text-decoration: underline;
}

/* -------------------------------------------------------------*\
  reactstrap badge
\* -------------------------------------------------------------*/
.bg-secondary {
  background-color: var(--default-blue) !important;
}

/* -------------------------------------------------------------*\
  reactstrap modal
\* -------------------------------------------------------------*/

.modal-xxl {
  max-width: 95%;
}
/* -------------------------------------------------------------*\
  reactstrap carousel
\* -------------------------------------------------------------*/
.carousel-indicators {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.carousel-indicators button {
  background-color: var(--default-blue) !important;
  width: 10px !important;
  height: 10px !important;
  border: 0;
  border-radius: 50%;
  transition: opacity .6s ease;
}

.carousel-indicators.active {
  background-color: var(--default-blue) !important;
}

.carousel-control-container {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: transparent;
  width: 6%;
  position: relative;
}

.carousel-inner {
  /* padding: 0 5%; */
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none !important;
  width: 100%;
}

.carousel-control-next-icon::after {
  font-family: "Material Design Icons";
  content: "\F0142";
  background-image: none !important;
  color: black;
  font-size: 25px;
}

.carousel-control-prev-icon::after {
  font-family: "Material Design Icons";
  content: "\F0141";
  background-image: none !important;
  color: black;
  font-size: 25px;
}

/* -------------------------------------------------------------*\
  React Anchors
\* -------------------------------------------------------------*/
.react-anchor {
  color: var(--bs-primary) !important;
  text-decoration: none;
  cursor: pointer;
}

.react-anchor-light {
  color: var(--neutral-dark-grey) !important;
  text-decoration: none;
  cursor: pointer;
}

.react-anchor:hover,
.react-anchor-light:hover {
  text-decoration: underline;
}

.anchor-respect-header {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

.sidenav {
  line-height: 2.5;
}

.sidenav i.mdi-star::before,
.sidenav i.mdi-star-outline::before {
  top: 10px;
  padding-right: 5px;
}

/* ------------------------------------------------------------ *\
	Overrule bootstrap rule to avoid ugly alignment
\* ------------------------------------------------------------ */
.dropdown {
  width: auto !important;
}

/* ------------------------------------------------------------ *\
	Dashboard Filter Menu
\* ------------------------------------------------------------ */

.filter-icon {
  position: relative;
  top: 6px;
  left: 5px;
}

/* ------------------------------------------------------------ *\
	Dashboard notes Tooltips
\* ------------------------------------------------------------ */
.show-more-tooltip {
  background: rgba(255, 255, 255, 0);
}
.show-more-tooltip-inner {
  background: rgba(255, 255, 255, 1);
  color: black;
  min-width: 400px;
  border: 1px solid black;
  text-align: left;
}
.show-more-tooltip-inner ul {
  padding-left: 20px;
}
.show-more-tooltip-inner td {
  padding-left: 10px;
}

/* ------------------------------------------------------------ *\
	Application Bootstrap
\* ------------------------------------------------------------ */
.application-bootstrap-default-loader span span {
  background-color: #49A3D0;
}

/* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */
.ico-arrow-black {
  background: url(./images/sprite/ico-arrow-black.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 16px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-arrow {
  background: url(./images/sprite/ico-arrow.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 12px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-block {
  background: url(./images/sprite/ico-block.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-cake {
  background: url(./images/sprite/ico-cake.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 12px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-calendar-large {
  background: url(./images/sprite/ico-calendar-large.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 18px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-calendar {
  background: url(./images/sprite/ico-calendar.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 12px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-calendar-import {
  background: url(./images/sprite/ico-calendar-import.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-check {
  background: url(./images/sprite/ico-check.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-close-btn {
  background: url(./images/sprite/ico-close-btn.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 42px;
  height: 42px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-close-grey {
  background: url(./images/sprite/ico-close-grey.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-close-large {
  background: url(./images/sprite/ico-close-large.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-close {
  background: url(./images/sprite/ico-close.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-clipboard {
  background: url(./images/sprite/ico-clipboard.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-dots {
  background: url(./images/sprite/ico-dots.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 14px;
  height: 6px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-file-pink {
  background: url(./images/sprite/ico-file-pink.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 20px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-file {
  background: url(./images/sprite/ico-file.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 13px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-filter {
  background: url(./images/sprite/ico-filter.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 12px;
  height: 11px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-globe {
  background: url(./images/sprite/ico-globe.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-link {
  background: url(./images/sprite/ico-link.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-oval {
  background: url(./images/sprite/ico-oval.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-phone {
  background: url(./images/sprite/ico-phone.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-plus {
  background: url(./images/sprite/ico-plus.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-search {
  background: url(./images/sprite/ico-search.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 21px;
  height: 21px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-news {
  background: url(./images/sprite/ico-news.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-news-new {
  background: url(./images/sprite/ico-news-new.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-security {
  background: url(./images/sprite/ico-security.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 17px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-tick-green {
  background: url(./images/sprite/ico-tick-green.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 24px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-tick-grey {
  background: url(./images/sprite/ico-tick-grey.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 24px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-tick {
  background: url(./images/sprite/ico-tick.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 16px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-user-white {
  background: url(./images/sprite/ico-user-white.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 17px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-tab-2 {
  background: url(./images/sprite/ico-tab-2.svg) no-repeat 0 0;
  background-size: 100% 100%;
  width: 43px;
  height: 43px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-phone-note {
  background: url(./images/sprite/ico-phone-note.svg) no-repeat 0 0;
  background-size: 100% 100%;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-clone {
  background: url(./images/sprite/ico-clone.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-setadvisor-business {
  background: url(./images/sprite/ico-setadvisor-business.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 21px;
  height: 21px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-setadvisor-private {
  background: url(./images/sprite/ico-setadvisor-privat.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 21px;
  height: 21px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-finnova {
  background: url(./images/sprite/finnova.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-checkbox-multiple-blank-circle-outline {
  background: url(./images/sprite/checkbox-multiple-blank-circle-outline.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-ray-start-vertex-end {
  background: url(./images/sprite/ray-start-vertex-end.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-account-clock {
  background: url(./images/sprite/account-clock.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-check-all{
  background: url(./images/sprite/check-all.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}

.ico-account-cancel {
  background: url(./images/sprite/account-cancel.png) no-repeat 0 0;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  font-size: 0px;
}


@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 192dpi) {
  .ico-arrow-black {
    background: url(./images/sprite/ico-arrow-black@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 16px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-arrow {
    background: url(./images/sprite/ico-arrow@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 12px;
    height: 8px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-block {
    background: url(./images/sprite/ico-block@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-cake {
    background: url(./images/sprite/ico-cake@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 12px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-calendar-large {
    background: url(./images/sprite/ico-calendar-large@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 18px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-calendar {
    background: url(./images/sprite/ico-calendar@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 12px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-check {
    background: url(./images/sprite/ico-check@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-close-btn {
    background: url(./images/sprite/ico-close-btn@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 42px;
    height: 42px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-close-grey {
    background: url(./images/sprite/ico-close-grey@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-close-large {
    background: url(./images/sprite/ico-close-large@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-close {
    background: url(./images/sprite/ico-close@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-dots {
    background: url(./images/sprite/ico-dots@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 14px;
    height: 6px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-file-pink {
    background: url(./images/sprite/ico-file-pink@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 20px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-file {
    background: url(./images/sprite/ico-file@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 13px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-filter {
    background: url(./images/sprite/ico-filter@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 12px;
    height: 11px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-globe {
    background: url(./images/sprite/ico-globe@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-link {
    background: url(./images/sprite/ico-link@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-oval {
    background: url(./images/sprite/ico-oval@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-phone {
    background: url(./images/sprite/ico-phone@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-plus {
    background: url(./images/sprite/ico-plus@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-search {
    background: url(./images/sprite/ico-search@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 21px;
    height: 21px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-setadvisor-business {
    background: url(./images/sprite/ico-setadvisor-business@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 21px;
    height: 21px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-setadvisor-private {
    background: url(./images/sprite/ico-setadvisor-privat@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 21px;
    height: 21px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-tick-green {
    background: url(./images/sprite/ico-tick-green@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 24px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-tick-grey {
    background: url(./images/sprite/ico-tick-grey@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 24px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-tick {
    background: url(./images/sprite/ico-tick@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 16px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-user-white {
    background: url(./images/sprite/ico-user-white@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 17px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
  .ico-clone {
    background: url(./images/sprite/ico-clone@2x.png) no-repeat 0 0;
    background-size: 100% 100%;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
  }
}

/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after {
  content: "";
  display: block;
  clear: both;
}

/*  Notext  */

.notext {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

/*  Hidden  */

[hidden],
.hidden {
  display: none !important;
}

/*  Alignleft  */

.alignleft {
  float: left;
}

/*  Alignright  */

.alignright {
  float: right;
}

/*  Disabled  */

[disabled],
.disabled {
  cursor: default;
  color: lightgray !important;
}
.disabled:hover {
  text-decoration: none;
}

/*  Grid  */

.cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.col {
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.col--1of2 {
  max-width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

/*  Responsive Helpers  */

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  .visible-xs-block {
    display: block !important;
  }
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }
  .visible-sm-block {
    display: block !important;
  }
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .hidden-md {
    display: none !important;
  }
  .visible-md-block {
    display: block !important;
  }
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1201px) {
  .hidden-lg {
    display: none !important;
  }
  .visible-lg-block {
    display: block !important;
  }
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */

* {
  padding: 0;
  margin: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block;
}

template {
  display: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

img,
iframe,
video,
audio,
object {
  max-width: 100%;
}

img,
iframe {
  border: 0 none;
}

img {
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

b,
strong {
  font-weight: bold;
}

address {
  font-style: normal;
}

svg:not(:root) {
  overflow: hidden;
}

a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="image"],
label[for] {
  cursor: pointer;
}

button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
a[href^="tel"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

button,
select {
  text-transform: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

nav ul,
nav ol {
  list-style: none outside none;
}

.nav-link {
  color: #666666;
}

.nav-link:hover {
  color: var(--bs-primary);
}

.LinkDropdown {
  display: inline !important;
}

/* ------------------------------------------------------------ *\
	Align icons with Text
\* ------------------------------------------------------------ */
.mdi:before,
.mdi-set {
  display: inline;
  vertical-align: top;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 1.1em;
  color: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nohover:hover {
  text-decoration: none !important;
}

.dropdown-item {
  color: var(--neutral-dark-grey);
}
.dropdown-item:active {
  background-color: #fff;
}

/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */
.screen-height {
  height: 90vh;
  overflow-x: hidden;
  padding: 0px 15px 0px 15px;
}

.screen-width {
  width: 100%;
  max-width: 100vw;
}

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 11px 32px 11px 31px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
}

.header .header__links ul:after {
  content: "";
  clear: both;
  display: table;
  line-height: 0;
}

.header .header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .header__links ul {
  list-style-type: none;
}

.header .header__links a {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
}

.header .header__links .current {
  color: #7252ac;
  font-weight: 600;
}

.header .header__links li {
  float: left;
}

.header .header__links li + li {
  margin-left: 40px;
}

.header .search + .header__links {
  margin-left: 30px;
}

.header-new-tab {
  padding: 0px 5px 0px 0px;
  font-size: 2.5em;
  opacity: 0.5;
}

.header-new-tab-icon {
  margin-top: -8px;
  cursor: pointer;
}

.header-profile-dropdown-disabled {
  color: rgb(127, 127, 127) !important;
}

.show-more-tooltip {
  white-space: pre-line;
}

.danger {
  color: #e40531;
}

.transparent {
  opacity: 0.5;
}

/* ------------------------------------------------------------ *\
	Profile
\* ------------------------------------------------------------ */

.profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile .profile__name {
  font-size: 16px;
  color: #757575;
}

.profile a {
  margin-left: 7px;
}

.profile .profile__name {
  margin-left: 18px;
}

.profile .profile-dropdown {
  width: auto !important;
}

.image-disabled {
  opacity: 0.5;
}

/*
 * React form
 */
.react-form-validation-result-form-message {
  color: var(--bs-danger);
}

.form-timefield-icon {
  color: var(--form-color-primary);
}

/*
 *  Consultant search
 */
.consultantsearch {
  text-align: left;
  padding: 10px;
  margin-top: 15px;
  width: 30rem;
}

.consultantsearch-header {
  text-align: left;
  font-size: 0.9em;
  padding-top: 5px;
  padding-left: 0px;
  width: 100%;
  padding-bottom: 10px;
}

.consultantsearch-close {
  position: absolute;
  font-size: 1.7em;
  color: #b3b3b3;
  top: 0.3em;
  right: 0px;
}

.consultantsearch-input-group {
  border: 1px solid #ced4da;
}

.consultantsearch-input {
  border: 0px;
  outline: none;
  box-shadow: none !important;
}

.consultantsearch-input-addon {
  border: 0px;
  padding-left: 0.5em;
  font-size: 1.8em;
  color: #7252ac;
}

.consultantsearch-list {
  border: 0px;
}

.consultantsearch-list-item {
  border: 0px;
  padding-left: 10px;
  color: #7252ac;
  cursor: pointer;
}

.consultantsearch-list-item-active {
  background-color: #f2f2f2;
}

.consultantsearch-list-item:hover {
  background-color: #f2f2f2;
}

.news-content {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

/* ------------------------------------------------------------ *\
	Customer
\* ------------------------------------------------------------ */
.favorite-customer {
  border: 0;
  color: lightgray;
  outline: none;
  font-size: 20px;
  background-color: white;
}

.favorite-customer-active {
  border: 0;
  color: #f3a91b;
  outline: none;
  font-size: 20px;
  background-color: white;
}

.contact-buttons {
  color: #7152ac;
  font-size: 16px;
  margin-left: 8px;
  border: 1px solid #7152ac;
  vertical-align: middle;
  display: inline-block;
  padding: 5px 7px 5px 7px;
  background-color: white;
}

.contact-buttons:disabled,
.contact-buttons.disabled {
  border: 1px solid lightgray !important;
}

.lead-customer-list .lead-customer-item {
  padding-left: 10px;
  padding-right: 5px;
  cursor: pointer;
}

.lead-customer-list .lead-customer-item i {
  position: unset;
}

.lead-customer-item {
  display: flex;
  align-content: center;
  align-items: center;
  border-left: 5px solid;
  padding: 0px;
}

.lead-customer-item-container {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  width: 100%;
}

.lead-customer-item-score-wrapper {
  display: flex;
  align-self: center;
}

.lead-customer-item-score {
  display: inline-block;
  border-radius: 50%;
  background: #7152ac;
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  font-size: 0.8em;
}

.lead-customer-item-score-text {
  display: inline-block;
  margin-top: 25%;
  font-weight: bold;
}

.lead-customer-item-elements-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.lead-customer-item-elements {
  display: flex;
  align-self: center;
  height: 100%;
}

.lead-customer-item-element {
  align-self: center;
  display: inline-block;
  padding-left: 10px;
  font-size: 13px;
}

.lead-customer-item-linked-items {
  align-self: center;
  margin-left: auto;
  padding-left: 10px;
  min-width: 10%;
}

.lead-customer-item-linked-items i {
  position: unset;
}

.lead-form-date-group {
  display: flex;
  justify-content: space-between;
}

.lead-form-expire-date-warn {
  border-color: orange;
  color: orange;
}

.lead-form-expire-date-warn input {
  border-color: orange;
}

.lead-form-expire-date-warn .Select-control {
  border-color: orange;
}

.lead-in-active-consultant {
  color: red;
}

.activity-customer-list-item {
  border-top: 1px solid #ccc;
  padding: 5px 0px 10px 0px;
}

.activity-listitem-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.activities-filter .dropdown-menu {
  width: 21em;
}

.activities-filter-customer .filter-menu-label {
  font-size: 1em;
}

.activities-filter-dashboard .filter-menu-label {
  font-size: 1em;
}

.activity-listitem-content-note {
  max-height: 100px;
  overflow: hidden;
  position: relative;
}
.activity-listitem-content-note::after {
  display: block;
  content: "";
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  height: 10px;
  background-image: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.infobox-kpi {
  border: 10px;
  padding: 15px;
}

.infobox-customer h2 {
  font-size: 1.2em;
}

.row-customer {
  background: #403e3e0a;
  padding: 10px;
}

.customer-main-content {
  padding-top: 6rem;
  padding-right: 60px;
  padding-left: 32px;
}

.consultant-overlay-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 40px;
  background-color: rgb(114, 82, 172);
  cursor: pointer;
  z-index: 5;
}

.consultant-overlay-toggle-text {
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  cursor: pointer;
  color: #fff;
  min-width: 90px;
}

.consultant-overlay-content {
  overflow: hidden;
  width: 0px;
  height: 0px;
  background-color: #fff;
  transition: width 1s, height 1s;
  box-shadow: -5px 6px 4px -6px lightgray;
  border-left: 0.5px solid lightgray;
  border-right: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;
}

.consultant-overlay-content-item {
  padding: 20px;
  border-top: 1px solid lightgray;
}

.consultant-overlay {
  position: fixed;
  display: flex;
  top: 300px;
  right: 0%;
  height: auto;
  z-index: 200;
}
.customer-scroll-to-top {
  width: 70px;
  height: 65px;
  position: fixed;
  padding: 5px;
  right: 10px;
  top: 50vh;
  background: #80808066;
  border-radius: 4px;
  cursor: pointer;
  z-index: 100;
  text-align: center;
}

.customer-flag {
  padding: 4px 15px 5px 15px;
  margin-right: 10px;
}

.customer-flag-closed {
  background: red;
  color: #fff;
}

.customer-flag-undesired {
  background: #000000;
  color: #fff;
}

.customer-flag-link {
  cursor: pointer;
}

.customer-flag-narilo {
  background: #f3a91b;
  color: #fff;
}

.customer-portfolio {
  padding-bottom: 50px;
}

/* ------------------------------------------------------------ *\
	Account Statement
\* ------------------------------------------------------------ */
.account-statement {
  width: 80%;
  margin-top: 20px;
  border: 1px solid rgb(114, 82, 172);
  padding: 10px;
  padding-bottom: 20px;
}

.account-statment-details {
  width: 55%;
  max-width: 55%;
}

.year-value {
  display: inline-block;
  color: #614692;
}
.year-value:hover {
  text-decoration: underline;
  color: #614692;
}

/* ------------------------------------------------------------ *\
	Customer identification
\* ------------------------------------------------------------ */

.customer-identification-nav .active{
  background: #614692 !important;
  color: white !important;
  border-bottom: 1px solid #ccc !important;
}

.customer-identification-group {
  padding-bottom: 30px;
}

.customer-identification-group-title {
  font-weight: bold;
  border-bottom: 1px solid black;
  padding: 10px;
  margin-bottom: 5px;
}

.customer-identification-item {
  border-bottom: 1px solid #d9d9d9;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.customer-identification-item-question {
  display: flex;
  width: 100%;
  margin: auto;
}

.customer-identification-item-answer {
  opacity: 0.5;
}

.customer-identification-item-count {
  color: #7152ac;
  right: 15px;
  top: 15px;
  position: absolute;
  width: 40px;
  text-align: right;
  font-weight: bold;
}

.customer-identification-item-question-title {
  display: flex;
  width: 75%;
  padding-right: 20px;
}

.customer-identification-item-question-checkbox {
  display: flex;
}

.customer-identification-checkbox {
  color: #d9d9d9;
  font-size: 1.7em;
  padding-right: 10px;
}

.customer-identification-checkbox-active {
  color: #7152ac;
}

.customer-call-consultant {
  color: #7252ac;
  margin-top: 10px;
  display: flex;
}

.customer-identifiation-fraud-attempt-alert {
  color: #f22d54;
}

.customer-identification-modal-header h5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.customer-identification-discard-justification:not(:last-child) {
  margin-bottom: 1em;
}

.customer-identification-discard-justification-input {
  margin-top: 0.23em;
}

/* ------------------------------------------------------------ *\
	Customer overview
\* ------------------------------------------------------------ */
.customer-overview-datagrid-container {
  min-height: 70vh;
}

.customeroverview-grid-row {
  height: 72%;
  font-size: 0.8em;
}

.customeroverview-customer-link {
  color: #7152ac;
}

.customeroverview-customer-link:hover {
  text-decoration: underline;
}

/* ------------------------------------------------------------ *\
	Customerbook
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Workguide
\* ------------------------------------------------------------ */
.pdf-preview {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pdf-preview-modal-body {
  display: inline-block;
  text-align: center;
}

.pdf-preview-modal-footer {
  justify-content: center;
}

.workguide-icon {
  font-size: 1.2em;
  padding-right: 5px;
  cursor: pointer;
}

/* ------------------------------------------------------------ *\
	Workguide
\* ------------------------------------------------------------ */
.flex-xl-nowrap {
  flex-wrap: nowrap !important;
}

.sticky-sidebar-left {
  position: sticky;
  top: 5rem;
  z-index: 2;
  height: calc(100vh - 4rem);
  flex: 0 1 450px;
  padding-top: 5rem;
  color: #614692;
}

.workguide-content {
  padding-top: 3rem;
}

.divider {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-top: 1px solid #aaa;
}

.spacer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.spacer-2rem {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.spacer-3rem {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.kontextBereich__background {
  position: fixed;
  z-index: -1;
  margin-left: -15px;
  background: #f2f2f2;
  width: 100%;
  top: 0;
  bottom: 0;
}

.entris-workguide-content .workguide-details {
  max-width: 90%;
}

/* ------------------------------------------------------------ *\
	Contracts
\* ------------------------------------------------------------ */
.customeroverview-contract-detail {
  border-bottom: 1px solid lightgray;
}

.mailto-underline:hover {
  text-decoration: underline !important;
}

/* ------------------------------------------------------------ *\
	Leads
\* ------------------------------------------------------------ */
.lead-score-100 {
  background-color: var(--leadScore100);
  color: white;
}

.lead-score-80 {
  background-color: var(--leadScore80);
  color: #49a3d0
}

.lead-score-60 {
  background-color: var(--leadScore60);
  color: #49a3d0
}

/* ------------------------------------------------------------ *\
	Lead Management
\* ------------------------------------------------------------ */
.leadManagement {
  margin-top: 10px;
}

.leadmanagement-container {
  height: 90vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0px 15px 0px 15px;
}

.leadmanagement-board-row {
  height: 82%;
}

.leadmanagement-board-container {
  height: 100%;
  margin-top: 40px;
  padding: 0px 0px 0px 0px;
}

.leadmanagement-filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
}

.leadmanagement-filter-menu {
  height: 100%;
  width: 30%;
}

.leadmanagement-sort-menu {
  height: 100%;
  width: 70%;
}

.leadmanagement-sort-menu .filter-menu-label {
  font-size: 1em;
}

.leadmanagement-filter-menu .filter-menu-label {
  font-size: 1em;
}

.leadmanagement-board-column {
  height: 100%;
}

.leadmanagement-board-column-items {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.leadmanagement-board-dropzone {
  display: table;
  height: 100%;
  border: 1px dashed #999999;
  opacity: 0.9;
  width: 94%;
  color: #999999;
  background-color: #fff;
  text-align: center;
}

.leadmanagement-board-dropzone-content {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.3em;
}

.leadmanagement-board-dropzone-content i {
  font-size: 2em;
}

.leadmanagement-board-dropzone-win {
  background-color: #f2f2f2;
  opacity: 0.9;
}

.leadmanagement-board-dropzone-win .leadmanagement-board-dropzone-content {
  margin-top: 25%;
  color: #737373;
  font-weight: bold;
}

.leadmanagement-board-dropzone-lost {
  background-color: #ffe6e6;
  opacity: 0.9;
}

.leadmanagement-board-dropzone-lost .leadmanagement-board-dropzone-content {
  margin-top: 25%;
  color: #cc0000;
  font-weight: bold;
}

.leadmanagement-form-modal .modal-header {
  border: 0px;
}

.leadmanagement-form-modal .modal-content {
  padding: 1rem;
}

.leadmanagement-form-modal .modal-footer {
  border: 0px;
  justify-content: left;
}

.leadmanagement-lead-detail {
  width: 80vw;
  max-width: 80vw;
}

.leadmanagement-lead-detail .modal-title {
  width: 100%;
}

.leadmanagement-linkeditems-popover .popover-header {
  background-color: #fff;
  border: 0px;
}

.leadmanagement-filter-menu .dropdown {
  width: 320px !important;
}

.leadmanagement-filter-item:active {
  background-color: #fff;
}

.leadmanagement-filter-item:hover {
  background-color: #fff;
}

.leadqualification-board-dropzone-accepted {
  background-color: #f3f4e3;
  color: #9aaa00;
  opacity: 0.9;
}

.leadqualification-board-dropzone-rejected {
  background-color: #f2f2f2;
  opacity: 0.9;
}

.leadmanagement-search-field input {
  border-right: 0px;
  height: 42px;
}

.leadmanagement-search-field input:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #ced4da;
}

.leadmanagement-powersearch input {
  color: #000000;
}

.leadmanagement-powersearch .powersearch-input-group-disabled input {
  background: #dadada;
}

.leadmanagement-powersearch strong {
  color: #000000;
}

.leadmanagement-powersearch .powersearch-input-addon {
  right: 25px;
  top: 12px;
}

.leadmanagement-powersearch .powersearch-workguide-list-item {
  color: #7252ac;
}

.leadmanagement-powersearch .powersearch-selected-workguide-label {
  background-color: #7252ac;
  border-color: #7252ac !important;
}

.leadmanagement-powersearch .powersearch-selected-workguide-remove {
  background-color: #7252ac;
  border-color: #7252ac !important;
  border-left-color: #8064b4 !important;
}

.leadmanagement-powersearch .react-autosuggest__suggestions-container {
  width: 70vw;
}

.leadmanagement-tooltip-detail {
  display: inline-block;
  opacity: 0.7;
  font-size: 1.3em;
  padding-right: 5px;
}

.leadmanagement-tooltip-detail {
  opacity: 0.5;
  font-size: 1.3em;
  padding-right: 5px;
}

.leadmanagement-tooltip-activity {
  opacity: 0.5;
  font-size: 1em;
  padding-right: 10px;
}

.leadmanagement-lead {
  padding-top: 0.4em;
  position: relative;
}
.leadmanagement-lead__drag-count {
  background-color: #00a5d5;
  position: absolute;
  right: 0;
  top: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: #fff;
  font-size: 0.9em;
  text-align: center;
  padding-top: 6px;
  font-weight: bold;
  z-index: 2;
}

.leadmanagement-update-due-dates {
  margin-bottom: 15px;
}
.leadmanagement-update-due-dates__selected {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 5px 5px 5px 0;
}
.leadmanagement-update-due-dates__selected-title {
  padding: 5px 10px 5px 0;
}
.leadmanagement-update-due-dates__selected-item {
  margin-right: 10px;
  background-color: #cde9f1;
  border-radius: 5px;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
  margin-bottom: 5px;
}

.leadmanagement-update-due-dates__selected-item-readonly {
  background-color: #f0f0f0;

  background: linear-gradient(135deg, #f0f0f0 49.9%, #aeaeae 49.9%, #aeaeae 60%, #f0f0f0 60%),
    linear-gradient(135deg, #aeaeae 10%, #f0f0f0 10%);
  background-size: 0.5em 0.5em;
}

.leadmanagement-update-due-dates__dialog-content {
  width: 520px;
}

.leadmanagement-update-due-dates__form {
  display: flex;
}
.leadmanagement-update-due-dates__form button {
  margin-left: 10px;
}

.leadmanagement-due-dates-dropzone {
  position: absolute;
  width: 100%;
  top: -95px;
  height: 88px;
  z-index: 8;
}
.leadmanagement-due-dates-dropzone__fixed {
  position: fixed;
  top: 80px;
  z-index: 101;
}

.leadmanagement-dropzones-container {
  position: absolute;
  z-index: 7;
  width: 33vw;

  height: 50vh;
}

.leadmanagement-due-dates-dropzone__dropzone {
  width: 98%;
  padding: 8px 0;
  opacity: 1;
  height: 88px !important;
  top: 80px;
  position: fixed;
}

.leadmanagement-card__is-selected {
  background-color: #cde8f1 !important;
}

.leadmanagement-card-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.leadmanagement-card-border-left {
  width: 10px;
  background: #00a5d5;
}

.leadmanagement-card-border-left-blue {
  background: #00a5d5;
}

.leadmanagement-card-border-left-pink {
  background: #7152ac;
}

.leadmanagement-card-border-left-green {
  background: #9aaa00;
}

.leadmanagement-card-left {
  width: 75%;
  align-self: flex-start;
  padding: 10px;
}

.leadmanagement-card-right {
  display: flex;
  width: 25%;
  flex-direction: column;
  padding: 5px;
}

.leadmanagement-card-customer {
  font-size: 0.9em;
  padding-bottom: 3px;
}

.leadmanagement-card-task-type {
  font-size: 13px;
  color: #212121;
  padding-bottom: 3px;
}

.leadmanagement-card-note {
  padding-bottom: 8px;
}

.leadmanagement-card-dates {
  font-size: 12px;
  color: #212121;
}

.leadmanagement-card-icon-container-top {
  text-align: right;
  flex-grow: 1;
}

.leadmanagement-card-icon-container-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 5px;
}

.leadmanagement-card-icon {
  align-self: center;
  opacity: 0.5;
}

.leadmanagement-card-linked-items {
  padding-right: 5px;
  align-self: center;
  min-width: 35px;
}

.leadmanagement-card-clone {
  margin-right: 10px;
  align-self: center;
}

.leadmanagement-closed-filter {
  margin-left: 75%;
  width: 25%;
  display: flex;
  margin-top: 3%;
  z-index: 99;
}

/* ------------------------------------------------------------ *\
	Lead Dispatch
\* ------------------------------------------------------------ */
.leaddispatch-container {
  height: 90vh;
  max-height: 90vh;
  width: 70vw;
}

.leaddispatch-chevron-outer {
  display: table;
  height: 100%;
}

.leaddispatch-chevron-inner {
  display: table-cell;
  vertical-align: middle;
  font-size: 5em;
  color: #a6a6a6;
}

.leaddispatch-list-loader {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.leaddispatch-list-item-overlay-outer {
  position: absolute;
  z-index: 2;
  padding-bottom: 4px;
}

.leaddispatch-list-item-overlay-inner {
  background-color: #809fff;
  opacity: 0.25;
  height: 100%;
  width: 100%;
}

.leaddispatch-consultant-list {
  height: 100%;
  overflow-y: auto;
}

.leaddispatch-consultant-outer {
  display: table;
  width: 100%;
  padding: 10px;
  border: 1px dashed #6c757d;
  border-radius: 20px;
  margin-bottom: 5px;
  height: 60px;
  vertical-align: middle;
}

.leaddispatch-consultant-outer-active {
  background-color: #ccd9ff;
}

.leaddispatch-consultant-inner {
  vertical-align: middle;
  display: table-cell;
}

.leaddispatch-consultant-inner img {
  height: 40px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.leaddispatch-consultant-search {
  margin-bottom: 20px;
  padding-right: 40px;
  width: 20rem;
  display: initial;
}

/* ------------------------------------------------------------ *\
	Filter Menu
\* ------------------------------------------------------------ */
.filter-menu {
  height: 100%;
  width: 20%;
  display: table;
}

.filter-menu-label {
  font-size: 0.8em;
}

.filter-menu-reset {
  display: inline-block;
}

.filter-menu-reset-inner {
  cursor: pointer;
  padding-left: 0.5em;
  color: red;
  line-height: 1.3;
}

.filter-menu .dropdown {
  width: 320px !important;
}

.filter-submenu-toggle {
  width: 100%;
  height: 100%;
}

.filter-submenu-toggle-wrapper {
  display: table;
  padding-left: 1.5rem;
  width: 100%;
}

.filter-submenu-toggle-icon {
  display: inline-block;
  font-size: 1.7em;
  padding-right: 1.5em;
}

.filter-submenu-toggle-label {
  position: relative;
  display: inline-block;
  color: black;
  top: -4px;
  font-weight: normal;
}

.filter-submenu-toggle-caret {
  display: table-cell;
  padding-left: 15px;
  font-size: 1.2em;
  font-weight: bold;
  vertical-align: top;
  padding-top: -2px;
}

.filter-menu-item:active {
  background-color: #fff;
}

.filter-menu-item:hover {
  background-color: #fff;
}

.filter-search-field {
  width: 100%;
}

.activity-modal {
  width: 70vw;
  max-width: none;
}

.activity-modal-header .modal-title {
  width: 100%;
}

.activity-modal-header .activity-modal-filter-container {
  padding-top: 20px;
}

.activity-modal-show-more {
  font-size: 0.9em;
}

.filter-search-field input {
  border-right: 0px;
  height: 42px;
}

.filter-search-field input:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #ced4da;
}

.filter-date-range-slider-disabled {
  pointer-events: none;
}

.filter-date-range-slider-disabled .rc-slider-track {
  background-color: grey;
}

.filter-date-range-slider-disabled .rc-slider-handle {
  border: 2px solid grey;
}

.filter-date-range-slider .rc-slider-mark {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.foreign-assets .info__actions {
  position: relative;
  width: 70%;
  bottom: 4px;
}

.foreign-assets-filter {
  font-size: 1.2em;
  bottom: 2px;
  padding-right: 50px;
}

.foreign-assets-new-link {
  width: 70%;
}

/* ------------------------------------------------------------ *\
	Sort menu
\* ------------------------------------------------------------ */
.sort-menu {
  width: 100%;
}

.sort-menu-item-inner {
  display: flex;
  width: 100%;
  color: black;
}

.sort-menu-item:active {
  background-color: #fff;
}

.sort-menu-item:hover {
  background-color: #fff;
}

.sort-menu-item-label {
  align-self: flex-end;
}

.sort-menu-item-icon {
  align-self: flex-start;
}

/* ------------------------------------------------------------ *\
	Item
\* ------------------------------------------------------------ */

.items {
  position: relative;
}

/* === Transparent State === */
.items--transparent .item {
  opacity: 0.1;
}
.items--transparent .clearbox {
  opacity: 0.1;
}

/* === Overlay === */
.items .items__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.items .items__overlay--alt .items__box {
  height: 33.33%;
}

/* === Box Overlay === */
.items .items__box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  border: 1px dashed #ccc;
  text-align: center;
  background: rgba(158, 158, 158, 0.1);
}

.items .items__box--error {
  background: rgba(227, 5, 48, 0.1);
  color: #e40531;
}

.items .items__box--info {
  background: rgba(113, 82, 172, 0.1);
  color: #7152ac;
}

.items .items__box--successgreen {
  background: rgba(154, 170, 0, 0.1);
  color: #9aaa00;
}

.items .items__box i {
  margin-bottom: 10px;
}

/* === Clearbox === */
.items .clearbox {
  border: 1px dashed #ccc;
  margin-top: 10px;
}

/* === Item Styles === */
.item {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 1px 10px 8px 20px;
  background-color: #f5f5f5;
  border-radius: 2px;
}

.item + .item {
  margin-top: 11px;
}

.item .item__bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background: #00a5d5;
}
.item .item__bar--pink {
  background: #7152ac;
}
.item .item__bar--green {
  background: #9aaa00;
}

.item .item__content {
  margin-top: 8px;
}
.item .item__content h5 {
  margin-bottom: 3px;
  font-size: 16px;
  color: #212121;
}
.item .item__content p {
  margin-bottom: 8px;
  font-size: 13px;
  color: #212121;
}

.item .item__meta {
  font-size: 12px;
  color: #212121;
}
.item .item__meta .transparent {
  opacity: 0.5;
}
.item .item__meta .danger {
  color: #e40531;
}
.item .item__meta i {
  margin: 0 14px;
}

.item .item__notification {
  margin: 2px 0 19px 0;
  text-align: right;
}
.item .item__notification--transparent i {
  opacity: 0;
}

.item .item__number {
  display: block;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: #7152ac;
  line-height: 33px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.item .item__info {
  display: flex;
  align-items: center;
}
.item .item__info a + a {
  margin-left: 10px;
}

/* ------------------------------------------------------------ *\
	Confirm route change
\* ------------------------------------------------------------ */

.confirm-route-change-body {
  padding: 2rem;
}

.confirm-route-change-body h4 {
  padding-bottom: 2rem;
}

.confirm-route-change-footer {
  border: 0px;
  padding-left: 2rem;
  padding-bottom: 2.5rem;
  justify-content: left;
}

/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search {
  width: 915px;
  position: relative;
}

.search .search__field {
  width: 100%;
  padding: 0 40px 0 18px;
  border: 1px solid #cccccc;
  height: 42px;
  font-size: 14px;
  color: #9e9e9e;
  letter-spacing: -0.03em;
}

.search .search__btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 41px;
  background: transparent;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #ccc;
  border-radius: 0;
}

.modal__header_search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 1520px) {
  .search {
    width: 600px;
  }
}

/* ------------------------------------------------------------ *\
	Search Small
\* ------------------------------------------------------------ */

.search--small {
  width: 540px;
}

/* ------------------------------------------------------------ *\
	Dashboard
\* ------------------------------------------------------------ */

.dashboard {
  padding: 40px 0;
}

@media (max-width: 1300px) {
  .dashboard .row {
    margin: 0 -5px;
  }
  .dashboard .col {
    padding: 0 5px;
  }
}

/* ------------------------------------------------------------ *\
	Notifications
\* ------------------------------------------------------------ */

.notification-banners__item {
  padding: 2px;
  text-align: center;
  background: #c01a00;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.notification-banners__item + .notification-banners__item {
  margin-top: 10px;
}

/* ------------------------------------------------------------ *\
	Dashboard Alt
\* ------------------------------------------------------------ */

.dashboard--alt {
  padding: 39px 0;
}

.dashboard--alt .dashboard__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 43px;
}

.dashboard--alt .dashboard__holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dashboard--alt .dashboard__holder .search + a {
  margin-left: 33px;
}

.dashboard--alt .dashboard__actions {
  font-size: 0;
  line-height: 0;
}

.dashboard--alt .dashboard__actions .button {
  display: block;
}

.dashboard--alt .dashboard__actions .button:hover {
  opacity: 0.7;
}

.dashboard--alt .dashboard__actions a + a {
  margin-left: 10px;
}

/* ------------------------------------------------------------ *\
	Dashboard Small
\* ------------------------------------------------------------ */

.dashboard--small .dashboard__body {
  max-width: 1104px;
  margin: 0 auto;
}

/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
  max-width: 99%;
  padding-left: 10px;
  padding-right: 10px;
}

.shell--fluid {
  max-width: none;
}

/* ------------------------------------------------------------ *\
	Info
\* ------------------------------------------------------------ */

.info h4 {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
}

.info .info__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.info .info__head .react-anchor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
}

.info .info__head .react-anchor span {
  vertical-align: top;
}

.info .info__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.info .info__actions .react-anchor + .react-anchor {
  margin-left: 20px;
}

.info + .info {
  margin-top: 43px;
}

.info .info__foot .react-anchor {
  font-size: 13px;
}

.info .info__body + .info__foot {
  margin-top: 5px;
}

.info .info__body--fixed {
  height: 728px;
  padding-right: 5px;
  overflow-y: auto;
}

.info-collapse {
  padding-right: 5px;
}

.info-collapse i {
  font-size: 1.3em;
  font-weight: bold;
}

.collapsible {
  transition: opacity 1s ease-in-out;
}

.info__body-collapsed {
  height: 0px;
  opacity: 0;
}

/* ------------------------------------------------------------ *\
	Infobox
\* ------------------------------------------------------------ */

.infoboxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px -10px;
}

.infobox {
  width: 25%;
  padding: 0 5px;
  margin-bottom: 10px;
  text-align: center;
}

.infobox .infobox__number {
  padding: 12px 0 13px;
  background-color: #eeeeee;
  color: #794cb2;
}

.infobox .infobox__number h3 {
  font-size: 39px;
  font-weight: 400;
}

.infobox .infobox__text {
  padding: 6px 0;
  background: #794cb2;
  color: #fff;
  font-size: 12px;
}

.infobox--red .infobox__number {
  color: #e30530;
}

.infobox--red .infobox__text {
  background-color: #e30530;
}

.infobox--yellow .infobox__number {
  color: #f3a91b;
}

.infobox--yellow .infobox__text {
  background-color: #f3a91b;
}

.infobox--green .infobox__number {
  color: #9baa00;
}

.infobox--green .infobox__text {
  background-color: #9baa00;
}

@media (max-width: 1360px) {
  .infobox {
    width: 33.33%;
  }
}


/* ------------------------------------------------------------ *\
	Boxes
\* ------------------------------------------------------------ */

.boxes + .boxes {
  margin-top: 23px;
}

.boxes .boxes__head {
  margin-bottom: 13px;
}

.boxes .boxes__head h5 {
  font-size: 12px;
}

.boxes .boxes__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
  margin: 0 -5px -10px;
}

.boxes .boxes__body .button__box {
  padding: 0 5px;
  margin-bottom: 10px;
}

@media (max-width: 1300px) {
  .boxes .boxes__body .button__box {
    width: 50%;
  }
}

/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list-"] {
  list-style: none outside none;
}

.list-birthday {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #ccc;
}

.list-birthday i {
  margin-right: 7px;
  vertical-align: baseline;
}

.list-birthday li {
  position: relative;
  padding: 10px 10px 11px 99px;
}

.list-birthday .react-anchor {
  display: block;
  font-size: 13px;
}

.list-birthday .react-anchor + .react-anchor {
  margin-top: 5px;
}

.list-birthday li span {
  position: absolute;
  left: 11px;
  font-size: 13px;
}

.list-birthday li + li {
  border-top: 1px solid #ccc;
}

.list-birthday-icon-coloured {
  color: #00a5d5;
}

/* ------------------------------------------------------------ *\
	List links
\* ------------------------------------------------------------ */

.list-links a {
  font-size: 12px;
}

.list-links li + li {
  margin-top: 9px;
}

.list-links + .list-links {
  margin-left: 76px;
}

/* ------------------------------------------------------------ *\
	Links
\* ------------------------------------------------------------ */

.links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 0;
}

/* ------------------------------------------------------------ *\
	List Notifications
\* ------------------------------------------------------------ */

.list-notifications {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #ccc;
}

.list-notifications li {
  position: relative;
  padding: 10px 35px 8px 34px;
  color: #212121;
}

.list-notifications li.active {
  color: #f3a91b;
}

.list-notifications li + li {
  border-top: 1px solid #ccc;
}

.list-notifications li p {
  font-size: 13px;
}

.list-notifications i {
  position: absolute;
  left: 9px;
}

/* ------------------------------------------------------------ *\
	List Schedule
\* ------------------------------------------------------------ */

.list-schedule + .list-schedule {
  margin-top: 29px;
}

.list-schedule p {
  margin-bottom: 9px;
}

.list-schedule ul {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #ccc;
  list-style-type: none;
}

.list-schedule li {
  position: relative;
  padding: 10px 35px 11px 34px;
  color: #212121;
  font-size: 13px;
}

.list-schedule li span {
  margin-right: 18px;
}

.list-schedule li p {
  margin-bottom: 0;
}

.list-schedule li + li {
  border-top: 1px solid #ccc;
}

.list-schedule i {
  position: absolute;
  top: 12px;
  left: 8px;
}

/* ------------------------------------------------------------ *\
	List Charts
\* ------------------------------------------------------------ */

.list-charts li {
  position: relative;
  padding-left: 20px;
}

.list-charts li + li {
  margin-top: 6px;
}

.list-charts li > span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 12px;
}

.list-charts p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* ------------------------------------------------------------ *\
	Graphic
\* ------------------------------------------------------------ */

.graphic .graphic__head {
  margin-bottom: 14px;
}

.graphic .graphic__head h5 {
  font-size: 13px;
}

.graphic .graphic__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.graphic .graphic__content {
  width: calc(100% - 40px);
  padding: 0 77px 0 39px;
}

.graphic--alt .graphic__content {
  padding: 0 96px 0 39px;
}

.graphic .graphic__chart {
  height: 203px;
  width: 40px;
}

/* ------------------------------------------------------------ *\
	Chart
\* ------------------------------------------------------------ */

.chart {
  text-align: center;
}

.chart .chart__image {
  position: relative;
  display: inline-block;
}

.chart .chart__center {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: #00a5d5;
}

.chart .chart__center .chart__content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
}

.chart .chart__outter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  height: 50px;
  width: 106px;
  border-radius: 2px;
  border: 1px solid #7152ac;
  background-color: #ffffff;
  color: #7252ac;
  font-size: 12px;
  line-height: 1.5;
}

.chart .chart__outter--top {
  top: -12px;
  left: 84px;
}

.chart .chart__outter--bottom {
  bottom: -14px;
  left: 84px;
}

.chart .chart__outter--topright {
  top: 50px;
  right: -24px;
}

.chart .chart__outter--topleft {
  top: 50px;
  left: -35px;
}

.chart .chart__outter--bottomright {
  bottom: 58px;
  right: -24px;
}

.chart .chart__outter--bottomleft {
  bottom: 58px;
  left: -34px;
}

.chart * .chart__button {
  cursor: pointer;
}

.chart * .chart__button:hover {
  filter: brightness(85%);
}

/* ------------------------------------------------------------ *\
	Powersearch
\* ------------------------------------------------------------ */
.powersearch-customer-list-item {
  text-align: left;
  color: rgb(0, 165, 213);
  cursor: pointer;
}

.powersearch-customer-list-item:hover {
  background-color: #e6e6e6;
}

.powersearch-customer-list-item-label {
  padding-top: 9px;
  padding-bottom: 9px;
}

.powersearch-workguide-list-item {
  text-align: left;
  color: rgb(154, 170, 0);
  cursor: pointer;
}

.powersearch-workguide-list-item:hover {
  background-color: #e6e6e6;
}

.powersearch-selected-customer-label {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
  color: #fff;
  background-color: rgb(0, 165, 213);
  border-color: rgb(0, 176, 230) !important;
}

.powersearch-selected-customer-remove {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
  color: #fff;
  background-color: rgb(0, 165, 213);
  border-color: rgb(0, 176, 230) !important;
}

.powersearch-selected-workguide-label {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
  color: #fff;
  background-color: rgb(154, 170, 0);
  border-color: rgb(161, 179, 0) !important;
}

.powersearch-selected-workguide-remove {
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
  color: #fff;
  background-color: rgb(154, 170, 0);
  border-color: rgb(161, 179, 0) !important;
}

.powersearch-input-addon {
  right: 10px;
  top: 25%;
  background: url(./images/sprite/activity.png);
  height: 100%;
  width: 25px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.leadmanagement-powersearch-input-addon {
  background: url(./images/sprite/ico-search.png);
  height: 100%;
  width: 25px;
  background-repeat: no-repeat;
  border: 0px;
  position: absolute;
  top: 12px;
  right: 25px;
}

.powersearch-confirm-remove-modal-body {
  padding: 2rem;
}

.powersearch-confirm-remove-modal-body h4 {
  padding-bottom: 2rem;
}

.powersearch-confirm-remove-modal-footer {
  border: 0px;
  padding-left: 2rem;
  padding-bottom: 2.5rem;
  justify-content: left;
}

.powersearch-show-more {
  color: #7152ac;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
}

.powersearch-container {
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.open-customer-in-tab {
  font-size: 1.3em;
  padding-left: 10px;
  cursor: alias;
}

.powersearch-icon-customer-label {
  font-size: 1.2em;
}

.search .react-autosuggest__suggestions-container {
  width: 90vw;
}

.powersearch-main .powersearch-customer-list-item {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* ------------------------------------------------------------ *\
	Lookup
\* ------------------------------------------------------------ */
.lookup-field {
  position: relative;
}
.lookup-field__status {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.lookup-field__status--requesting {
  color: gray;
  animation: spin 2s linear infinite;
}
.lookup-field__status--existing {
  color: green;
}
.lookup-field__container {
  position: relative;
}
.lookup-field__suggestions-container {
  display: none;
}
.lookup-field__suggestions-container--open {
  display: block;
  position: absolute;
  z-index: 2;
  top: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #b8a8d6;
}
.lookup-field__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.lookup-field__suggestion {
  cursor: pointer;
  padding: 6px 12px;
}
.lookup-field__suggestion--highlighted {
  background-color: #ddd;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* ------------------------------------------------------------ *\
	Markdown
\* ------------------------------------------------------------ */

.markdown-area * ol,
.markdown-area * ul {
  padding-left: 40px;
}

.markdown-area * table {
  border-collapse: collapse;
}

.markdown-area * tr {
  border-bottom: 1px solid grey;
}

.markdown-area * td {
  padding-left: 10px;
}


/* ------------------------------------------------------------ *\
	Notes
\* ------------------------------------------------------------ */
.note {
  display: flex;
  cursor: pointer;
  overflow: hidden;
}

.note-customer-overview {
  max-height: 200px;
}

.note-dashboard {
  max-height: 60px;
}

.note-icon {
  display: flex;
  align-self: flex-start;
  padding-right: 10px;
}

.note-text {
  display: flex;
}

.note-list-item {
  font-size: 0.9em;
  border-top: 1px solid #ccc;
  padding: 5px 0px 10px 0px;
  overflow: hidden;
}

.field-limit {
  font-size: 12px;
}

/* ------------------------------------------------------------ *\
	Products
\* ------------------------------------------------------------ */

.basic-products-container {
  width: 50%;
  float: left;
  padding: 0px;
}

.basic-products-header {
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-family: Objektiv, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  margin: -4px 0px 0px 0px;
  padding: 15px 0px 10px 0px;
  border-bottom: 1px solid black;
  cursor: initial;
}

.basic-products-row {
  height: 40px;
}

.favorite-product {
  color: #c2ca60;
  border-bottom: 1px solid #ddd;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 372px;
}

.disliked-product {
  color: #ec5270;
}

.basic-product {
  height: 40px;
  left: 0px;
  top: 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.printable {
  display: none;
}

/* ------------------------------------------------------------ *\
	PortalTip
\* ------------------------------------------------------------ */
.portaltip-content {
  position: absolute;
  z-index: 99999;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #bfbfbf;
  cursor: grab;
}

.portaltip-content ul {
  padding-left: 20px;
}

.portaltip-content td {
  padding-left: 10px;
}

/* ------------------------------------------------------------ *\
	Workguides List
\* ------------------------------------------------------------ */
.workguidesearch-input-addon {
  background-image: url(./images/sprite/ico-search.png);
  background-repeat: no-repeat;
  background-position: center top;
  margin-left: 95%;
  margin-top: -4%;
  position: relative;
  height: 20px;
}

/* ------------------------------------------------------------ *\
	Delete List Action
\* ------------------------------------------------------------ */
.delete-list-action {
  padding: 5px;
  font-size: 20px;
}

/* ------------------------------------------------------------ *\
	Address
\* ------------------------------------------------------------ */
.address-form {
  width: 100%;
}

/* ------------------------------------------------------------ *\
	popover
\* ------------------------------------------------------------ */
.card-error-popover {
  max-width: 700px;
}

/* ------------------------------------------------------------ *\
	PDF
\* ------------------------------------------------------------ */
.pdf-generate-overlay {
  position: absolute;
  z-index: 2;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background-color: #fff;
}

.pdf-generate-overlay-content {
  position: absolute;
  bottom: 20%;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-right: 20%;
  padding-left: 20%;
  opacity: 1.3;
  border: 1px solid #b3b3b3;
  background-color: lightgray;
  border-style: dashed;
}

/* ------------------------------------------------------------ *\
	Collapsible infobox
\* ------------------------------------------------------------ */
.collapsible-infobox-actions {
  display: flex;
}

/* ------------------------------------------------------------ *\
	Entris
\* ------------------------------------------------------------ */
.entris-beat-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.trust-account-selection {
  min-width: 50%;
}

/* ------------------------------------------------------------ *\
	Relations
\* ------------------------------------------------------------ */
.relations-table th {
  border-top: none;
  border-bottom: 1px solid #000 !important;
}
.relations-table td {
  border-top: none;
  border-bottom: 1px solid #ddd !important;
}

/* ------------------------------------------------------------ *\
	Accordion (should be moved to valiant styles)
\* ------------------------------------------------------------ */
.accordion {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.accordion-item:last-of-type {
  border-radius: 5px;
}
.accordion-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.accordion-item:first-of-type .accordion-button {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.accordion-button:not(.collapsed) {
  color: var(--default-lila);
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 5px;
}
.accordion-item:first-of-type {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  color: var(--default-lila);
  background-color: transparent;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}

/* ------------------------------------------------------------ *\
	Loader
\* ------------------------------------------------------------ */
.beatloader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 50px;
}

.react-beatloader span {
  background-color: #49A3D0 !important;
}

.application-bootstrap-default-loader span span{
  background-color: #49A3D0 !important;
}