.setadvisor-value {
  display: flex;
  align-items: center;
}

.setadvisor-value-icon {
  padding-right: 5px;
}

.setadvisor-value-label {

}

.setadvisor-value-fulfilled {
  color: #9baa00;
}

.setadvisor-value-notfulfilled {
  color: red;
}

.setadvisor-value-notfulfilled-icon {
  font-size: 1.3em;
}

.setadvisor-value-factsheet {
  color: rgb(114, 82, 172);
  cursor: pointer;
}

.setadvisor-value-factsheet-missing {
  color: black;
  opacity: 0.6;
  cursor: default;
}

.setadvisor-value-pricetotal {
  font-weight: 700;
}

.setadvisor-value-pricetotal-missing-rules {
  color: red;
}

.setadvisor-value-notpossible {
  color: red;
}
