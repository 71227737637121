.open-module-paper--customer-display {
  color: var(--valiant-neutral-black);
  display: block;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 26px !important;
}

.open-module-paper--title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px !important;
}

.open-module-paper--disclaimer {
  color: var(--valiant-neutral-black);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.open-module-paper--action-buttons {
  margin-top: 28px !important;
}

.open-module-paper-notification-options {
  display: flex;
  margin-top: 16px;
}

.open-module-paper-comments {
  margin-bottom: 16px;
}

.open-module-paper--label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding: 8px 0;
}

.open-module-paper-notification-options .react-form-radio-select--container {
  display: flex;
}
.open-module-paper--disclaimer {
  color: var(--valiant-neutral-black);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}