.lead-board-lead-footer--right--score {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 1.5rem;
  height: 1.5rem;
  color: #6a6a6a;
  padding-right: 1.2em;
  padding-left: 1.2em;
  border: 1px solid #acacac;
}

.lead-board-lead-footer--right--score--inner {
  font-size: 0.8em;
  font-weight: bold;
}

.lead-board-lead-footer--right--score--ge80 {
  color: var(--leadScore100);
  border-color: var(--leadScore100);
}

.lead-board-lead-footer--right--score--ge60 {
  color: var(--leadScore80);
  border-color: var(--leadScore80);
}

.lead-board-lead-footer--right--score--le60 {
  color: var(--leadScore60);
  border-color: var(--leadScore60);
}
