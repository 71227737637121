.graph-legend {
  display: flex;
}

.graph-legend__label {
  margin-right: 40px;
}

.graph-legend__root {
  border: #ccc solid 1px;
  border-radius: 5px;
  background-color: #00a5d5;
  width: 20px;
  margin-right: 10px;
}
.graph-legend__customer {
  border: #ccc solid 1px;
  border-radius: 5px;
  width: 20px;
  margin-right: 10px;
}


.graph-legend__customerbook {
  border: #ccc solid 1px;
  border-radius: 5px;
  background-color: #fff;
  width: 20px;
  margin-right: 10px;
}

.graph-legend__reg-arrow {
  color: #794cb2;
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
}

.graph-legend__crm-arrow {
  color: rgb(233, 122, 57);
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
}

.graph-legend__customerbook-arrow {
  color: #cfe400;
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
}

.graph-legend__poa-arrow {
  color: #cfe400;
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
}
