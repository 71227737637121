.workguide-form-workflow--node {
  padding: 0.4em;
}

.list-group-item:has(.workguide-form-workflow--node--invalid) {
  border-color: var(--bs-danger);
  border-top: 1px solid var(--bs-danger);
}

.workguide-form-workflow--node--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.workguide-form-workflow--node--header--title {
  font-weight: bold;
  font-size: 1.1em;
}

.workguide-form-workflow--node--exlapse {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 1.2em;
  font-size: 1.7em;
}

.workguide-form-workflow--node--expanded-content {
  padding-top: 1em;
}

.workguide-form-workflow--node--expanded-content--description {
  padding-bottom: 3em;
}
