.securities-account-opening-fund {
  display: flex;
  align-items: center;
  padding-bottom: 2px;
}

.securities-account-opening-fund--input {
  width: 30%;
  padding-right: 10px;
}
