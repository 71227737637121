.consultant-popover-info-organisation--entry{
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  .consultant-popover-info-organisation--entry--image {
  
    img {
      border-radius: 50%;
      height: 3.5em;
      width: 3.5em;
      object-fit: cover;
      object-position: 0% 10%;
    }

    .consultant-activity-status-container {
      position: relative;
      margin-top: -15px;
      margin-left: 32px;
    }

  }

  .consultant-popover-info-organisation--entry--name {
    display: flex; 
    flex-direction: column;
    justify-content: center;
  }

  .consultant-popover-info-organisation--entry--location {
    display: flex; 
    align-items: center;
  }
}