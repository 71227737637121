.product-grid__container {
    .slides-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        position: relative;
    }
    

    .title {
        text-align: left;
        margin-bottom: 5px;
        font-size: 14px;
        color: #333;
        font-weight: bold;
    }

    .sub-title {
        font-size: 12px;
        color: #999;
    }

    .sildes-container {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }

    .lilaset-products-external-links--container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
    }

    .lilaset-products-external-links {
        width: auto;
        padding-left: 20px;
    }
      
/* Initial state of entering element */
.product-card-enter {
    opacity: 0;
    transform: translateY(20px);
    
}

/* Animate to these styles */
.product-card-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
}

/* Initial state of exiting element */
.product-card-exit {
    opacity: 1;
    transform: translateY(0);
}

/* Animate to these styles */
.product-card-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 500ms, transform 500ms;
}

      
    
}
