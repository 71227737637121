.setadvisor-form-limit-calculation--monthly-income-item--header {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.setadvisor-form-limit-calculation--monthly-income-item--header--border {
  border-top: 1px solid lightgray;
}

.setadvisor-form-limit-calculation--monthly-income-item--header--remove {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2em;
  color: #666666;
}
