.loading-modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;  
}

.loading-modal .alert-content--children {
    display: flex;
    justify-content: space-between;
}
.loading-modal--paragraph {
    padding-top: 1rem;
}