.portfolio-transactions-text--information--popover .popover{
  max-width: 600px;
}

.portfolio-transactions-text--information--note--padding {
  padding-bottom: 0.5em;
}

.portfolio-transactions-text--information--popover--aligncenter {
  display: flex;
  justify-content: center; 
  align-items: center;
}

.portfolio-transactions-text--information--popover--aligncenter.react-beatloader span span {
  width: 10px !important;
  height: 10px !important;
  margin: 1.3px !important;
}