.estimation-protocol {
  margin-top: 20px;
}

.estimation-protocol__toolbar {
  display: flex;
  margin-bottom: 20px;
}

.estimation-protocol__toolbar .react-anchor {
  margin-right: 0;
}

.estimation-protocol__fields {
  display: flex;
}

.estimation-protocol__column {
  width: 400px;
  padding-right: 30px;
}

.estimation-protocol__results-table {
  margin-top: 20px;
}

.modal-dialog.estimation-detail-popup {
  min-width: 1000px;
}
.estimation-detail-popup {
  max-width: 740px;
}
.modal-dialog.estimation-info-popup{
  min-width: 740px;
}

.estimation-detail-table > .datagrid-header-row {
  padding-top: 30px;
  padding-bottom: 30px;
}
