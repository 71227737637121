.customer-overview-container {
  padding: 40px 0;
  .card-container--content {
    height: 100%;
  }
}
.customer-overview-content {
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.customer-overview-link {
  color: var(--neutral-dark-grey);
  cursor: pointer;
}
