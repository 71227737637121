.layout-header-user-profile .dropdown-menu {
  right: -20px;
  top: 5rem;
  min-width: 30vw;
  padding: 20px;
}

.layout-header-user-profile--dropwdown .dropdown-toggle::after {
  display: none;
}


.layout-header-user-profile .dropdown-item {
  color: #323232;
}

.layout-header-user-profile--dropdown-item {
  display: flex;
  align-items: center;
}

.layout-header-user-profile--dropdown-item--no-icon {
  padding-left: 2.12rem;
}


.layout-header-user-profile--dropdown-item
.mdi:before,
.mdi-set {
  vertical-align: super;
}
