.sort-dropdown {
  width: fit-content !important;
}

.sort-dropdown--label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sort-dropdown--label--reset {
  color: red;
  font-size: 1.1rem;
  padding-right: 0.1rem;
}

.sort-dropdown--label--reset--hidden {
  visibility: hidden;
}

.sort-dropdown--label .mdi-sort-variant {
  font-size: 1.1rem;
  padding-right: 0.4rem;
}
