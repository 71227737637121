.bpf-cms-identification-document-form-create--customer-list {
  padding-bottom: 20px;
}

.bpf-cms-identification-document-form-create--customer-list--label {
  display: flex;
  align-items: center;
  vertical-align: baseline;
}

.bpf-cms-identification-document-form-create--customer-list--label .mdi-account-plus {
  font-size: 1.5em;
  padding-left: 20px;
  cursor: pointer;
}
