.twint-modal .popover {
  .popover-header {
    .modal-main-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .popover-body {
    .label-spacing {
      margin: 10px;
    }
  }
}

.twint-modal--body {
  .counterparties-list {
    &::-webkit-scrollbar {
      width: 6px; /* Thin scrollbar */
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Light gray track */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Gray thumb */
      border-radius: 3px; /* Rounded thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Darker gray when hovered */
    }
  }


  .row {
    padding: 5px;

    > * {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .label-icon {
    font-size: 17px;
    padding-right: 5px;
    display: inline-flex;
  }
}

.twint-information-container .icon-col {
  align-items: center;
  display: flex;
  gap: 8px;
}
