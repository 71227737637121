.lead-revisions--versions {
  display: flex;
  align-items: center;
  padding-bottom: 2em;
  flex-wrap: wrap;
}

.lead-revisions--versions--version {
  padding: 0.7em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-right: 0.2em;
  margin-bottom: 0.2em;
  border: 1px solid var(--bs-gray-400);
  cursor: pointer;
  width: 16%;
}

.lead-revisions--versions--version--active {
  background-color: var(--form-color-primary-light);
}

.lead-revisions--versions--version--disabled {
  opacity: 0.4;
}
