.entris-documents-filter .ico-filter {
  background-image: unset;
}

.entris-documents-filter .filter-menu-label::before {
  font-family: "Material Design Icons";
  content: "\F0233";
  -webkit-font-feature-settings: 'liga';
  vertical-align: -10%;
  font-size: 1.6em;
  color: var(--neutral-dark-grey);
}

.entris-documents-filter .mdi {
  color: var(--neutral-dark-grey);
}

.entris-documents-filter .dropdown-menu {
  min-width: 20vw;
}
