.lead-board-lead-footer--right--editor {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #acacac;
  width: 1.5rem;
  height: 1.5rem;
  color: #6a6a6a;
  padding-right: 1.2em;
  padding-left: 1.2em;
}

.lead-board-lead-footer--right--editor--inner {
  font-size: 0.7em;
  font-weight: bold;
}

.lead-board-lead-footer--right--editor--container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.lead-board-lead-footer--right--editor--icon {
  font-size: 15; 
  padding-right: 1em;
  padding-left: 1em;
}
