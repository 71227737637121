.address-map--map-container {
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 100%; 
  height: 20em;
}

.address-map--map-iframe {
  width: 100%;
  height: 100%;
}