.filter-dropdown-sub-menu--dropdown-item .filter-dropdown-item--children {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filter-dropdown-sub-menu--dropdown-item .mdi-chevron-right {
  font-size: 1.2rem;
}

.filter-dropdown-sub-menu--dropdown-item
.mdi::before {
  vertical-align: super;
}
