.customer-activities-modal-search-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 0.75em;
  padding-top: 20px;
}

.customer-activities-modal-header .modal-title {
  width: 100%;
}

.customer-activities-modal-search-and-filter--search {
  width: 100%;
  padding-right: 50px;
}

.customer-activities-modal-search-and-filter--filter {
  padding-right: 50px;
}

.customer-activities-modal-search-and-filter--search input {
  border-right: 0px;
  height: 46px;
}

.customer-activities-modal-search-and-filter--loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  min-height: 40px;
}
