.turn-over-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.turn-over-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

.turn-over-content p {
    font-weight: bold;
}

.turn-over-content-text {
    width: 30%;
    display: flex;
    align-items: center;
}