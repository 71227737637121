.table-column-actions--main-actions--action {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}

.table-column-actions--main-actions--action--disabled {
  opacity: 0.4;
}

.table-column-actions--main-actions--action--icon {
  font-size: 1.1rem;
}

.table-column-actions--main-actions--action--label {
  padding-left: 0.5rem;
}

.table-column-actions--main-actions--action--no-icon {
  padding-right: 1.5rem;
}
