.consultant-popover-info-tab-nav {
  margin-bottom: 1.3em;

  .nav-link.active {
    background-color: transparent;
    border-bottom-color: #fafafa;
  }

}

.consultant-popover-info-organisation {
  padding-left: 1em;
  padding-right: 1em;

  max-height: 30em;
  overflow-y: auto;
  overflow-x: hidden;

  .consultant-popover-info-organisation-col {
    padding-bottom: 0.5em;
    font-weight: bold;
    border-bottom: 1px solid #c3c4c5;

    padding-left: 0;
    padding-right: 0;

    margin-right: calc(var(--bs-gutter-x)* .5);
    margin-left: calc(var(--bs-gutter-x)* .5);
  }
}
