.send-documents-physically--title {
  padding-top: 10px;
  padding-bottom: 20px;
}

.send-documents-physically--sub-title{
  padding-top: 10px;
  padding-bottom: 10px;
}

.send-documents-physically--form-col {
 .send-documents-physically--form-and-documents {
    background-color: white;
    padding: 20px;
  }
}

.send-documents-physically-form--info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.send-documents-physically-form--info-button-container{
  display: flex; 
  justify-content: flex-end; 
}