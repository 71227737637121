.issue-board-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px dashed black;
  opacity: 0.5;
}

.issue-board-dropzone--hidden {
  height: 0;
  width: 0;
}

.issue-board-dropzone--hover {
  background-color: lightgray;
}

.issue-board-dropzone:last-child {

}

.issue-board-dropzone-content {
  text-align: center;
}
