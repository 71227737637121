.quick-filters--button-add {
  display: flex;
  text-align: center;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  margin-bottom: 1rem;
  margin-right: 1rem;
  text-transform: uppercase;
  min-height: 38px;
}
