.error-error-detail {
  font-size: 0.95em;
}

.error-error-detail .row {
  padding-bottom: 0.7em;
}

.error-error-detail--section {
  padding-bottom: 1.5em;
}

.error-error-detail--section--title {
  font-weight: 700;
  text-decoration: underline;
  font-size: 1em;
}
