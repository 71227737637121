.layout-header-navbar {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0.9rem;
}

.layout-header-navbar--nav {
  justify-content: space-between;
}

.layout-header-navbar--nav--hidden {
  opacity: 0;
  transition: all 1s ease;
}

.layout-header-navbar--nav--visible {
  opacity: 1;
  transition: all 1s ease;
}

.layout-header-navbar--nav--powersearch {
  margin-left: 50px;
}

.layout-header-navbar--nav--open-new-tab {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.layout-header-navbar--nav--notification-center {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.open-new-tab-icon {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  cursor: alias;
  color: var(--default-lila);
  background-color: #6D53A720;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
}
.open-new-tab-icon::before {
top: 0
}

.layout-header-navbar--nav--open-new-tab:hover {
  color: var(--default-lila);
}

.layout-header-navbar--nav--user-profile {
  display: flex;
  align-items: center;
  min-width: 80px;
}

.layout-header-navbar--nav--news {
  display: flex;
  align-items: center;
}


/* Notificaiton Center styling start */
/* -------------------------- */
.layout-header-navbar--nav--notification-center {
  position: relative;
  width: 50px;
}

.notification-center-container.nc-hide {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.notification-center-container.nc-show {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

/* 
 Ensure the backdrop is hidden when .nc-hide is active. 
 This is necessary because the Offcanvas component remains mounted at all times. 
 Instead of unmounting, we only hide it to prevent unnecessary re-renders 
 when opening and closing the notification center.
*/
.notification-center-container.nc-hide + .offcanvas-backdrop {
  display: none !important;
}

.offcanvas.notification-center-container.offcanvas-end {
  width: 55%;
}


.notification-center-container.nc-hide {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-100%) !important;
  transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease-in-out, visibility 0.5s !important;
}

.notification-center-container.nc-show {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
  transform: translateY(0) !important;
  transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s ease-in-out !important;
}

.notification-center-container .offcanvas-body {
  padding-top: 0 !important;
  overflow-y: hidden
}
@media (min-width: 2000px) and (min-aspect-ratio: 16/9) {
  .offcanvas.notification-center-container.offcanvas-end {
    width: 40%;
  }
}
@media (min-width: 3200px) {
  .offcanvas.notification-center-container.offcanvas-end {
    width: 30%;
  }
}
.notification-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -7px;
  width: 24px;
  height: 24px;
  background-color: orange;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: 1px solid white;
  box-sizing: border-box;
  z-index: 1;
}

.notification-badge-small-text {
  font-size: 9px;
  top: -8px;
  right: -10px;
}

.sploosh-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.sploosh-ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 134, 64, 0.7);
  box-shadow: 0 0 0 0 rgba(255, 134, 64, 0.7);
  animation: sploosh 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}

.sploosh-ring-delay {
  animation-delay: 0.33s;
  animation-duration: 2.2s;
}

.badge-text {
  position: relative;
  z-index: 1;
  line-height: 1;
  text-align: center;
  width: 100%;
}

@keyframes sploosh {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 134, 64, 0.7);
    background: rgba(255, 134, 64, 0.7);
  }
  80% {
    background: rgba(255, 134, 64, 0);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 134, 64, 0);
  }
}
/* Notificaiton Center styling end */
/* -------------------------- */