
.lead-board-customer--board {
  margin-top: 2rem;
}

.lead-board-customer--row-filters {
  display: flex;
  align-items: center;
}

.lead-board-customer--row-filters--links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lead-board-customer--board {
  position: relative;
}

.lead-board-customer--board--row-columns {
  height: 100%;
}

.lead-board-customer--row-refresh-warning {
  padding-left: 1rem;
  padding-right: 6rem;
  padding-top: 2rem;
}
