.error-overview {
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 5em;
  padding-right: 5em;
}

.error-overview--title {
  padding-bottom: 2em;
}
