.activity-customer-overview .react-table-bootstrap-body-row {
  border-bottom: 0px solid #ffffff;
}

.activity-customer-overview--stickyrow {
  background-color: rgba(0, 0, 0, 0.04);
}

.activity-customer-overview .react-table-bootstrap-body-row td {
  vertical-align: top;
}


