.public-DraftEditorPlaceholder-root {
  pointer-events: none;
}

.activity-form-download-button-container {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 1.6em;
  color: #7252ac;
  right: -10px;
  z-index: 999;
}

