.workguide-multiselect-selected {
  margin-top: 0.24em;
  margin-left: 0.3em;
  float: left;
  border: 0px;
  border-radius: 2px;
  background-color: #794cb2;
  color: #fff;
}

.workguide-multiselect-selected-icon {
  float: left;
  margin-top: 0.15em;
  margin-left: 0.25em;
  font-size: 1.3em;
}

.workguide-multiselect-selected-label {
  float: left;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-right: 0.7em;
  padding-left: 0.5em;
  font-size: 1em;
}

.workguide-multiselect-selected-remove {
  float: left;
  margin-top: 0.2em;
  padding-left: 0.3em;
  padding-right: 0.3em;
  font-size: 1.3em;
  border-left: 1px solid #865eba;
  cursor: pointer;
}
