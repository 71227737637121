.lead-board-filter-menu---date-filter {
  .dropdown-item {
    background-color: #ffffff !important;
  }

  .input-group {
    flex-wrap: unset;
  }

  .input-group-text .mdi::before {
    left: unset;
  }
}
