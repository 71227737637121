.address-map--loader-container {
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center; 
  flex-direction: column;
}

.address-map--loader-container p {
  color: var(--neutral-dark-grey);
  margin-bottom: 0.5em;
}

.customer-address-table--expandable-building-info--row {
  padding-bottom: 0.5rem;
}

.address-building-info--container {
  padding-right: 1em; 
}

.address-building-info--title-row {
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  border-bottom: 1px solid #EBEBEB;
}

.address-building-info--title-row {
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  border-bottom: 1px solid #EBEBEB;
}

.address-building-info--content-row{
  padding-bottom: 0.5em;
}