.setadvisor-business-modal {
  margin-top: 6%;
  max-width: 98vw;
}

.setadvisor-business-modal .modal-content {
  padding: 10px;
}

.setadvisor-business-modal .modal-title {
  width: 100%;
}
