.open-account--header {
    display: flex;
    justify-content: space-between;
}

.open-account--quantity-selector {
    display: flex;
    gap: 24px;
}
.open-account--label {
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.open-account--quantity-selector {
    padding-top: 1em;
}

.open-account--quantity-selector .quantity-input {
    padding-top: 0;
}