.info-popover-base {
  width: 100%;
  min-height: 5em;
}

.info-popover-base .popover {
  background-color: #fafafa;
  border-radius: 4px;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  max-width: 1000px;
  width: 100%;
}

.info-popover-base .popover-header {
  background-color: var(--bs-body-bg);
  border-bottom: 0;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 2.7em;
}

.info-popover-base .popover-header .modal-main-title {
  font-size: 1.2em;
  font-weight: 700;
}

.info-popover-base .popover-body {
  padding-top: 1.5em;
  padding-left: 2.7em;
  padding-bottom: 2em;
  padding-right: 2em;
}

.info-popover-base .popover-body p {
  margin-bottom: 0.5em;
}
