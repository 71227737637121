.lead-board-lead-content {
  padding-top: 1.7rem;
  padding-left: 2.9rem;
}

.lead-board-lead-content--type {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 700;
  line-height: 14px;
}
