.provision-account-select--existing-account--row {
  margin-bottom: 1em;
  margin-left: 1.1em;
}

.provision-account-select--existing-account-pledge-alert--row {
  margin-top: 1em;
}

.provision-account-select--new-account--row {
  margin-bottom: 10px;
  margin-left: 16px;
  margin-top: 10px;
}