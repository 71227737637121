.lead-board-filter-menu--item--customerTaskType--select {
  padding-left: 2.3rem;
  padding-right: 1.4rem;
  padding-bottom: 1rem;
}

.lead-board-filter-menu--item--resolvedDate--input {
  padding-left: 2.3rem;
  padding-right: 1.4rem;
  padding-bottom: 1rem;
}

.lead-board-filter-menu--item--resolvedDate--input .mdi::before {
  top: unset;
  left: unset;
}
