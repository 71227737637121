.customer-detail {
  display: flex;
  /* background-color: #FBFCFD; */
  height: 100%;
  min-height: calc(100vh - 5.5rem);
}

.customer-detail-content {
  margin-top: 4rem;
  padding-left: 8rem;
  padding-right: 3rem;
}
