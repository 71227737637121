.customer-modal .popover {
  max-width: 800px;
}

.l * {
  pointer-events: none;
}

.customer-modal--header {
  position: relative;
}
.customer-modal--header-close {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  padding: 0;
  position: absolute;
  right: 27px;
  top: 26px;
  width: auto;
}
.customer-modal--header-close::after {
  content: '✕';
}

.customer-modal .popover-header .modal-main-title {
  font-size: 18px;
  font-weight: 700;
}

.customer-modal .popover-header .modal-customer-type {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.customer-modal .popover-header .modal-customer-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  line-height: 24px;
}

.customer-modal .mdi::before {
  vertical-align: unset;
  top: 0;
}

.customer-modal .col {
  padding: 0;
}
.customer-modal .row {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.customer-modal--body .row {
  padding: 5px;
}

.customer-modal--body .row > * {
  padding-left: 0;
  padding-right: 0;
}

.customer-modal--body .label-icon{
  font-size: 17px;
  padding-right: 5px;
  display: inline-flex;
}

.customer-information-container .icon-col {
  align-items: center;
  display: flex;
  gap: 8px;
}

.customer-information-container .text-cutted {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.customer-information-container p {
  margin-bottom: 0;
}

.mdi-file-edit-outline.edit-icon {
  cursor: pointer;
  align-self: self-end;
}

.customer-information-container .link-item--text {
  font-size: 12.25px;
}
