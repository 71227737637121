.securities-account-opening-payment-rules {
  display: flex;
  align-items: center;
}

.securities-account-opening-payment-rules--button {
  padding: 10px;
  border: 0.5px solid #f2f2f2;
  width: 15%;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.securities-account-opening-payment-rules--button-active {
  background-color: #95bcf2;
}

.securities-account-opening-payment-rules--link {
  padding-left: 20px;
}
