.filter-dropdown-item {
  display: flex;
  align-items: center;
}

.filter-dropdown-item--check {
  font-size: 1.2rem;
  color: var(--bs-purple);
}

.filter-dropdown-item--check
.mdi::before {
  vertical-align: super;
}

.filter-dropdown-item--without-check{
  width: 22px;
}
