.contract-accounts-info-icon {
  cursor: pointer;
  font-size: 1.3em;
}

.contract-accounts-popover {
  min-width: 30%;
  padding: 0px 10px 0px 10px;
  background-color: #f4f6f6;
  font-size: 0.9em;
}

.contract-accounts-popover-header {
  width: 100%;
  text-align: right;
  cursor: pointer;
  font-size: 1.5em;
  margin-bottom: -10px;
}