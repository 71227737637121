.setadvisor-form-limit-determintation--coverage {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.setadvisor-form-limit-determintation--slider {
  padding-top: 0em;
}

.setadvisor-form-limit-determintation--slider .rc-slider {
  background-color: rgba(155, 170, 0, 0.2);
}

.setadvisor-form-limit-determintation--slider .rc-slider-track {
  background-color: rgba(155, 170, 0, 0.2);
  height: 0.8rem;
}

.setadvisor-form-limit-determintation--slider .rc-slider-rail {
  background-color: rgba(155, 170, 0, 0.2);
  height: 0.8rem;
}

.setadvisor-form-limit-determintation--slider .rc-slider-handle {
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--bs-success);
  border-color: var(--bs-success);
  cursor: unset;
}

.setadvisor-form-limit-determintation--slider--warning .rc-slider {
  background-color: rgba(242, 45, 84, 0.2)
}

.setadvisor-form-limit-determintation--slider--warning .rc-slider-track {
  background-color: rgba(242, 45, 84, 0.2)
}

.setadvisor-form-limit-determintation--slider--warning .rc-slider-rail {
  background-color: rgba(242, 45, 84, 0.2)
}

.setadvisor-form-limit-determintation--slider--warning .rc-slider-handle {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
  cursor: unset;
}
