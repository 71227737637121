.customer-ebanking-contract-detail {
  padding: 10px 15px 10px 15px;
  height: 100%;
}

.customer-ebanking-contract-detail-item {
  padding-bottom: 20px;
}

.customer-ebanking-contract-detail-item-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.customer-ebanking-contract-detail-item-value .mdi-alert-circle-outline{
  font-size: 1.3em;
}

.customer-contract-permissions .customer-contract-expanded-row-collapse--content {
  padding-top: 0;
}
