.account-selector--container {
    padding: 30px;
}

.account-selector--wrapper {
    display: flex;
}

.account-selector--label {
    line-height: 24px;
    font-size: 14px;
    font-weight: 700;
}

.account-selector--row {
    align-items: center;
    margin-bottom: 12px;
    min-height: 38px;
}

.account-selector--rubric-col {
    padding-bottom: 1em;
}
