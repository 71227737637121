.bpf-cms-identification-document-form-comments--comment {
  padding-bottom: 20px;
}

.bpf-cms-identification-document-form-comments--comment--editor {
  padding-bottom: 5px;
}

.bpf-cms-identification-document-form-comments--comment--comment {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.bpf-cms-identification-document-form-comments--comment--timestamp {
  text-align: right;
  padding-top: 5px;
}
