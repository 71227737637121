.credit-card-container {
  position: relative;
  width: 100%; 
}

.credit-card-image {
  width: 65%;
  display: block;
  border-radius: 10px;
}

.credit-card-info {
  position: absolute;
  top: 0;
  left: 10px;
  width: 65%;
  height: 100%;
  padding: 1rem; 
}

 .card-label-expiry, .card-label-card-number {
  position: absolute;
  left: 40px;
  color: white;
  font-family: 'Arial', sans-serif;
  font-size: 0.6vw;
}
.card-label-expiry {
  top: 75%;
}
.card-label-card-number {
  top: 75%;
  left: 20%;
}

.card-holder, .card-number, .card-number-left, .expiry-date, .card-iban {
  position: absolute;
  left: 40px;
  color: white;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
  font-size: 1vw;
}

.card-holder {
  top: 52%
}

.card-iban {
  top: 62%; 
  letter-spacing: 3px;
}

.expiry-date {
  top: 80%; 
}

.card-number {
  top: 62%; 
  letter-spacing: 3px;
}

.card-number-left {
  top: 80%; 
  left: 20%;
  letter-spacing: 3px;
}
