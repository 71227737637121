.workguide-fundinvest-funds--labels {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: bold;
  padding-bottom: 10px;
}

.workguide-fundinvest-funds--hint {
  padding-top: 20px;
}
