.customer-detail-header-kpi--item {
  text-align: center;
  padding-right: 40px;
  cursor: pointer;
}

.customer-detail-header-kpi--item--kubi {
  display: flex;
  align-items: flex-end;
  height: 48px;
}

.customer-detail-header-kpi--item--no-value {
  filter: grayscale(100%) opacity(50%);
}

.customer-detail-header-kpi--item--image img {
  height: 48px;
}

.customer-detail-header-kpi--item--value {
  padding-top: 10px;
  font-size: 20px;
  line-height: 10px;
  font-weight: 600;
  color: var(--default-lila);
}

.customer-detail-header-kpi--item:last-child {
  padding-right: 0px;
}
