.securities-account-opening-eeg {
  display: flex;
}

.securities-account-opening-eeg--disabled {
    opacity: 0.5;
}

.securities-account-opening-eeg--button {
  padding: 10px;
  border: 0.5px solid #f2f2f2;
  width: 15%;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.securities-account-opening-eeg--button-active {
  background-color: #95bcf2;
}
