.feature-flag-menu-item-collapse-description--span {
  width: 100%;
  display: inline-block;
  height: auto;
  white-space: normal;
}

.feature-flag-status-icon.mdi::before{
  top: 2px;
}

.feature-flag-menu-item-collapse-description--divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, .15);
}

.feature-flag-menu-item--form-group {
  margin-bottom: 0 !important;
}