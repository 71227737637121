.customer-bank-relation-card {
    min-height: 65px;
    min-width: 190px; 
    max-width: 360px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 1px 1px 15px 3px rgba(0,0,0,0.05);
    flex: 1 1 calc(33% - 12px);
    margin: 7px;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;

    .customer-bank-relation-card__title {
      margin-bottom: 5px;
      text-align: left;
      color: #333;
      font-weight: bold;
    }

    .customer-bank-relation-card__outbound-container {
        margin-top: 5px;
        font-size: 12px;
    }
  }
  