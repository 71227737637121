.customer-detail-header-flag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px 4px 12px;
  border: 0.25px solid black;
  border-radius: 100px;
  gap: 8px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
  background: #ffffff;
  margin-right: 0.7em;
}

.customer-detail-header-flag--info {
  color: var(--bs-info);
  border-color: var(--bs-info);
  background-color: rgba(0, 165, 213, 0.15);
}

.customer-detail-header-flag--neutral {
  color: var(--bs-gray-500);
  border-color: var(--bs-gray-500);
  background-color: rgba(158, 158, 158, 0.15);
}

.customer-detail-header-flag--success {
  color: var(--bs-success);
  border-color: var(---bs-success);
  background-color: rgba(155, 170, 0, 0.15)
}

.customer-detail-header-flag--warning {
  color: var(--bs-warning);
  border-color: var(--bs-warning);
  background-color: rgba(243, 169, 27, 0.15);
}

.customer-detail-header-flag--danger {
  color: var(--bs-danger);
  border-color: var(--bs-danger);
  background-color: rgba(242, 45, 84, 0.15);
}

.check-duplicate-flag-modal h5 {
  margin-bottom: 0 !important;
}
