.activity-overview-modal {

  .modal-content{
    height: calc(100vh - 56px);
    overflow: hidden;

    .modal-body{
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .all-activities-modal--search-container{
    height: 65px;
    padding-bottom: 20px;
  }

  .all-activities-modal--body-container {
    height: calc(100% - 56px - 65px);
    overflow-y: scroll;
    display: block;

    .all-activities-modal--body-container--title-col {
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .all-activities-modal--body-container--list-col {
    padding-bottom: 10px;
    padding-top: 10px;
  }

.all-activities-modal--body-container--list-col .copy-to-clipboard .mdi::before {
  vertical-align: unset;
}

  .all-activities-modal--body-row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
}
