.bpf-cms-profile-completion-item {
  padding-top: 2px;
  padding-bottom: 5px;
}

.bpf-cms-profile-completion-item--label {
  display: flex;
  align-items: center;
}

.bpf-cms-profile-completion-item--label--icon {
  padding-right: 10px;
  font-size: 1.2em;
}

.bpf-cms-profile-completion-item--fulfilled .bpf-cms-profile-completion-item--label--icon {
  color: #000000;
}

.bpf-cms-profile-completion-item--unfulfilled .bpf-cms-profile-completion-item--label--icon {
  color: var(--bs-danger);
}

.bpf-cms-profile-completion-item--running .bpf-cms-profile-completion-item--label--icon {
  color: var(--bs-warning);
}

.bpf-cms-profile-completion-item--actions {
  display: flex;
  justify-content: flex-end;
  padding-left: 0px;
}

.bpf-cms-profile-completion-item--actions .btn {
  border-radius: 4px;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-size: 12px;
  line-height: 12px;
}

.bpf-cms-profile-completion-item--hint {
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  color: rgba(50, 50, 50, 0.25);
}

.bpf-cms-profile-completion-button {
  min-width: 8rem;
}