.migrationsearch {
  text-align: left;
  padding: 10px;
  margin-top: 15px;
}

.migrationsearch .collapsible-row {
  padding-left: 19px;

}

.migrationsearch .collapsible-box-header {
  padding-bottom: 0px;
}

.migrationsearch-result {
  margin-top: 10px;
  height: 450px;
  overflow: scroll;
}

.migrationsearch-header {
  text-align: left;
  font-size: 0.9em;
  padding-top: 5px;
  padding-left: 0px;
  width: 100%;
  padding-bottom: 10px;
}

.migrationsearch-close {
  position: absolute;
  font-size: 1.7em;
  color: #b3b3b3;
  top: 0.3em;
  right: 0px;
}

.migrationsearch-input-group {
  border: 1px solid #ced4da;
}

.migrationsearch-input {
  border: 0px;
  outline: none;
  box-shadow: none !important;
}

.migrationsearch-input-addon {
  border: 0px;
  padding-left: 0.5em;
  font-size: 1.8em;
  color: #7252ac;
}


.migrationsearch-list {
  padding-top: 5px;
}

.migrationsearch-list-item {
  color: #7252ac;
  cursor: pointer;
  padding: 3px 0 3px 10px;
  font-size: 16px;
}

.migrationsearch-list-item-active {
  background-color: #f2f2f2;
}

.migrationsearch-list-item:hover {
  background-color: #f2f2f2;
}

.migration-list__row {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 20px;
}

.migration-list__account {
	font-weight: bold;
}


