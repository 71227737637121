
.external-link-box {
  height: 50px;
  padding: 3px;
}
.external-link-box a {
  font-size: 11px;
  font-weight: bold;
  padding: 5px;
}
.box--light .box__inner .circle {
  border: 1px solid var(--neutral-dark-grey);
  color: #212121;
}
.box--success .box__inner .circle {
  border: 1px solid #9baa00;
  color: #9baa00;
}
.box--warning .box__inner .circle {
  border: 1px solid #f3a91b;
  color: #f3a91b;
}
.box--danger .box__inner .circle {
  border: 1px solid #f22d54;
  color: #f22d54;
}
.box--default .box__inner .circle {
  border: 1px solid #49a3d0;
  color: #49a3d0;
}
.box--valiant .box__inner .circle {
  border: 1px solid #6D53A7;
  color: #6D53A7;
}

.circle {
  display: inline-table;
  vertical-align: middle;
  width: 45px;
  height: 30px;
  background-color: white;
  color: black;
  border-radius: 20px;
  margin-right: 5px;
}
.circle__content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
