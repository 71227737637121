.bpf-cms-self-declaration-aia-fatca-form--row-yes-no {
  display: flex;
  align-items: center;
  height: 70px;
}

.bpf-cms-self-declaration-aia-fatca-form--row-hints {
  padding-top: 100px;
  padding-bottom: 40px;
}
