.activity-filter-menu-item-date {
  padding-bottom: 10px;
}

.activity-filter-menu-item-date--date-field {
  padding-top: 10px;
  padding-left: 18px;
  padding-right: 18px;
}

.activity-filter-menu-item-date--date-field i {
  padding-left: 5px;
  margin-top: -3px;
}
