.layout-header-user-profile--dropdown--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-bottom: 2rem;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #323232;
}

.layout-header-user-profile--dropdown--title--close {
  font-size: 1.4rem;
  cursor: pointer;
}

.layout-header-user-profile--dropdown--title
.mdi:before,
.mdi-set {
  vertical-align: top;
}
