.ebanking_order_via_mobileapp-information {
    padding: '1rem 1rem';
    .row {
        min-height: 25px;
        align-items: center;
    }
}
.ebanking_order_via_mobileapp-divider {
    border-bottom: 2px solid #8080802e;
    width: 95%;
}

.status-yellow {
    color: var(--bs-warning);
    font-size: 20px;
}
.status-green {
    color: var(--leadScore60);
    font-size: 20px;
}
.status-undefined {
    display: none;
}

