.workguide-form-workflow--summary-overlay--actions--action {
  padding-bottom: 0.7em;
}

.workguide-form-workflow--summary-overlay--actions--action--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.workguide-form-workflow--summary-overlay--actions--action--messages--message {}
