.theme--inspiring-sunset {
  --bs-primary: hsl(36, 100%, 45%);

  .layout-header-navbar {
    background-color: hsl(36, 100%, 50%) !important;
  }

  .layout-header-navbar .dropdown-toggle {
    background-color: hsl(36, 100%, 50%) !important;
  }

  .layout-content {
    background-color: hsl(36, 100%, 95%) !important;
  }

  .card-container {
    background-color: hsl(36, 100%, 98%) !important;
  }

  .customer-detail-navigation {
    background-color: hsl(36, 100%, 50%) !important;
  }

  .ag-root-wrapper {
    background-color: hsl(36, 100%, 98%) !important;
  }

  .ag-row {
    background-color: hsl(36, 100%, 98%) !important;
  }

  .ag-header {
    background-color: hsl(36, 100%, 95%) !important;
  }
}
