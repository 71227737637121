.workbench-overview-error {
  width: 120%;
  text-align: center;
}

.workbench-overview-error-buttons {
  text-align: center;
  padding-top: 20px;
}

.workbench-overview-error-buttons--button {
  padding-bottom: 20px;
}

.workbench-overview-error-show-detail {
  cursor: pointer;
}
