.customer-detail-navigation--link {
  display: flex;
  align-items: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-right: 2rem;
  padding-left: 2rem;
  cursor: pointer;
  width: auto;
}

.customer-detail-navigation--link--disabled {
  opacity: 0.6;
}

.customer-detail-navigation--link--expanded {
  padding-right: 8rem;
}

.customer-detail-navigation--link--active {
  color: var(--bs-primary);
  background-color: rgba(0, 0, 0, 0.04);
}

.customer-detail-navigation--link--arrow {
  position: absolute;
  right: 10px;
  font-size: 1.6em;
  vertical-align: -10%;
  visibility: hidden;
}

.customer-detail-navigation--link:hover .customer-detail-navigation--link--arrow {
  visibility: visible;
}

.customer-detail-navigation--link--icon {
  font-size: 1.2rem;
  position: relative;
}

.customer-detail-navigation--link--label span {
  padding-left: 1rem;
}

.pulsing-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  animation: impulseScale 2s infinite;
  color: #ff8640;
}


@keyframes impulseScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
