.activity-customer-overview--column-note {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}

.activity-customer-overview--column-note--preview {
  max-height: 20px;
  overflow: hidden;
  cursor: pointer;
  font-weight: normal !important;
}

.activity-customer-overview--column-note--preview-note {
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}

.activity-customer-overview--column-note--preview strong {
  font-weight: normal !important;
}

.activity-customer-overview--column-note--expand-collapse {
  font-size: 2em;
  cursor: pointer;
  padding-left: 20px;
}
