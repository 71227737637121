.custom-pagination {
  display: flex;
  flex-direction: row;

  .custom-pagination--total-count {
    color: #212121;
    padding-right: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-pagination--pagination-container {

    .custom-pagination--pagination {
      .page-item {
        display: flex;
        justify-content: center;
    
        .page-link {
          font-size: 1.4em;
          border-radius: 15%;
          border: none;
          padding-top: 0.1em;
          padding-bottom: .3rem;
          padding-left: .75rem;
          padding-right: .75rem;
          color: rgb(33, 33, 33);
        }
      }

      .pagination-page-item--next-page .page-link{
        display: flex;
        cursor: pointer;
        font-size: 1em !important;
        text-align: center;
        vertical-align: middle;
        height: 100%;
        align-content: center;
        align-items: center;
        border-radius: 5px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
}