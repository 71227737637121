.activity-filter-menu-item-checkbox-content {
  display: flex;
  align-items: center;
  font-size: 1.1em;
}

.activity-filter-menu-item-checkbox-content--checkbox-content {
  display: flex;
  align-items: center;
  color: black;
  padding-left: 5px;
}

.activity-filter-menu-item-checkbox-content--checkbox-content--children {
  padding-top: 3px;
  margin-left: -5px;
}
