.setadvisor-form-contract-dispatch-rules--dispatch-rule .react-form-radio-button-group {
  display: flex;
  align-items: center;
}

.setadvisor-form-contract-dispatch-rules--dispatch-rule .react-form-radio-button-group--child {
  display: flex;
  align-items: center;
  padding-right: 0.7em;
  padding-bottom: 0;
}

.setadvisor-form-contract-dispatch-rules--dispatch-rule .react-form-radio-button-group--child .mdi:before {
  vertical-align: baseline;
  top: 0;
}
