$text-muted: #6c757d;
$primary-color: var(--default-lila);
$icon-color: #424242;
$transition-duration: 0.2s;

.notification-header {
  cursor: pointer;
  transition: background-color $transition-duration ease;
  padding: 0.5rem;
  border-radius: 8px;


  &__content {
    margin-bottom: 0.5rem;
  }

  &__meta {
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: $text-muted;

    &-username {
      font-weight: 500;
    }

    &-separator {
      opacity: 0.5;
    }
  }

  &__action-button {
    visibility: hidden;
    transition: visibility $transition-duration ease, opacity $transition-duration ease;
    opacity: 0;
    background: transparent;
    border: none;
    color: var(--default-lila);
    padding: 0;
    
    &:hover {
      opacity: 0.8;
    }
    
    &-icon {
      font-size: 1.2rem;
    }
  }

  &:hover {
    .notification-header__action-button {
      visibility: visible;
      opacity: 1;
    }
  }
  

  &__toggle-icon {
    font-size: 1.7rem;
    color: $icon-color;
    transition: transform $transition-duration ease;

    &--expanded {
      transform: rotate(180deg);
    }


  }
}