.consultant-details-wrapper .consultant-activity-status-container {
  position: absolute;
  margin-top: -25px;
  margin-left: 90px;
}


.consultant-details-wrapper .consultant-activity-status-span{
  font-size: 20px;
}

.consultant-details-wrapper .consultant-activity-status-span::before{
  background-color: white;
  border-radius: 50%;
}

.layout-header-user-profile--image--container .consultant-activity-status-container {
  position: absolute;
  top: 32px;
  left: 32px;
}

.layout-header-user-profile--image--container .consultant-activity-status-span {
  font-size: 15px;
}

.layout-header-user-profile--image--container .consultant-activity-status-span::before{
  background-color: white;
  border-radius: 50%;
}

.layout-header-user-profile--image.userswitch-profile-image .consultant-activity-status-container {
  position: absolute;
  top: 32px !important;
  left: 120px !important;
}
