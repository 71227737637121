.product-card-container {
  min-height: 65px;
  min-width: 190px;
  max-width: calc(33% - 12px);
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 15px 3px rgba(0, 0, 0, 0.05);
  flex: 1 1 calc(33% - 12px);
  margin: 7px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  // &:hover {
  //   transform: scale(1.02);
  //   box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.1);
  // }

  .product-card-container-inner {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .product-card-button {
    height: 20px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .recommend-badge {
    background-color: orange;
    border-radius: 5px;
    font-size: 8px;
    color: white;
    height: 18px;
    width: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-right: 10px;
  }
}