.workbench-overview-table-column-actions--assignee-change-popover {
  max-width: unset;
}

.workbench-overview-table-column-actions--assignee-change-popover .popover {
  max-width: 350px; 
}

.workbench-overview-table-column-actions--assignee-change-popover-inner {
  min-width: 450px;
}

.workbench-overview-table-column-actions--assignee-change-content {
  padding: 20px;
  min-width: 40%;
}

.workbench-overview-table-column-actions--assignee-change-content-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.workbench-overview-table-column-actions--assignee-change-content--consultant {
  width: 80%;
}

.workbench-overview-table-column-actions--assignee-change-content--consultant--icon
.mdi:before,
.mdi-set {
  top: 0px;
  left: unset;
}

.workbench-overview-table-column-actions--assignee-change-content--links-and-loader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.workbench-overview-table-column-actions--assignee-change-content--link {
  padding-bottom: 5px;
  font-weight: bold;
}

.workbench-overview-table-column-actions--assignee-change-content--loader {
  padding-right: 30%;
}

.workbench-overview-table-column-actions--assignee-change--dropdown-menu {
  right: 2.6rem !important;
  min-width: 30vw;
}
