
$read-background: #f5f5f5;
$unread-background: white;
$read-text-color: #757575;
$unread-text-color: #212121;
$border-color: var(--default-blue);
$box-shadow: 1px 1px 15px 3px rgba(0, 0, 0, 0.05);
$transition-duration: 0.2s;

.notification-child {
  border-radius: 8px;
  padding: 0.75rem;
  transition: all $transition-duration ease;
  margin: 0 10px;
  &--unread {
    background-color: $unread-background;
   
    border-left: 4px solid $border-color;
    box-shadow: $box-shadow;
    .notification-child__content {
      color: $unread-text-color;
    }
  }
  
  &--read {
    background-color: $read-background;
    
    .notification-child__content {
      color: $read-text-color;
    }
  }
  
  &__content {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
  
  &__meta {
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6c757d;
    
    &-username {
      font-weight: 500;
    }
    
    &-separator {
      opacity: 0.5;
    }
  }
  
  &__mark-button {
    visibility: hidden;
    transition: visibility $transition-duration ease, opacity $transition-duration ease;
    opacity: 0;
    background: transparent;
    border: none;
    color: var(--default-lila);
    padding: 0;
    
    &:hover {
      opacity: 0.8;
    }
    
    &-icon {
      font-size: 1.2rem;
    }
  }
  
  &:hover {
    .notification-child__mark-button {
      visibility: visible;
      opacity: 1;
    }
  }
}