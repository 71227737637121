.bpf-cms-profile-completion-missing-properties {
  font-size: 0.7rem;
  padding-bottom: 1rem;
}

.bpf-cms-profile-completion-missing-properties--title {
  font-weight: bold;
  text-decoration: underline;
  padding-left: 1.8rem;
  padding-bottom: 0.5rem;
}

.bpf-cms-profile-completion-missing-properties--row--category {
  padding-left: 2.5rem;
}

.bpf-cms-profile-completion-missing-properties--row--key {
  padding-left: 0px;
  font-weight: bold;
}