.customer-detail-navigation--link-count-badge {
  position: absolute;
  top: -12px;
  right: -20px;
  font-size: 12px;
  font-weight: bold;
  width: 22px;
  height: 22px; 
  border-radius: 50%;
  color: white;
  box-sizing: border-box;
  display: none; 
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 22px;
}

.customer-detail-navigation--link-count-badge.active {
  display: flex;
  background-color: orange;
}


.customer-detail-navigation--link-count-badge span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 100%;
  min-width: 22px;
}


.customer-detail-navigation--link-count-badge-disabled {
  opacity: 0.5;
}

.customer-detail-navigation--link-count-badge-small-text {
  font-size: 9px;
}

.sploosh-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.sploosh-ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 134, 64, 0.7);
  box-shadow: 0 0 0 0 rgba(255, 134, 64, 0.7);
  animation: sploosh 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}

.sploosh-ring-delay {
  animation-delay: 0.33s;
  animation-duration: 2.2s;
}

.badge-text {
  position: relative;
  z-index: 1;
  line-height: 1;
  text-align: center;
  width: 100%;
}

@keyframes sploosh {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 134, 64, 0.7);
    background: rgba(255, 134, 64, 0.7);
  }
  80% {
    background: rgba(255, 134, 64, 0);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 134, 64, 0);
  }
}