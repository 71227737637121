.contact-form-list-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.contact-form-list-item--invalid {
  border-color: red;
}

.contact-form-list-item--actions {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}

.contact-form-list-item--actions .mdi {
  cursor: pointer;
}
