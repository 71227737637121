.workbench-acitivity-change-check-modal-body {
  text-align: center;
  padding: 40px 60px 40px 60px;
}

.workbench-acitivity-change-check-modal-body--title {
  padding-bottom: 30px;
  color: red;
}

.workbench-acitivity-change-check-modal-body--description {
  padding-bottom: 40px;
  line-height: 2em;
}
