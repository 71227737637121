.workguide-customer-account-positions--positions {
  padding-bottom: 1rem;

}

.workguide-customer-account-positions--positions .list-group-item.active {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: black;
  font-weight: bold;
}
