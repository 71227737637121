.workbench-dashboard-score-item {
  border-radius: 4px;
  font-weight: 700;
  padding-top: 1.2rem;
  background: #f6fafd;
  color: #6a6a6a;
}

.workbench-dashboard-score-item-total--count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  width: 100%;
  cursor: pointer;
}

.workbench-dashboard-score-item-total--label {
  display: flex;
  justify-content: center;
  font-size: 1em;
  padding-bottom: 0.5em;
}

.workbench-dashboard-score-item-divider {
  height: 0.05rem;
  background-color: #bbbec0;
  margin-left: 2.2em;
  margin-right: 2.2em;
  margin-top: 0.8em;
  margin-bottom: 0.7em;
}

.lead-score-80 .workbench-dashboard-score-item-divider {
  background-color: #79bbdc;
}

.lead-score-60 .workbench-dashboard-score-item-divider {
  background-color: #79bbdc;
}

.workbench-dashboard-score-item-lead-category {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.workbench-dashboard-score-item-lead-category--count {
  font-size: 1.4em;
  padding-right: 0.4em;
  font-weight: 800;
  width: 45%;
  text-align: right;
  cursor: pointer;
}

.workbench-dashboard-score-item-lead-category--label {
  font-size: 0.8em;
  padding-left: 0.4em;
  width: 55%;
  text-align: left;
}

.workbench-dashboard-score-item--footer {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-top: 1em;
  padding-bottom: 1em;
  color: #666666;
}

.workbench-dashboard-score-item--footer--score {
  border: 1px solid #acacac;
  border-radius: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 70%;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
}

.workbench-dashboard-score-item--score-gt-80 .workbench-dashboard-score-item--footer--score {
  border-color: var(--leadScore100);
  color: var(--leadScore100);
}

.workbench-dashboard-score-item--score-gt-60 .workbench-dashboard-score-item--footer--score {
  border-color: var(--leadScore80);
  color: var(--leadScore80);
}

.workbench-dashboard-score-item--score-lt-60 .workbench-dashboard-score-item--footer--score {
  border-color: var(--leadScore60);
  color: var(--leadScore60);
}

