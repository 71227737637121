.card-container--header--main-actions {
  display: flex;
  width: auto;
  align-items: center;
}
.card-container--header--main-actions--action {
  width: auto;
  display: flex;
  align-items: center;
  color: var(--neutral-dark-grey);
  cursor: pointer;
  font-size: 1.1em;
  padding-right: 1rem;
}

.card-container--header--main-actions--action .mdi:before {
  vertical-align: baseline;
  padding-right: 5px;
  top: 2px;
}
