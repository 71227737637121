.reload-modal {
  text-align: center;
}

.reload-modal--title {
  font-size: 1.4em;
  padding-top: 2rem;
}

.reload-modal--paragraph {
  padding-top: 3rem;
}

.reload-modal--switch {
  padding-top: 2rem;
}

.reload-modal--hint {
  font-weight: bold;
  padding-top: 4rem;
  padding-bottom: 2rem;
}
