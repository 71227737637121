.customer-detail-content--overview--leads .react-table-footer-pagination {
  display: none;
}

.customer-detail-content--overview--leads .react-table-bootstrap-body-row {
  border-bottom: 0px solid #ffffff;
}

.customer-detail-content--overview--leads--footer {
  padding-top: 2rem;
  font-size: 0.9em;
}
