@keyframes firework {
  0% { transform: translate(var(--x), var(--initialY)); width: var(--initialSize); opacity: 1; }
  50% { width: 1vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
}

/* @keyframes fireworkPseudo {
  0% { transform: translate(-50%, 50%); width: var(--initialSize); opacity: 1; }
  50% { width: 0.5vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
} */

/* Not sure if this code is scoped. I seams like, because it does not effect any other pages*/
/* Well is does... It changes the scoll parent from layout-content to window which leads to problems in lead management */
/*
.layout-content {
  overflow: unset;
}
*/

.firework,
.firework::before,
.firework::after
{
  --initialSize: 0.5vmin;
  --finalSize: 70vmin;
  --particleSize: 0.1vmin;
  --y: -30vmin;
  --x: -50%;
  --initialY: 60vmin;
  content: "°";
  animation: firework 2s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, var(--y));
  width: var(--initialSize);
  aspect-ratio: 1;
  background:

    radial-gradient(circle, #6D53A7 var(--particleSize), #0000 0) 0% 0%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #0000 0) 100% 0%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #0000 0) 100% 100%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #0000 0) 0% 100%,


    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 50% 0%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 100% 50%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 50% 100%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 0% 50%,

    /* bottom right */
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 80% 90%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 95% 90%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 90% 70%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 100% 60%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 55% 80%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 70% 77%,

    /* bottom left */
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 22% 90%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 45% 90%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 33% 70%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 10% 60%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 31% 80%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 28% 77%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 13% 72%,

    /* top left */
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 80% 10%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 95% 14%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 90% 23%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 100% 43%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 85% 27%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 77% 37%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 60% 7%,

    /* top right */
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 22% 14%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 45% 20%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 33% 34%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 10% 29%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 31% 37%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 28% 7%,
    radial-gradient(circle, #6D53A7 var(--particleSize), #6D53A7 0) 13% 42%
    ;
  background-size: var(--initialSize) var(--initialSize);
  background-repeat: no-repeat;
}

.firework::before {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
/*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
  transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
/*   animation: fireworkPseudo 2s infinite; */
}

.firework::after {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
/*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
  transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
/*   animation: fireworkPseudo 2s infinite; */
}

.firework:nth-child(2) {
  --x: 30vmin;
}

.firework:nth-child(2),
.firework:nth-child(2)::before,
.firework:nth-child(2)::after {
  --finalSize: 40vmin;
  left: 30%;
  top: 60%;
  animation-delay: -0.25s;
}

.firework:nth-child(3) {
  --x: -30vmin;
  --y: -50vmin;
}

.firework:nth-child(3),
.firework:nth-child(3)::before,
.firework:nth-child(3)::after {
  --finalSize: 35vmin;
  left: 70%;
  top: 60%;
  animation-delay: -0.4s;
}

.firework:nth-child(4),
.firework:nth-child(4)::before,
.firework:nth-child(4)::after {
  --finalSize: 35vmin;
  left: 90%;
  top: 60%;
  animation-delay: -0.3s;
}

.firework:nth-child(5),
.firework:nth-child(5)::before,
.firework:nth-child(5)::after {
  --finalSize: 35vmin;
  left: 10%;
  top: 60%;
  animation-delay: -0.5s;
}
