.bpf-cms-customer-form-private-individual-edit--update-advisor {
  display: flex;
  align-items: center;
  width: 100%;
}

.bpf-cms-customer-form-private-individual-edit--update-advisor--icon {
  font-size: 2.5em;
  padding-right: 10px;
}

.bpf-cms-customer-form-private-individual-edit--update-advisor--button {
  align-self: flex-end;
  margin-left: auto;
}
