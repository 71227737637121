.tab-bar-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.tab-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-icon {
  width: 30px;
  height: 30px;
  filter: brightness(0%) opacity(0.8);
}

.tab-label {
  margin-left: 8px;
  color: black;
  display: none;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.tab:hover .show-on-hover,
.always-show {
  display: inline;
  opacity: 1;
  visibility: visible;
}

.tab-disabled {
  cursor: default;
  opacity: 0.5;

  .tab-icon {
    filter: grayscale(100%);
  }
}

.tab-active {
  box-shadow: 0 4px 8px lightgray;
}
