// notification-card.scss

// Variables
$card-border-radius: 4px;
$card-margin: 10px 0;
$card-box-shadow: 1px 1px 15px 3px rgba(0, 0, 0, 0.05);
$unread-border-color: var(--default-blue);
$transition-duration: 0.2s;
$card-padding: 0.5rem;
$card-margin-right: 15px;

.notification-card {
  border-radius: $card-border-radius;
  margin: $card-margin;
  box-shadow: $card-box-shadow;
  transition: all $transition-duration ease;
  margin-right: $card-margin-right;
  
  &--unread {
    border-left: 4px solid $unread-border-color !important; // Added !important to override Bootstrap's card border
  }
  
  &__body {
    padding: $card-padding;
  }
  
  &__children-container {
    margin-top: 1rem;
    
    // Add subtle divider between children items if needed
    > div:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  
  // Add animation for collapsing/expanding content
  &__collapse {
    transition: max-height $transition-duration ease;
    overflow: hidden;
  }

  // make sure all popovers are inline-block
  & .infopopover-container {
    display: inline-block;
  }
}

