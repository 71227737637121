.bpf-cms-workguide-edit-contact-information--save-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background:rgba(255,255,255, 0.5);
  z-index: 2;
}
