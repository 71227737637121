.selectable-list-item {
  width: 100%;
}

.selectable {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.selectable-checkbox {
  padding: 10px;
  height: 100%;
}

.selectable-checkbox-align-center {
  align-self: center;
}

.selectable-checkbox-align-start {
  align-self: flex-start;
}

.selectable-checkbox-align-end {
  align-self: flex-end;
}

.selectable-checkbox i {
  font-size: 1.5em;
  color: #614692;
}

.selectable-description {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.selectable-description-item {
  padding-right: 30px;
  padding-top: 10px;
}
