.bpf-cms-identification-document-document-link--link {
  display: flex;
  align-items: center;
}

.bpf-cms-identification-document-document-link--link--icon {
  padding-left: 5px;
  font-size: 1.2em;
  cursor: pointer;
}
