.lead-board-lead-compact {
  padding-bottom: 1.2rem;
}

.lead-board-lead-compact .lead-board-lead-content {
  padding-top: 0.7rem;
}

.lead-board-lead-compact .lead-board-lead-footer {
  padding-top: 0.3rem;
}
