.activity-filter-menu-content {
  min-width: 350px;
  margin-top: 10px;
}

.activity-filter-menu-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.activity-filter-menu-toggle--filter-icon {
  cursor: pointer;
}

.activity-filter-menu-toggle--filter-reset-icon {
  color: red;
  cursor: pointer;
  padding-right: 3px;
}

.activity-filter-menu .dropdown-item:active {
  background-color: #fff;
}
