.react-file-upload--global-dropzone {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 9;
  justify-content: center;
  width: 100%;
  height: calc(100% - 5em); /* height - navigation height */
  background-color: rgba(255, 255, 255, 0.8);

}

.react-file-upload--global-dropzone--content {
  text-align: center;
  color: #757575;
}

.react-file-upload--global-dropzone--content--icon-container {
  font-size: 5em;
}

.react-file-upload--global-dropzone--content--icon-container .mdi::before {
  vertical-align: baseline;
  top: 0px;
}

.react-file-upload--global-dropzone--content--title-container {
  font-size: 1.2em;
}

/* Customer 360° Overview */

.customer-360-dropzone {
  left: 0;
  padding-left: 2em;
  padding-right: 2em;
  top: 5rem;
}
  
.customer-360-dropzone > .react-file-upload--dropzone {
  padding: 2.7em;
  height: inherit;
}

/* Dashboard */

.dashboard-dropzone {
  left: 0;
  padding-left: 2em;
  padding-right: 2em;
}

.dashboard-dropzone > .react-file-upload--dropzone {
  padding: 2.7em;
  height: inherit;
}
