.lead-outlook-button--icon {
  align-self: center;
  cursor: pointer;
}

.lead-outlook-button--icon--disabled {
  opacity: 0.5;
}

.hoverable-outlook-icon {
  transition: color 0.3s ease;
}

.hoverable-outlook-icon:hover {
  color: #ff0000;
}