.info-popover-consultant .popover {
  max-width: 600px !important;
}

.consultant-details-wrapper {
  padding: 10px;
}

.consultant-details-wrapper .consultant-popover-profile-image img {
  border-radius: 50%;
  height: 10em;
  width: 10em;
  object-fit: cover;
  object-position: 0% 10%;
}

.consultant-avatar {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.consultant-details-icon {
  color: var(--neutral-dark-grey) !important;
  font-size: 20px;
}

.consultant-details-label {
  color: var(--default-lila);
}

.consultant-details-label:hover {
  text-decoration: underline !important;
  color: var(--default-lila);
}

.consultant-popover-name-username {
  font-size: 14px;
}
