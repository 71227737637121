.lead-form-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  padding-top: 17rem;
  width: 99%;
  height: 92%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
}

.lead-form-overlay--children {
  padding: 2em;
  font-size: 1.1em;
  font-weight: 700;
}

.lead-form-overlay .alert-container {
  max-height: 30%;
  width: 50%;
}
