.link-item {
  background: none;
  border: none;
  display: flex;
  gap: 8px;
}

.link-item.disabled-link {
  color: grey !important;
  cursor:unset;
}

.link-item.disabled-link a {
  cursor:unset;
}


.link-item--icon {
  background: #6D53A7;
  border-radius: 100px;
  height: 18px;
  width: 18px;
  position: relative;
}

.link-item--icon.disabled-link {
  background: grey !important;
}

.link-item--icon-text {
  color: #FFFFFF;
  font-size: 9px;
  font-weight: 700;
  height: 10px;
  left: 0px;
  line-height: 10px;
  letter-spacing: 0.3px;
  position: absolute;
  right: 0px;
  text-align: center;
  text-transform: uppercase;
  top: calc(50% - 10px/2);
}

.link-item--text {
  color: #6D53A7;
  font-size: 14px;
  line-height: 18px;
}

.link-item--text.disabled-link {
  color: grey;
  text-decoration: none;
}

.link-item--text.disabled-link:hover {
  text-decoration: none;
}

