.workguide-mail-callback--align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
}

.workguide-mail-callback--alert .alert-title {
  color: black;
}