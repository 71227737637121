.bpf-cms-formalities--customer-checks--content-checks--check {
  display: flex;
  align-items: center;
}

.bpf-cms-formalities--customer-checks--content-checks--check--checkbox {
  min-width: 25%;
}

.bpf-cms-formalities--customer-checks--content-checks--check--create {
  cursor: pointer;
  padding-left: 5px;
}
