.select-all-button {
    cursor: pointer;
    margin: 0 0 10px 0;
    color: var(--default-lila);
    font-weight: bold;
    text-decoration: underline;
}

.connect-ebanking-to-accounts-container .alert-container {
    margin-bottom: 1em;
}