.notification-center-tab-container {
    height: 100%;

  .tab-content {
    padding: 15px;
  }
    .notification-center-tab {

      height: 7%;
      align-items: center;
      background-color: white;
      
  
      .notification-center-tab-item {
        .nav-link {
          text-decoration: none;
          border: none;
          padding: 0 2em;

  
          .notification-center-tab-item-underline {
            padding-bottom: 0.6em;
            font-weight: 550;
          }
  
          &:hover {
            border: none;
          }
  
          &.active {
            background-color: var(---notification-center-tab-container);
            color: var(--default-lila);
            .notification-center-tab-item-underline {
              border-bottom: solid 2px var(--default-lila);
              font-weight: 700;
            }
          }
        }
      }
    }
  
    .tab-content {
      height: 93%;
      padding-top: 15px;
      .tab-pane {
        &.active {
          height: 100%;
          .row {
            height: 100%;
          }
        }
      }
    }
  
  }