.lead-board-lead-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-left: 2.9rem;
  min-height: 4.7em;
}

.lead-board-lead-footer--left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: rgba(102, 102, 102, 0.5);
}

.lead-board-lead-footer--right {
  display: flex;
  align-items: center;
  color: rgba(102, 102, 102, 0.5);
}
