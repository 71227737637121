.provision-depot-opening--space--top {
  padding-top: 1em;
}

.provision-depot-opening--space--bottom {
  padding-bottom: 1em;
}

.provision-depot-opening--label--center {
  display: flex; 
  align-items: center;
}

.provision-depot-opening-qi-declaration-other-country-select {
  width: 100%;
}

.provision-depot-opening-qi-declaration-loader {
  display: flex;
  justify-content: flex-start;
}

.provision-depot-opening--space--top-large {
  padding-top: 2em;
}

.provision-depot-opening--execution-title-row {
  padding-top: 2em; 
  padding-bottom: 1em;
}

.provision-depot-opening--section-content {
  padding-left: 2em;
  padding-right: 2em;
}

.provision-depot-opening-securitiessolution-hint--row {
  padding-top: 0.5em;
}

.provision-depot-opening-securitiessolution-hint {
  color: var(--bs-danger);
}