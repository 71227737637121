.ui-note-banners {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1020;
}

.ui-note-banners--banner {
  width: 100%;
  min-height: 15%
}

.ui-note-banners--backdrop {
  height: 75%;
  background: #000000;
  opacity: 0.4;
}


