.activities-search-field {
  width: 100%;
  & * {
    border: none;
    background-color: #E8E8E8;
    color: #818181;
  }
}

.activities-search-field input {
  border-right: 0px;
  height: 46px;
}

.activities-search-field input:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: #E8E8E8;
  color: #818181;
}

.activities-search-field--icon {
  font-size: 1.5em;
  font-weight: bold;
}
