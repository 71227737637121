.customer-detail-navigation {
  position: fixed;
  z-index: 7;
  left: 0px;
  padding-top: 3rem;
  height: 100%;
  border: 1px solid #e5e5e5;
  color: #666666;
  background-color: #ffffff;
}

.customer-detail-navigation-expand-collapse {
  position: absolute;
  left: 2rem;
  padding-top: 40px;
  font-size: 1.6rem;
  cursor: pointer;
  bottom: 6rem;
}
