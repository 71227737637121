.contract-accounts-detail-item {
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
  padding-top: 10px;
}

.contract-accounts-detail-item:first-child {
  padding-top: 0px;
}

.contract-accounts-detail-item:last-child {
  border-bottom: 0px;
}

.contract-accounts-detail-item-row {
  padding-bottom: 5px;
}