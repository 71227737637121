.theme--relaxing-blue {
  --bs-primary: hsl(199, 100%, 25%);
  --ag-background-color: hsl(199, 100%, 95%) !important;

  .layout-header-navbar {
    background-color: hsl(199, 100%, 90%) !important;
  }

  .layout-header-navbar .dropdown-toggle {
    background-color: hsl(199, 100%, 90%) !important;
  }

  .layout-content {
    background-color: hsl(199, 100%, 95%) !important;
  }

  .card-container {
    background-color: hsl(199, 100%, 98%) !important;
  }

  .customer-detail-navigation {
    background-color: hsl(199, 100%, 90%) !important;
  }

  .ag-root-wrapper {
    background-color: hsl(199, 100%, 98%) !important;
  }

  .ag-row {
    background-color: hsl(199, 100%, 98%) !important;
  }

  .ag-header {
    background-color: hsl(199, 100%, 95%) !important;
  }
}
