.bpf-cms-self-declaration-aia-fatca-form--tax-domiclile-countries--add {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bpf-cms-self-declaration-aia-fatca-form--tax-domiclile-countries--titles {
  padding-bottom: 10px;
}

.bpf-cms-self-declaration-aia-fatca-form--tax-domiclile-countries--item {
  border: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.bpf-cms-self-declaration-aia-fatca-form--tax-domiclile-countries--item--form-elements {
  display: flex;
}

.bpf-cms-self-declaration-aia-fatca-form--tax-domiclile-countries--item--remove {
  margin-top: 6px;
  padding-left: 10px;
  font-size: 1.4em;
  cursor: pointer;
  text-align: right;
}

.bpf-cms-self-declaration-aia-fatca-form--tax-domiclile-countries--add .mdi-plus-circle {
  vertical-align: unset;
}
