.freemium-form-credit-card-general--card-variety .form-common-sub-section {
  margin-bottom: 0rem;
}


.freemium-form-credit-card-general--card-variety .form-common-sub-section--title {
  font-weight: normal;
}

.freemium-form-credit-card-general--card-variety .form-common-sub-section--content {
  margin-bottom: 0.5rem;
  padding-left: 2.8rem;
}
