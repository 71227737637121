.setadvisor-limit-calculation--monthly-expense--header {
  display: flex;
  align-items: center;
  border-top: 1px solid lightgray;
  padding-top: 2rem;
}

.setadvisor-limit-calculation--monthly-expense--header--toggle {
  display: flex;
  align-items: center;
  padding-right: 2rem;
}


.setadvisor-limit-calculation--monthly-expense--header--add {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2em;
  color: #666666;
}
