.sort-dropdown-item {
  display: flex;
  align-items: center;
}

.sort-dropdown-item .mdi-sort {
  visibility: hidden;
}

.sort-dropdown-item
.mdi::before {
  vertical-align: super;
}
