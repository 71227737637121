.consultant-insights-card-container--title {
  font-size: 22px;
  font-weight: 700;
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.deposit-transactions--sell-icon-col {
    width: fit-content !important;
}

.consultant-mutation--consultant--row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}