.lead-board-lead-content-status {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgba(102, 102, 102, 0.5);
  border-radius: 100px;
  font-size: 11px;
  font-weight: 500;
  line-height: 24px;
  color: #666666;
  width: fit-content;
}

.lead-board-lead-content-status--text {
  padding-left: 2rem;
  padding-right: 2rem;
}

.lead-board-lead-status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;  
  margin-top: 1rem;}

.lead-board-lead-status--left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: rgba(102, 102, 102, 0.5);
}

.lead-board-lead-status--right {
  display: flex;
  align-items: center;
  color: rgba(102, 102, 102, 0.5);
}
