.floating-stock-bubble-flex-vertically {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 20px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 1s;
  z-index: 1032;
}

.stock_bubble__action__button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-top: 15px;
  background-color: white;
  border: 1px solid var(--default-lila);
  box-shadow: 0px 5px 15px -6px #000000;
  cursor: pointer;
  color: var(--default-lila);
}

.stock_bubble__action__button__stock_name {
  font-size: 12px;
}

.stock-price-detail--popover {
  width: 20em;
}

.stock-price-detail--popover-body {
  width: 20em;
}

.stock-price-detail--popover-body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5em;
}

.stock-price-detail--popover-body-container-inner{
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-price-detail--popover-body-container-inner--performance {
  padding-right: 1em;
}

.stock-price-detail--popover-body-container-inner--price {
  font-size: 20px;
}

.stock-price-detail--popover-body-details-container {
  border-top: 1px solid #EBEBEB;
  padding-top: 0.4em;
}

.stock-popover {
  width: 17em;
}
