.workguide-fundinvest-fund--content {
  display: flex;
  align-items: center;
  width: 100%;
}

.workguide-fundinvest-fund--content .react-form-singleselect-selected {
  background-color: unset;
}

.workguide-fundinvest-fund--content .react-form-singleselect-selected--label {
  padding-left: 0px;
  color: black;
}
