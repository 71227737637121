.setadvisor-form-limit-calculation--monthly-income {
  padding-top: 2rem;
}

.setadvisor-form-limit-calculation--monthly-income--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 24px;
}

.setadvisor-form-limit-calculation--monthly-income--title .mdi {
  font-size: 1.2em;
  cursor: pointer;
  color: #666666;
}

.setadvisor-form-limit-calculation--monthly-income--children {
  padding-top: 1rem;
}
