.dev-tools {
  padding-right: 4em;
  padding-left: 4em;
}

.dev-tools-components {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
}

.dev-tools-components--title {
  padding: 1em 2em 1em 2em;
  border: 1px solid lightgray;
}

.dev-tools-components--title--active {
  background-color: var(--form-color-primary-light);
}

.dev-tools-component--title {
  padding-bottom: 2em;
}
