.setadvisor-header {
  background-color: white;
  padding: 20px;
  height: 6em;
}

.setadvisor-header-title {
  font-size: 1.3em;
  padding-bottom: 5px;
}
.setadvisor-header-subtitle {
  display: flex;
  font-size: 0.8em;
  font-weight: normal;
  opacity: 0.8;
  width: 100%;
}

.setadvisor-header-subtitle-element {
  padding-right: 6px;
}

.setadvisor-header-subtitle-young-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.setadvisor-header-subtitle-young-plus-icon {
  font-size: 0.8em;
  margin-right: 5px;
}

.setadvisor-header-last-update {
  position: absolute;
  right: 25px;
}
