.workguide-approval-step--item-heading {
  font-size: 1.1em;
  padding-bottom: 25px;
}

.workguide-approval-step--item-heading-title {
  font-weight: bold;
  padding-bottom: 5px;
}

.workguide-approval-step--item-heading-description {
  font-size: 0.9em;
  font-weight: normal;
  padding-bottom: 5px;
}
