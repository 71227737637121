.setadvisor-color-legend {
  display: flex;
  padding-left: 30px;
}

.setadvisor-color-legend-current {
  padding: 2px 10px 2px 10px;
  background-color: #e5e5ff;
}

.setadvisor-color-legend-recommended {
  padding: 2px 10px 2px 10px;
  background-color: #e5f2e5;
}
