.workguide-modal,
.activity-modal,
.lead-modal-edit {

  /*
  ----------
  Text input
  ----------
  */
  .react-form-text-input {
    height: 2.4rem;
    border: 1px solid var(--form-border-light) !important;
    border-bottom: 1px solid var(--form-border-dark) !important;
  }

  .react-form-text-input:focus {
    border-bottom: 1px solid var(--form-border-active) !important;
    box-shadow: unset;
  }

  /*
  Number input
  */
  .react-form-number-input {
    height: 2.4rem;
    border: 1px solid var(--form-border-light) !important;
    border-bottom: 1px solid var(--form-border-dark) !important;
  }

  .react-form-number-input:focus {
    box-shadow: unset;
  }

  /*
  Date Field
  */
  .react-form-datefield-input {
    height: 2.4rem;
    border: 1px solid var(--form-border-light) !important;
    border-bottom: 1px solid var(--form-border-dark) !important;
  }

  .react-form-datefield-icon {
    color: var(--form-color-primary);
  }

  .react-form-datefield--disabled .react-form-datefield-icon {
    color: var(--form-color-primary-lighter);
  }

  .react-form-datefield--disabled .input-group-text {
    border: 1px solid var(--form-border-light);
  }

  .react-form-datefield-input:focus {
    box-shadow: unset;
  }

  .react-datepicker__input-container .input-group-text {
    border-bottom: 1px solid var(--form-border-dark);
  }

  /*
  Validations
  */
  .validatable-invalid {

    .form-control,
    .input-group-text,
    .react-select-inner__control {
      border: 1px solid var(--form-border-light);
      border-bottom: 1px solid var(--form-border-erroneous) !important;
    }
  }

  .validatable-invalid:focus-within {

    .form-control,
    .input-group-text,
    .react-select-inner__control {
      border: 1px solid var(--form-border-light);
      border-bottom: 1px solid var(--form-border-active) !important;
    }
  }

  .react-form-validatable-messages {
    color: var(--form-border-erroneous);
  }

  /*
  ----------
  React Toggle
  ----------
  */
  /* Track */
  .react-toggle-track {
    background-color: var(--form-color-secondary-lighter);
    height: 12px;
    width: 40px;
    margin-top: 6px;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: var(--form-color-primary-lighter);
  }

  .react-toggle:hover .react-toggle-track {
    background-color: var(--form-color-secondary-light) !important;
  }

  .react-toggle--checked:hover .react-toggle-track {
    background-color: var(--form-color-primary-light) !important;
  }

  /* Thumb */
  .react-toggle-thumb {
    background-color: #ffffff;
    border: unset;
    left: 0px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: var(--form-color-primary);
    border-color: var(--form-color-primary);
    left: 18px;
  }

  .react-toggle--disabled {
    opacity: 0.35;
  }

  .react-toggle-track-check {
    display: none;
  }

  .react-toggle-track-x {
    display: none;
  }

  /*
  React Select
  */
  .Select-control {
    height: 2.4rem;
  }

  .validatable-invalid .react-select-inner__control input {
    border-bottom: unset !important;
  }

  .react-select-inner__control {
    border: 1px solid var(--form-border-light) !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--form-border-dark) !important;
  }

  .react-select-inner__control--menu-is-open {
    box-shadow: unset !important;
  }

  .react-select-inner__control--is-focused {
    box-shadow: unset !important;
    border: 1px solid var(--form-border-light) !important;
    border-bottom: 1px solid var(--form-border-active) !important;
  }

  .react-select-inner__menu {
    border-radius: 0 !important;
  }

  .react-form-singleselect-selected {
    background-color: var(--form-color-primary-light);
  }

  .react-form-multiselect-selected {
    background-color: var(--form-color-primary-light);
    color: var(--form-color-secondary);
  }

  .react-form-multiselect-selected--remove {
    color: var(--form-color-secondary);
    border-left: 0.5px solid var(--form-border-dark);
  }

  /*
  Radio Button
  */
  .react-form-radio-button--radiobox {
    color: var(--form-color-primary);
  }

  /*
  Checkbox
  */
  .mdi-checkbox-blank-outline {
    color: var(--form-color-primary);
  }

  .mdi-checkbox-marked {
    color: var(--form-color-primary);
  }

  /*
  Login Form
  */
  .react-login--gateway-login .react-form-text-input {
    border: 0px !important;
  }
}
