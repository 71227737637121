/*
.issue-board-column {
  position: relative;
  height: 100%;
  width: 100%;
}
*/

.issue-board-column--load-indicator {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  padding-top: 20rem;
}

.issue-board-column--load-indicator span > span {
  background-color: var(--bs-purple);
}
