.customer-modal-header-customer {
  .customer-modal-header-customer--info {
    font-size: 0.8em;
  }
}

.customer-modal-header-customer--with-title {
  .customer-modal-header-customer--name { 
    padding-top: 1.5rem;
    font-size: 14px;
  }

  .customer-modal-header-customer--info {
    font-size: 14px;
    font-weight: 400;
  }
}
