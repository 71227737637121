.customer-detail-content--overview--profile-completion-company {
  padding-bottom: 40px;
}

.customer-detail-content--overview--profile-completion-company--charts {
  display: flex;
  align-items: center;
}

.customer-detail-content--overview--profile-completion-company--chart-inner-label {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.customer-detail-content--overview--profile-completion-company--crm-chart .doughnut-chart-doughnut {
  height: 130px;
  width: 180px;
  margin-left: -20%;
}

.customer-detail-content--overview--profile-completion-company--regulatory-chart .doughnut-chart-doughnut {
  height: 130px;
  width: 180px;
  margin-left: -40%;
}

.customer-detail-content--overview--profile-completion-company--missing-data {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 14px;
}

.customer-detail-content--overview--profile-completion-company--missing-data .row {
  padding-bottom: 0.3rem;
}

.customer-detail-content--overview--profile-completion-company--missing-data--title {
  font-weight: 700;
  text-decoration: underline;
}
