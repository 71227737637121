.quick-filters-form .circle-picker {
  justify-content: center;
}

.quick-filters-form-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 2rem;
}
