.bpf-cms-identification-document-form-file-upload--title-hint {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.bpf-cms-identification-document-form-file-upload--title-hint--title {
  font-weight: bold;
  padding-right: 25px;
  min-width: 20%;
}

.bpf-cms-identification-document-form-file-upload--title-hint--hint {
  display: flex;
  align-items: center;
  color: #969696;
  font-size: 0.9em;
}

.bpf-cms-identification-document-form-file-upload--title-hint--hint--icon {
  padding-right: 10px;
  font-size: 1.5em;
}

.bpf-cms-identification-document-form-file-upload--title-hint--hint--icon .mdi-set {
  vertical-align: baseline;
}
