.action__button__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 15px;
  background-color: white;
  border: 0.5px solid var(--default-lila);
  box-shadow: 0px 5px 15px -6px #000000;
  cursor: pointer;
}

.action__button__circle .mdi {
  font-size: 1.5rem;
  color: var(--default-lila);
}

.action__button__circle.active .mdi {
  color: white;
}

.action__button__circle.disabled {
  background-color: #f2f2f2;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.action__button__circle.disabled .mdi {
  /* font-size: 1.5rem; */
  color: #d9d9d9;
}

.action__button__badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  right: -7px;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0.2em;
  border-radius: 50%;
  background-color: #ff8640;
  font-size: 12px;
  font-weight: bolder;
  color: white;
}

.action__button__circle.active {
  background-color: var(--default-lila);
}

.action__button__circle.active i {
  color: #fff;
}

.side-bar-container {
  transition: 1s;
  height: 500px;
  background-color: white;
  position: absolute;
  top: 50px;
  right: 60px;
  overflow: hidden;
  border-radius: 4px;
}

.side-bar-container--open-fullscreen {
  width: 85vw !important;
  height: 80vh;
  top: 0;
}

.side-bar-container--open {
  width: 800px;
  height: 80vh;
  top: 0;
}


.side-bar-content-body {
  height: 93%;
  background-color: var(--card-secondary-background-color);
}
.side-bar-set-fullscreen {
  position: absolute;
  right: 0;
  width: 25px;
  height: 25px;
  border-radius: 25%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  cursor: pointer;
}
.side-bar-set-fullscreen .mdi {
  font-size: 30px;
  color: var(--neutral-dark-grey);
}

.side-bar-content-header {
  display: flex;
  align-items: center;
  padding: 0 3em;
  height: 7%;
}

.side-bar-content-header .title {
  padding: 0;
  font-size: 15px;
  color: var(--neutral-dark-grey);
  /* font-family: 'Livvic'; */
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
