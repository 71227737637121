.send-by-post-tab-content {
  background-color: var(--card-secondary-background-color);
  padding: 10px;
}


.send-by-post-tab {  
  border: none;

  .nav-link{
    text-decoration: none;
    margin-bottom: 0;
  }

  .nav-link.active{
    color: #7252ac;
    background-color: #fff;
    border: none;
    text-decoration: none;
  }
}

.send-by-post--title {
  padding-top: 10px;
  padding-bottom: 20px;
}
