.workguide-form-component-accept-decline--option {
  display: flex;
}

.workguide-form-component-accept-decline--option-active {
  background-color: #e6f9ff;
}

.workguide-form-component-accept-decline--invalid {
  border: 0.5px solid red;
}
