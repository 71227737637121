.lead-modal-win-reason--header {
  display: flex;
  align-items: center;
}

.lead-modal-win-reason--header
.mdi::before {
  vertical-align: baseline;
}

.lead-modal-win-reason--header--icon {
  font-size: 1.3rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.lead-modal-win-reason .lead-board-lead-header-right--actions {
  display: none;
}

.lead-modal-win-reason--form-element {
  padding-top: 2rem;
}
