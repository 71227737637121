.savings__container{
  background-color: #6d53a702 !important;

  .savings-header-tr{
    
    th{
      font-size: 11px;
      color: rgb(128, 128, 128);
      font-weight: normal;
    }
  }

  tr th:first-child {
    padding-left: 10px;
  }

  tr th {
    padding-top: 10px;

  }

  tr td:first-child {
    padding-left: 10px;
  }

  tr, td, th {
    height: 30px;
    background-color: transparent !important;
  }
}

.savings-credit-card-progress-container-box {
  padding-top: 5px;
  background-color: #6d53a702 !important;
}

.savings__credit-card__progress--container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.savings__credit-card__progress {
  width: 70%;
  // margin: 0 auto;
}

.savings__credit-card__progress--value {
  padding-left: 5px;
  font-size: 11px;
}

.progress-complete-background {
  background-color: var(--default-lila) !important;
}

.progress-background {
  background-color: var(--default-blue) !important;
}

.progress-loading-background {
  background-color: var(--neutral-dark-grey) !important;
}

.savings-text-info-box {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  background-color: #6d53a702 !important;
  font-size: 12px;
}