.workguide-form--buttons {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
}

.workguide-form--buttons button {
  margin-right: 10px;
}

.workguide-form--post-actions {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
