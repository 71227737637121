.workguide-form-fieldgroup--element .workguide-form-component {
  padding-bottom: 0px;
}

.workguide-form-fieldgroup--element .workguide-form-component .mb-3 {
  margin-bottom: 0px !important;
}

.workguide-form-fieldgroup--element {
  display: flex;
  align-items: center;
}
