

.consultation-form-select--type {
  cursor: pointer;
  padding: 10px;
  width: 150px;
  text-align: center;
  border-radius: 5px;
}

.consultation-form-select--type.active {
  background-color: #6D53A720;
  padding: 10px;
  width: 150px;
  text-align: center;
  border-radius: 5px;
}

.consultation-form-select--container  {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.consultation-form-tab {
  border: none;
  height: 4em;
  align-items: center;
  background-color: white;
  padding-left: 3em;
}

.consultation-form-tab-item .nav-link{
  text-decoration: none;
  border: none;
}

.consultation-form-tab-item .nav-link .consultation-form-tab-item-underline{
  padding-bottom: 0.6em;
  font-weight: 550;
}

.consultation-form-tab-item .nav-link:hover{
  border: none;
}

.consultation-form-tab-item .nav-link.active{
  /* background-color: var(---communication-tab-container); */
  color: var(--default-lila);
}

.consultation-form-tab-item .nav-link.active .consultation-form-tab-item-underline{
  border-bottom: solid 2px var(--default-lila);
  font-weight: 700;
}

.consultation-form-tab-content {
  height: 100%;
}

.consultation-form-tab-content .tab-pane.active {
  height: 100%;
}

.consultation-form-tab-content .tab-pane.active .div {
  height: 100%;
}

.consultation-form-edossier-archiv {
  background-color: #FFF;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  padding: 1em;
}

