.lead-board-filter-menu .filter-dropdown--menu {
  min-width: 20vw;
}

.lead-board-filter-menu--item--typeOrder .filter-dropdown-item--check {
  color: rgb(0, 165, 213);
}

.lead-board-filter-menu--item--typePotential .filter-dropdown-item--check {
  color: rgb(154, 170, 0);
}

.lead-board-filter-menu--item--resolvedDate--input {
  padding-left: 2.3rem;
  padding-right: 1.4rem;
  padding-bottom: 1rem;
}

.lead-board-filter-menu--item--resolvedDate--input .mdi::before {
  top: unset;
  left: unset;
}

.lead-board-filter-menu .react-form-multiselect-selected--remove .mdi::before {
  top: 2px;
  left: unset;
}
