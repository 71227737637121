.bpf-cms-profile-completion-several-private-individual--partner {
  padding-top: 20px;
}

.bpf-cms-profile-completion-several-private-individual--partner--designation {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 15px;
}
