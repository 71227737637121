.error-summary--list-item {
  display: flex;
  align-items: center;
  padding-top: 0.4em;
  padding-bottom: 0.3em;
}

.error-summary--list-item .error-summary--list-item--badge {
  margin-right: 0.7em;
  margin-top: -0.4em;
}
