.lead-board-lead-footer--left--dates {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.lead-board-lead-footer--left--dates--arrow {
  font-size: 1.2rem;
  color: rgba(102, 102, 102, 0.5);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.lead-board-lead-footer--left--dates--date {
  font-weight: 600;
}

.lead-board-lead-footer--left--dates--date-expired {
  color: red;
}


.lead-board-lead-footer--left--dates--linked-event {
  font-size: 1.1rem;
  margin-left: 10px;
  color: var(--neutral-dark-grey);
  cursor: pointer;
}