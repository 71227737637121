.counter-input {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  background-color: #49A3D00D;

  &.show-border {
    border: 1px solid #afafaf;
  }

  .counter-input--display {
    background-color: #49A3D00D;
    display: block;
    margin: 0;
    padding: 3px 21px;
  }
  .counter-input--button {
    background-color: transparent;
    border: none;
    flex-grow: 1;
    font-size: 20px;
    font-weight: 800;
  }
}
