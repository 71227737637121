.calculate-many-capital-tax-modal {
  max-width: 1500px;
}

.customer-many-capital-tax-calculate-modal--col {
  background-color: white;
  padding: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.customer-many-capital-tax-calculate-modal--body {
  background-color: #66666615;
  padding: 2em;
}

.customer-many-capital-tax-calculate-modal--form {
  width: 50%;
}

.customer-many-capital-tax-calculate-modal--results {
  width: 45%;
}