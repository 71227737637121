.doughnut-chart-doughnut {
  position: relative;
}

.doughnut-chart-inner-container {
  position: absolute;
  width: 30%;
  height: 50%;
  z-index: 2;
  left: 35%;
  top: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.doughnut-chart-label-bottom {
  padding-top: 5px;
  text-align: center;
}
