.workguide-post-actions-summary {
  background-color: #ffffff;
  opacity: 0.7;
  width: 99.5%;
  height: 100%;
  text-align: center;
}

.workguide-post-actions-summary--title {
  font-size: 1.2em;
  font-weight: 700;
  padding-bottom: 2rem;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.workguide-post-actions-summary--actions {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
}

.workguide-post-actions-summary--action {
  display: flex;
  width:80%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.4em;
}

.workguide-post-actions-summary--progress {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  width: 100%;
}
