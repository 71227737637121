.lead-board-lead-content-note {
  padding-top: 1rem;
  font-size: 0.9em;
  font-weight: 300;
  line-height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 4rem;
  min-height: 4.1em;
}
