.layout-header-user-profile--image img {
  width: 40px;
}

.layout-header-user-profile--user-switch-images > .layout-header-user-profile--image:first-child::after{
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid rgba(0, 0, 0, 0);
  border-bottom: 0;
  border-left: .3em solid rgba(0, 0, 0, 0);
}

.layout-header-user-profile--image:not(.userswitch-profile-image)::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid rgba(0, 0, 0, 0);
  border-bottom: 0;
  border-left: .3em solid rgba(0, 0, 0, 0);
}

.layout-header-user-profile--image {
  display: flex;
  align-items: center;
}


.layout-header-user-profile--image--container {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-header-user-profile--image--disabled {
  opacity: 0.5;
}

.user-profile--news-badge{
  background-color: orange;
  width: 22px;
  height: 22px;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  right: 11px;
  top: 0;
  content: none;
}