.lead-form-revision-field {
  display: none;
  padding-right: 0.2em;
}

/*
.lead-form-revision-field .form-label {
  display: none;
}
  */
.lead-form-revision-field div[class^='lead-form-field'] .form-label {
  display: none;
}

.lead-form-revision-field--added {
  display: block;
  color: var(--bs-success);
}

.lead-form--field-col:has(.lead-form-revision-field--added) {
  border: 1px solid var(--bs-success);
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.lead-form-revision-field--changed {
  display: block;
  color: var(--bs-info);
}

.lead-form--field-col:has(.lead-form-revision-field--changed) {
  border: 1px solid var(--bs-info);
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.lead-form-revision-field--removed {
  display: block;
  color: var(--bs-danger);
}

.lead-form--field-col:has(.lead-form-revision-field--removed) {
  border: 1px solid var(--bs-danger);
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.lead-form-revision-field--popover .popover {
  min-width: 40%;
  max-width: unset;
}


