$backdrop-color: #00000080;

#react-confirm-alert {
  .confirm-text {
    font-size: 1em;
    font-weight: 600;
    margin-left: 6%;
    margin-right: 6%;
    margin-top: 5%;
    padding-bottom: 6%;
    border-radius: 0px;
    text-align: center;
  }

  .mdi::before {
    vertical-align: middle;
    top: 0px;
  }

  .confirm-title {
    padding-left: 3%;
    margin-top: 0.5%;
  }

  .confirm-button {
    border-radius: 4px;
    padding: 0.2em;
    min-width: 30%;
    margin-right: 10px;
  }

  .decline-button {
    border-radius: 4px;
    padding: 0.2em;
    min-width: 30%;
  }

  .react-confirm-alert-overlay {
    background: $backdrop-color;
  }

  .react-confirm-alert {
    background-color: white;
  }
}