.info-popover-account--financing-content {
  min-height: 5em;
}

.info-popover-account--financing-content--body--divider {
  width: 100%;
  padding-top: 1.4em;
  border-bottom: 1px solid lightgray;
}

.info-popover-account--financing-content--body--section-title {
  font-size: 1.1em;
  font-weight: 800;
  padding-bottom: 1em;
}
