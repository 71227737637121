.create-document-preview__center {
    display: flex;
    justify-content: center;
  }
  
  .create-document-preview__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25vh;
  }
  
  .create-document-preview__loading__text {
    animation: create-document-preview__blinkingText 4s ease-in-out infinite;
    color: var(--default-blue);
  }
  
  @keyframes create-document-preview__blinkingText {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  