.layout-header-user-profile--dropdown--language-switch {
  justify-content: space-between;
  padding-bottom: 5px;
}

.layout-header-user-profile--dropdown--container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: 20px;
}

.layout-header-user-profile--dropdown--language-switch--language {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.layout-header-user-profile--dropdown--language-switch--language.active {
  width: 100%;
  background-color: #6D53A720;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

