.workguide-modal {
  margin-top: 5rem;
}

.workguide-modal .modal-dialog {
  margin-bottom: 10rem;
}

.workguide-modal .modal-header {
  display: flex;
}

.workguide-modal .modal-header .btn-close {
  align-self: flex-start;
  padding-top: 1rem;
}

.workguide-modal .modal-title .copy-to-clipboard {
  font-size: 1.2em;
}

.workguide-modal .modal-title .copy-to-clipboard .mdi::before {
  vertical-align: unset;
}

.workguide-modal--close-icon {
  cursor: pointer;
  color: lightgray;
  font-size: 1.6em;
}

.workguide-modal-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
}
.workguide-modal .modal-dialog{
  max-width: 80%;
}

.workguide-modal-post-actions-overlay {
  position: absolute;
  z-index: 999;
  background-color: #ffffff;
  opacity: 0.7;
}
