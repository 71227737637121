.reload-modal-progress {
  border: 2px solid #1ba3e5;
}

.reload-modal-progress--bar {
  height: 40px;
  background-color: #1ba3e5;
}

.reload-modal-progress--bar-inner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
}

.reload-modal-progress--bar-inner-label {
  color: #000000;
  font-weight: bold;
}
