.bpfdocument-quick-filters-actions {
  display: flex;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  padding-bottom: 2%;
}

.bpfdocument-quick-filters-actions--button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5%;
  padding-right: 0.5%;
  padding-bottom: 1%;
  padding-left: 0.5%;
  overflow: hidden;
}

.bpfdocument-quick-filters-actions--button-container .btn .mdi::before {
  top: 3px;
}

.bpfdocument-quick-filters-actions--button {
  display: flex;
  align-items: center;
}

.bpfdocument-quick-filters-actions--button:focus {
  box-shadow: unset;
}

