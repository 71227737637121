.bpf-cms-customer-form-several-private-individuals--contact-info--label {
  display: flex;
  align-items: center;
}

.bpf-cms-customer-form-several-private-individuals--contact-info--label--icon {
  font-size: 1.7em;
  padding-right: 15px;
}

.bpf-cms-customer-form-several-private-individuals--contact-info--label--icon .mdi:before {
  vertical-align: unset;
}
