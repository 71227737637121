.card-container--header--secondary-actions--icon {
  font-size: 1.5rem;
}

.card-container--header--secondary-actions {
  margin-left: 20px;
}
.card-container--header--secondary-actions .dropdown-item {
  color:var(--neutral-dark-grey);
}
.card-container--header--secondary-actions--item .dropdown-item {
  color: var(--neutral-dark-grey);
}

.card-container--header--secondary-actions--item {
  display: flex;
  align-items: center;
}

.card-container--header--secondary-actions--item--icon {
  font-size: 1.2em;
}

.card-container--header--secondary-actions--item
.mdi::before {
  vertical-align: text-top;
  top: 2.5px;
}
