.setadvisor-pricedetail-footer-total {
  display: flex;
  align-items: center;
}

.setadvisor-pricedetail-footer-total-label {
  font-weight: 700;
  padding-right: 5px;
}

.setadvisor-pricedetail-footer-total-badge {
  padding: 5px;
}

.setadvisor-pricedetail-row-feereduction-label {
  padding-right: 5px;
}

.setadvisor-pricedetail-row-feereduction-badge {
  padding: 5px;
}

.setadvisor-pricedetail-cheapest-bundle-col {
  background-color: red;
}

.setadvisor-pricedetail-countcheckbox {
  width: 90%;
}

.setadvisor-pricedetail-countinput {
  width: 60%;
}

.setadvisor-pricedetail-countinput-reset {
  color: red;
  cursor: pointer;
  font-size: 1.2em;
}
