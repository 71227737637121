.bpf-cms-formalities {
  position: relative;
  width: 100%;
}

.bpf-cms-formalities--error-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  color: #911b32;
}

.bpf-cms-formalities--header {
  display: flex;
  width: 100%;
  height: 100%;
}

.bpf-cms-formalities--header--text {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

.bpf-cms-formalities--header--border {
  border: 1px solid black;
  border-bottom: 0px;
  width: 100%;
  margin-top: 9px;
}

.bpf-cms-formalities--header--border-left {
  border-right: 0px;
}

.bpf-cms-formalities--header--border-right {
  border-left: 0px;
}

.bpf-cms-formalities--replay{
  text-align: end;
  border: 1px solid black;
  border-top: 0;
  border-bottom: 0;
}

.bpf-cms-formalities--replay span{
  padding-right: 6px;
  color: var(--neutral-dark-grey);
  cursor: pointer;
}


.bpf-cms-formalities--content {
  border: 1px solid black;
  border-top: 0px;
  padding: 20px;
}

.bpf-cms-formalities--content-checks {
  padding-bottom: 20px;
}
