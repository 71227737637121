.lead-modal-edit {
  width: 90vw;
  max-width: 90vw;
}

.lead-modal-edit .modal-title {
  width: 100%;
}

.lead-modal-edit--body {
  display: flex;
  width: 100%;
}

.lead-modal-edit--tabs {
  padding-top: 2em;
}
