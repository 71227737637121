.lead-modal-update-multi .modal-body {
  padding-bottom: 4rem;
}

.lead-modal-update-multi--leads {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.lead-modal-update-multi--leads .lead-board-lead-header-right--actions {
  display: none;
}
