.lead-board-lead {
  background-color: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin-bottom: 10px;
  padding-bottom: 1rem;
  padding-right: 2rem;
}

.lead-board-lead--selected {
  background-color: #cde8f1;
}
