
.debit-master-card--flex{
    align-items: center;
    display: flex;
    gap: 19px;
}

.debit-master-card--toggle {
    margin-top: 10px;
}

.debit-master-card-form--profile--completion--container {
    width: 70%;
}

.debit-master-card-form--container {
    border: 1px dotted gray;
}

.debit-master-card-form--toggle--delivery {
    align-items: center;
    display: flex;
    gap: 19px;
}

.setadvisor-form-common-sub-section--content .form-label {
    flex-basis: 200px;
}