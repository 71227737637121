.customer-financing-detail-item-title {
  padding-bottom: 10px;
  font-weight: bold;
}

.customer-financing-detail-item-value {
  padding-bottom: 10px;
}

.customer-financing-detail--row .address-map--map-container {
  height: 100%;
}

.customer-financing-detail-land-register-title {
  padding-top: 1em;
  font-size: 16px;
}