.error-overview-scopes--list-group-item--title-row .mdi::before {
   top: 0.07em;
   vertical-align: baseline;
}

.error-overview-scopes--list-group-item--title-row {
  cursor: pointer;
}

.error-overview-scopes--list-group-item--expanded-row {
  padding-top: 0.5em;
}

.error-overview-scopes--list-group-item .error-error-list--list-group-item {
  padding-left: 0;
}

.error-overview-scopes--list-group-item .error-error-detail {
  padding: 2em;
  background-color: var(--bs-gray-100);
}
