.floating-sidebar-communication {
  font-size: large;
  font-weight: bold;
  margin-top: 3%;
  margin-left: 3%;
}

.floating-sidebar-title {
  font-weight: bold;
  padding: 10px 5px 5px 18px;
}

.floating-sidebar-buttons {
  margin-left: auto;
  padding: 10px 18px 5px 5px;
}

.floating-sidebar-button-position {
  display: flex;
  margin: 0;
  position: absolute;
}

.floating-sidebar-flex-vertically {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 20px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 1s;
  z-index: 1032;
}

.custom-image.setadvisor-button-business {
  background: url('../../../../images/sprite/setadvisor-business.png');
  background-repeat: no-repeat;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.floating-sidebar-background-backdrop {
  position: absolute;
  background-color: var(--backdrop-color);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1031;
  transition: all 1s;
  cursor: pointer;
}

#consultation-floating-button + .side-bar-container--open{
  width: 1000px;
}
