.setadvisor-form-common-comments--content {
  box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 15px 3px;
  padding: 1.5rem;
  width: 100%;
}

.setadvisor-form-common-comments--comment {
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 15px 3px;
  padding: 1rem 2rem;
  width: 100%;
}

.setadvisor-form-common-comments--comment--meta {
  padding-bottom: 1rem;
  font-weight: 600;
}

.setadvisor-form-common-comments--comment--value {
  display: flex;
  flex-grow: 1;
  white-space: pre;
  background-color: #f5f5f5;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  border-radius: 2px;
}

.setadvisor-form-common-comments--comment--remove {
  display: flex;
  justify-content: flex-end;
  font-size: 1.2em;
  font-weight: 700;
  cursor: pointer;
  padding-right: 0;
  width: 100%;
}
