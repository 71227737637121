.card-container {
  --contentBasePadding: 1.5rem;
  --contentNoTitlePadding: 0rem;
  border: var(--cardBorder);
  box-shadow: var(--cardShadow);
  background-color: #ffffff;
  padding: 2rem;
  margin-bottom: 2.5rem;
}

.card-container--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  padding-right: 0;
}
.card-container--header--category {
  font-size: 1.2em;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.card-container--header--category i {
  margin-left: 10px;
}

.card-container--header--actions {
  display: flex;
  align-items: center;
}

.card-container--title {
  font-size: 22px;
  font-weight: 700;
  padding-top: 1.5rem;
}

.card-container--sub-title {
  font-size: 10px;
}

.card-container--content {
  padding-top: var(--contentBasePadding);
}

.card-container--no-title .card-container--content {
  --contentNoTitlePadding: 0.5rem;
  padding-top: calc(var(--contentBasePadding) + 0.5rem);
}

.card-container--no-sub-title .card-container--content {
  padding-top: calc(var(--contentBasePadding) + var(--contentNoTitlePadding) + 0.5rem);
}

.card-container .card-container--icon .mdi:before, .mdi-set{
  vertical-align: baseline;
}
