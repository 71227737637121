.customer-busines-relation-card {
  min-height: 65px;
  min-width: 190px; 
  max-width: 250px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 15px 3px rgba(0,0,0,0.05);
  flex: 1 1 calc(33% - 12px);
  margin: 7px;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;

  .title-container {
    display: flex;
    align-items: center;
  }

  .title {
    margin-bottom: 0;
    text-align: left;
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
}
