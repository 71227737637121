
    .send-documents-physically-form--info-box{
      background-color: white;
      padding: 20px;
      font-size: 12px;
  
      .send-documents-physically-form--info-title { 
        font-size: 12px;
        padding-bottom: 15px;
      }
  
  
      .send-documents-physically-form--info-description {
        font-size: 12px;
        padding-bottom: 10px;
      }
  
      .send-documents-physically-form--info-important-notes {
        color: #666666;
        ul {
          padding-left: 13px;
          padding-top: 10px;
        }
      }
    }