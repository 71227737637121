.spending__card-container {
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-bottom: 20px;
    margin-right: 30px;
  }
  
  .spending__card-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .sppending__badge {
    width: 35px; 
    height: 15px; 
    border-radius: 5px;
    display: inline-block;
  }

  .spending__striped-badge {
    width: 35px; 
    height: 15px; 
    background-color: #f2f2f2;
    border-radius: 5px; 
    background-image: linear-gradient(
      45deg, 
      rgba(200, 200, 200, 0.5) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(200, 200, 200, 0.5) 50%, 
      rgba(200, 200, 200, 0.5) 75%, 
      transparent 75%, 
      transparent
    );
    background-size: 10px 10px; /* Size of stripes */
    display: inline-block;
  }
  
  .spending__card-subtitle {
    color: #6c757d;
    margin-bottom: 1rem;
  }
  
  .spending__card-text {
    line-height: 1.5;
    color: #6c757d;
    border-top: 1px solid #f8f9fa;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .spending__card-text-key {
    font-weight: bold;
  }
  
  .spending__card-text-value {
    float: right;
  }
  
  .spending__progress {
    background-color: #e9ecef;
    border-radius: 5px;
    height: 10px;
    margin-bottom: 1rem;
  }

  .spending__progress-gray {
    color: #b6b6b8;
    background-color: #b6b6b8;
  }

  

  .spending__progress-gray-stripped {
    background-color: #f2f2f2;
    background-image: linear-gradient(45deg, rgba(200, 200, 200, 0.5) 25%, transparent 25%, transparent 50%, rgba(200, 200, 200, 0.5) 50%, rgba(200, 200, 200, 0.5) 75%, transparent 75%, transparent);
    background-size: 20px 20px;
}



  .spending__progress-black {
    color: var(--neutral-black);
    background-color: var(--neutral-black);
  }
  