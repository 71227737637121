.l * {
  pointer-events: none;
}

.ebanking-contract-modal--header {
  position: relative;
}
.ebanking-contract-modal--header-close {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  padding: 0;
  position: absolute;
  right: 27px;
  top: 26px;
  width: auto;
}
.ebanking-contract-modal--header-close::after {
  content: '✕';
}

.ebanking-contract-modal .popover-header .modal-main-title {
  font-size: 18px;
  font-weight: 700;
}

.ebanking-contract-modal .popover-header .modal-account-type {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.ebanking-contract-modal .popover-header .modal-account-number {
  font-size: 14px;
  line-height: 24px;
}

.ebanking-contract-modal .col {
  padding: 0;
}
.ebanking-contract-modal .row {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.ebanking-contract-modal--body .row {
  padding: 5px;
}

.ebanking-contract-modal--body .row > * {
  padding-left: 0;
  padding-right: 0;
}

.ebanking-contract-modal--body .label-icon{
  font-size: 17px;
  padding-right: 5px;
  display: inline-flex;
}

.ebanking-contract-information-container .icon-col {
  align-items: center;
  display: flex;
  gap: 8px;
}

.ebanking-contract-information-container .text-cutted {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ebanking-contract-information-container p {
  margin-bottom: 0;
}

.ebanking-contract-information-container .link-item--text {
  font-size: 12.25px;
}

.ebanking-contract-information--account-title-col {
  padding-bottom: 5px;
}

.ebanking-contract-information--account-col{
  padding: 5px;
}
