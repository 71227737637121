.release-notes-card .card-container--header--category{
  color: var(--neutral-dark-grey);
}

.release-notes-item--date-row  {
  padding-bottom: 10px;
  padding-left: 2em;
  padding-right: 2em;
}

.release-notes-item--date-col {}

.release-notes-item--date {
  font-size: 12px;
  color: var(--neutral-dark-grey);
  padding-bottom: 10px;
}

.release-notes-item--title-row {
  padding-left: 2em;
  padding-right: 2em;
}

.release-notes-item--title-col {}

.release-notes-item--title-date-col {
  display: flex;
  align-items: end;
  font-weight: bold;
  font-size: 13px;
}

.release-notes-item--divider {
  margin-top: 0.1em;
  border-top: 1px solid #dee2e6;
  margin-bottom: 0.1em;
}

.release-notes-item--content-row {
  padding-top: 1em;
}

.release-notes-item--image-row {
  padding-top: 1em;
}

.release-notes-item--image-card {
  padding-top: 0;
}

.release-notes-item--image {
  display: block;
  max-width: 100%;
}