.lead-form-field--due-date--download-button {
  font-size: 1.6em;
  color: var(--form-color-primary);
  padding-left: 0.2em;
  padding-top: 0.25em;
}

.lead-form-field--due-date--download-button--disabled {
  opacity: 0.5;
}
