.layout-header-navbar-links--link {
  color: #666666;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: 500;
}

.layout-header-navbar-links--link:hover {
  color: var(--default-lila);
  text-decoration: none;
}

.layout-header-navbar-links--link.active {
  background: rgba(0, 0, 0, 0.04);
  border-bottom: 2px solid var(--default-lila)
}

.layout-header-navbar-links--link.nav-link:hover, .nav-link:focus{
  color: var(--default-lila);
}