.theme--show-moar {
  --bs-primary: hsl(199, 100%, 25%);
  --ag-background-color: hsl(199, 100%, 95%) !important;

  .layout-header-navbar {
    background-color: hsl(225, 100%, 75%) !important;
  }

  .layout-header-navbar .dropdown-toggle {
    background-color: hsl(225, 100%, 75%) !important;
  }

  .layout-content {
    background-color: hsl(225, 100%, 85%) !important;
  }

  .card-container {
    background-image: url('showmore.gif');
    background-size: cover;
  }

  .card-container * {
    color: #b3c6ff !important;
  }

  .customer-detail-navigation {
    background-color: hsl(225, 100%, 75%) !important;
  }

  .ag-root-wrapper {
    background-color: hsl(225, 100%, 98%) !important;
  }

  .ag-row {
    background-color: hsl(225, 100%, 98%) !important;
  }

  .ag-header {
    background-color: hsl(225, 100%, 95%) !important;
  }
}
