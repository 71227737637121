.fidentity-verification-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fidentity-verification-progress--border {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px dashed #757575;
  min-height: 200px;
  width: 100%;
}

.fidentity-verification-progress--alert {
  align-items: flex-start;
}