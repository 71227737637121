.v-grid {
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
  
  .v-grid__item {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    display: flex;
    flex: 1;
    flex-direction: column;
    grid-column: auto / span 2;
    grid-row: auto / span 2;
  }
  .v-grid__item.--span-v-1 {
    grid-row: auto / span 4;
  }
  .v-grid__item.--span-h-3 {
    grid-column: auto / span 5;
  }
  
  .v-grid__item-header {
    display: flex;
    justify-content: space-between;
    padding: 4px 6px;
  }
  .v-grid__item-header span {
    font-size: 22px;
  }
  
  .v-grid__item-body {
    background: none;
    border: none;
    box-shadow: none !important;
    flex: 1;
    font-size: 12px;
    margin-top: 4px;
    outline: none;
    padding: 4px;
    resize: none;
  }

