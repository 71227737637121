.bpf-process-comment {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bpf-process-comment--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: bolder;
  padding-bottom: 0.5rem;
}

.bpf-process-comment--comment {
  padding-bottom: 1rem;
}

.bpf-process-comment--date {
  color: #aaa;
  font-size: 0.8em;
  text-align: right;
}

.bpf-process-comment--content {
  background-color: #f1f1f1;
  border: 2px solid #dedede;
  border-radius: 5px;
  padding: 1rem 1rem 0.5rem 1rem;
}
