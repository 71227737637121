.lead-board-lead-header {
  display: flex;
  border-radius: 4px;
}

.lead-board-lead-header-left {
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
}

.lead-board-lead-header-left--corner {
  position: absolute;
  width: 2rem;
  height: 2rem;
  opacity: 0.3;
  border-radius: 4px;
}

.lead-board-lead--potential .lead-board-lead-header-left--corner {
  background: linear-gradient(135deg, rgba(158, 169, 52,1) 0%, rgba(158, 169, 52,1) 50%, rgba(255,255,255,0) 25%);
}

.lead-board-lead--task .lead-board-lead-header-left--corner {
  background: linear-gradient(135deg, rgba(109, 83, 167,1) 0%, rgba(109, 83, 167,1) 50%, rgba(255,255,255,0) 25%);
}

.lead-board-lead--order .lead-board-lead-header-left--corner {
  background: linear-gradient(135deg, rgba(73, 163, 208,1) 0%, rgba(73, 163, 208,1) 50%, rgba(255,255,255,0) 25%);
}

.lead-board-lead-header-right {
  display: flex;
  justify-content: space-between;
  color: #666666;
  width: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
}

.lead-board-lead-header-right--designation {
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
}

.lead-board-lead-header-right--actions {
  font-size: 1.2em;
  cursor: pointer;
}

.lead-board-lead-header-right--secondary-actions .card-container--header--secondary-actions--icon {
  font-size: 1.1em;
}

.lead-board-lead-header-right--secondary-actions .card-container--header--secondary-actions--item {
  padding-bottom: 0.5rem;
}
