.lead-board-consultant {
  padding: 40px 20px;
  background-color: #FBFCFD;
  max-height: calc(100vh - 300px);
  position: relative;
}


.lead-board-consultant--board {
  margin-top: 2rem;
}

.lead-board-consultant--row-filters {
  display: flex;
  align-items: center;
}

.lead-board-consultant--row-filters--links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lead-board-consultant--board {
  position: relative;
}

.lead-board-consultant--board--row-columns {
  position: relative;
  height: 100%;
}

.lead-board-consultant--row-date-range {
  padding-right: 5.5rem;
}

.lead-board-consultant--row-refresh-warning {
  padding-left: 1rem;
  padding-right: 6rem;
  padding-top: 2rem;
}


.lead-board-consultant .card-container {
position: relative;
height: 100%;
}
.lead-board-consultant .card-container--content {
  max-height: 100%;
}