.layout-header-powersearch--menu-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: var(--bs-primary);
  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.layout-header-powersearch--menu-footer--link {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
}
