.environment-change-modal {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  margin-top: 0px;
}

.environment-change-modal .modal-content {
  height: 100vh;
}
