.customer-many-capital-tax-calc-result {
  background-color: white;

  .customer-many-capital-tax-calc-result--row {
    padding-bottom: 1em;
  }
}

.customer-many-capital-tax-calc-result--col-value {
  display: flex;
  justify-content: flex-end;
}

.customer-many-capital-tax-calc-result--loading-container{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}