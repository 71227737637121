.date-range-quick-filter--disabled {
  opacity: 0.5;
}

.date-range-quick-filter--selected {
  font-weight: bold;
}

.quick-filter {
  color: var(--neutral-dark-grey);
  cursor: pointer;
}

.quick-filter:hover{
  text-decoration: underline;
  color: var(--default-lila)
}