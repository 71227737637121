/* ------------------------------------------------------------ *\
	Collapsible
\* ------------------------------------------------------------ */
.collapsible-box {
  width: 100%;
  padding-left: 0px;
}

.collapsible-box-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  align-items: center;
}

.collapsible-box-header-left {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.collapsible-box-collapsible-content {
  padding-left: 24px;
  transition: opacity 0.5s ease-in-out;
}

.collapsible-box-icon {
  height: 100%;
  font-size: 1.3em;
  font-weight: bold;
  cursor: pointer;
  padding-right: 10px;
}

.collapsible-box-title {
  height: 100%;
  padding-top: 0px;
}

.collapsible-box-collapsed {
  opacity: 0;
  height: 0px;
}

.collapsible-box-collapsed .info__body {
  height: 0px;
  display: none;
}

.collapsible-box-collapsed .info__foot {
  height: 0px;
  display: none;
}

.collapsible-box-collapsed .row {
  display: none;
}

.collapsible-box-collapsed .datagrid-container {
  display: none;
}

.collapsible-box-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
}

.collapsible-box-actions-hidden {
  opacity: 0;
  visibility: hidden;
}

.collapsible-box-actions-hidden .filter-menu-reset {
  opacity: 0;
  visibility: hidden;
}
