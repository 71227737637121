.setadvisor-form-limit-calculation--active {
  display: flex;
  align-items: center;
}

.setadvisor-form-limit-calculation--active--title {
  padding-left: 1rem;
}

.setadvisor-form-limit-calculation--active--hint {
  padding-left: 2rem;
  font-size: 0.8em;
  font-weight: 300;
}
