.setadvisor-business-body-pricedetail-overview-account-select {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.setadvisor-business-body-pricedetail-overview-account-select-title {
    font-weight: bold;
    cursor: pointer;
    font-size: 1.1em;
    text-align: right;
}

.setadvisor-business-body-pricedetail-overview-account-select-accounts {
  padding-top: 10px;
}
