.bpf-error-general {
  background-color: #ffffff;
  padding: 1em;
}

.bpf-error-general--header-row {
  font-size: 1em;
  font-weight: 700;
  border-bottom: 2px solid var(--bs-danger);
  padding-bottom: 0.2em;
}

.bpf-error-general--body-row {
  font-size: 0.9em;
  color: var(--bs-gray-500);
}

.bpf-error-general--body-row .btn {
  font-size: 0.9em;
}
