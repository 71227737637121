/*
.lead-form--field-col {
  display: flex;
  align-items: center;
  width: 100%;
}

.lead-form--field-col div[class^='lead-form-field'] {
  width: 100%;
}
  */

.lead-form--field-col--hidden {
  display: none;
}

.lead-form-field--static-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 2.7em; */
}

.lead-form-field--html-preview {
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  border-bottom: 0.05em solid var(--bs-gray-500);
}
