.lead-board-lead-content-evooq {
  color: #e48f62;
  padding-top: 0.7rem;
}

.lead-board-lead-content-evooq--title {
  font-weight: 700;
  padding-bottom: 0.1rem;
}

.lead-board-lead-content-evooq--link {
  cursor: pointer;
  text-decoration: underline;
}
