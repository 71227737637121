.consultation-form-private-body {
  font-size: 0.9em;
  height: 100%;
}

.consultation-form-private-modal-body-row {
  padding-bottom: 40px;
}

.consultation-form-private-modal-body-pricedetail-title {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.consultation-form-private-modal-body-pricedetail-icon {
  font-size: 1.5em;
}
