.l * {
  pointer-events: none;
}

.product-usage-modal .popover-header .modal-main-title {
  font-size: 18px;
  font-weight: 700;
}

.product-usage-modal .popover-header .modal-account-type {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.product-usage-modal .popover-header .modal-account-number {
  font-size: 14px;
  line-height: 24px;
}

.product-usage-modal .col {
  padding: 0;
}
.product-usage-modal .row {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.product-usage-modal--body .row {
  padding: 5px;
}

.product-usage-modal--body .row > * {
  padding-left: 0;
  padding-right: 0;
}

.product-usage-modal--body .label-icon {
  font-size: 17px;
  padding-right: 5px;
  display: inline-flex;
}
