.notification-settings-container {
    overflow: auto;
  }
  
  .subscription-item {
    border-radius: 4px;
    transition: all 0.2s ease;
  }
  
  .subscription-item:hover {
    background-color: #f8f9fa;
    transform: translateX(2px);
  }
  
  .mdi-bell {
    font-size: 1.2rem;
  }
  
  .mdi-bell-off {
    font-size: 1.2rem;
    opacity: 0.7;
  }
  
  .mdi-close-circle-outline {
    font-size: 1.1rem;
    opacity: 0.7;
    transition: all 0.2s ease;
  }
  
  .mdi-close-circle-outline:hover {
    opacity: 1;
    color: #dc3545 !important;
  }
  
  .subscription-label {
    font-size: 0.9rem;
  }
  
  .subscription-id {
    font-size: 0.75rem;
  }
  
  .list-header {
    font-size: 0.8rem;
    color: #6c757d;
    letter-spacing: 0.5px;
    padding: 0.5rem 0;
    border-bottom: 1px solid #dee2e6;
  }