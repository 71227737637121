.bpf-cms-customer-form-several-private-individuals--customer-address--label {
  display: flex;
  justify-content: flex-start;
}

.bpf-cms-customer-form-several-private-individuals--customer-address--label--icon {
  font-size: 1.7em;
  padding-right: 15px;
}

.bpf-cms-customer-form-several-private-individuals--customer-address--label--icon .mdi:before {
  vertical-align: unset;
}
