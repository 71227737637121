.issue-board-list-dropzones-container {
  position: fixed;
  display: none;
  height: 100%;
  width: 100%;
  z-index: 3;
  background-color: #ffffff;
}

.issue-board-list-dropzones-container--dragging {
  display: inherit;
  opacity: 0.95;
}
