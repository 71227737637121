.workguide-form-workflow--summary-overlay {
  position: absolute;
  z-index: 9;
  width: 99%;
  height: 100%;
  margin-top: -7%;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 7%;
  padding-left: 10%;
  padding-right: 20%;
}

.workguide-form-workflow--summary-overlay--header {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

