.financing-customer-tranches--loan-products-title-row {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  border-bottom: 1px solid var(--bs-gray-200)
}

.workguide-customer-financing-tranches .workguide-financing-tranches--loan-product-list .selectable-checkbox {
  display: none;
}
