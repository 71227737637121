.bpf-cms-identification-document-form-create--customer-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bpf-cms-identification-document-form-create--customer-list-item--form {
  display: flex;
  width: 90%;
}

.bpf-cms-identification-document-form-create--customer-list-item--form .form-group {
  min-width: 30%;
}

.bpf-cms-identification-document-form-create--customer-list-item--actions {
  font-size: 1.5em;
  color: lightgrey;
}

.bpf-cms-identification-document-form-create--customer-list-item--actions span {
  cursor: pointer;
}
