.filter-dropdown {
  width: fit-content !important;
}

.filter-dropdown--label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filter-dropdown--label--reset {
  color: red;
  font-size: 1.2em;
  padding-right: 0.1rem;
}

.filter-dropdown--label--reset--hidden {
  visibility: hidden;
}

.filter-dropdown--label--toggle--icon {
  font-size: 1.2em;
  padding-right: 0.4rem;
}
