.release-notes-icon--dropdown-item {
  display: flex;
  align-items: center;
}

.release-notes-icon--dropdown-item
.mdi:before,
.mdi-set {
  vertical-align: super;
}

.release-notes-icon--dropdown-item .active.mdi:before, .mdi-set {
  color: orange;
  font-size: 18px;
}

.ico-news {
  cursor: pointer;
}

.release-notes-header {
  text-align: left;
  position: relative;
  height: 70px;
}

.release-notes-header .modal-title {
  width: 100%;
}

.release-notes-modal {
  width: 80vw;
  max-width: 80vw;
}

.release-notes-modal .modal-body {
  background-color: #FAFAFA;
}

.release-notes-content {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}