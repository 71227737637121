.layout-header-powersearch {
  display: flex;
  height: 100%;
}

.layout-header-powersearch .powersearch-select__control::before {
  font-family: "Material Design Icons";
  content: "\F0349";
  -webkit-font-feature-settings: 'liga';
  vertical-align: -10%;
  font-size: 1.6em;
}

.layout-header-powersearch .powersearch .powersearch-select__control {
  border: 0px;
  width: auto;
  min-width: 20vw;
  max-width: 75vw;
  background-color: #E8E8E8;
  color: #818181;
  padding: 0 15px;
  transform: translate(0, 0);
  transition: all 0.5s;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  overflow: hidden;
}

.layout-header-powersearch .powersearch .powersearch-select__value-container--has-value {
  flex-wrap: nowrap;
}

.layout-header-powersearch .powersearch .powersearch-select__control .powersearch-selected--label {
  white-space: nowrap;
}

.layout-header-powersearch .powersearch .powersearch-select__control--is-focused {
  width: 75vw;
  border-bottom: 2px solid var(--bs-primary);
  transform: translate(0, 0);
  transition: all 0.5s;
  cursor: text;
}

.layout-header-powersearch .customer-workguide-search--help-icon {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.layout-header-powersearch .customer-workguide-search--help-icon--focused {
  border-bottom: 2px solid var(--bs-primary);
  transform: translate(0, 0);
  transition: all 0.5s;
}

.layout-header-powersearch .customer-workguide-search {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 30vw;
  height: 100%;
}

.layout-header-powersearch .customer-workguide-search .powersearch-menu--show-more {
  color: #7152ac;
  font-size: 14px;
}

.layout-header-powersearch .powersearch-select__menu {
  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  width: 75vw;
  padding-left: 20px;
  padding-top: 20px;
}

.layout-header-powersearch .powersearch-list--title {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 20px;
  line-height: 14px;
}

.layout-header-powersearch .powersearch-list--no-data {
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 20px;
  line-height: 14px;
}
