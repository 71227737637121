.bpf-cms-profile-completion-several-private-individual--customer--missing-properties .bpf-cms-profile-completion-missing-properties {
  padding-bottom: 0.8rem;
}

.bpf-cms-profile-completion-several-private-individual--customer--missing-properties--person-partner {
  padding-left: 0px;
}

.bpf-cms-profile-completion-several-private-individual--customer--missing-properties--person-partner .bpf-cms-profile-completion-missing-properties--title {
  padding-left: 0px;
}

.bpf-cms-profile-completion-several-private-individual--customer--missing-properties--person-partner .bpf-cms-profile-completion-missing-properties--row--category {
  padding-left: 0.75rem;
}
