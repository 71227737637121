.chat-overlay-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 40px;
  background-color: #00a5d5;
  cursor: pointer;
  z-index: 5;
}

.chat-overlay-toggle-text {
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  cursor: pointer;
  color: #fff;
  min-width: 90px;
}

.chat-overlay-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 0px;
  height: 0px;
  background-color: #fff;
  transition: width 1s, height 1s;
  box-shadow: -5px 6px 4px -6px lightgray;
  border-left: 0.5px solid lightgray;
  border-right: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;
}

.chat-overlay-content-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 150px;
  border-top: 1px solid lightgray;
}

.chat-overlay {
  position: fixed;
  display: flex;
  top: 140px;
  right: 0%;
  height: auto;
  z-index: 200;
}

.button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.message-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #e40531;
  left: -15px;
  top: 15px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.message-badge p {
  color: white;
  font-weight: bold;
}
