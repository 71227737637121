.activities-list-item {
  border-left: none;
  border-right: none;
}

.activities-list-item-content {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
}

.activities-list-item-content--line {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  width: 100%;
}

.activities-list-item-content--origin-icon {
  font-size: 1.1em;
}

.activities-list-item-content--consultant-name {
  padding-top: 2px;
}

.activities-list-item-expanded-content {
  padding-bottom: 10px;
  padding-left: 20px;
}

.activities-list-item-content--activity-type {
  align-self: flex-start;
  width: 20px;
}

.activities-list-item--customer .activities-list-item-content--activity-type {
  margin-top: -2px;
}

.activities-list-item-content--preview {
  max-height: 20px;
  overflow: hidden;
  padding-left: 20px;
}

.activities-list-item-content--clone-icon {
  font-size: 1.1em;
  height: 22px;
  color: '#7152ac';
  padding-left: 5px;
}
.activities-list-item-content--edit-icon {
  font-size: 1.1em;
  margin-right: 5px;
}
