.setadvisor-workguide-credit-card .validatable-messages {
  padding-top: 0.5rem;
}

.setadvisor-workguide-credit-card--buttons {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
}
