.global-dropzone-modal--saving-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.global-dropzone-modal--saving-loader--text {
  padding-bottom: 0.5em;
}

.global-dropzone-modal .modal-title{
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-dropzone-modal #global-dropzone-modal--info-popover {
  display: flex;
}

.global-dropzone-modal--info--popover .global-dropzone-modal--info--popover--list {
  padding-left: 1em;
}
