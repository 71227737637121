.setadvisor-form-limit-calculation--monthly-expense {
  padding-top: 2rem;
}

.setadvisor-form-limit-calculation--monthly-expense--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 2rem;
}

.setadvisor-form-limit-calculation--title .mdi {
  font-size: 1.4em;
  cursor: pointer;
  color: #666666;
}
