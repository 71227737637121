.customer-contract-expanded-row-collapse--title-row {
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid lightgray;
  font-weight: bold;
  font-size: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  cursor: pointer;
}

.customer-contract-expanded-row-collapse--title-row--collapse-icon {
  font-size: 1.1em;
  width: auto;
}

.customer-contract-expanded-row-collapse--content {
  padding-top: 1em;
}