.linked-leads {
  margin-bottom: 10px;
}

.linked-leads__list {
  display: flex;
}

.linked-leads__list > :last-child {
  margin-right: 0;
}

.linked-leads__card-container {
  display: block;
  margin: 0 10px 10px 0;
  padding: 5px;
}
