.ui-note-banner {
  height: 100%;
  background-color: #ffffff;
  padding: 4em;
  padding-left: 12em;
  padding-right: 15em;
}

.ui-note-banner .row {
  padding-bottom: 3em;
}

.ui-note-banner .row {
  padding-bottom: 3em;
}

.ui-note-banner .row:last-of-type {
  padding-bottom: 0em;
}
