.bpf-cms-profile-completion-progress {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
}

.bpf-cms-profile-completion-progress--bar {
  display: flex;
  align-items: center;
}

.bpf-cms-profile-completion-progress--label {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.bpf-cms-profile-completion-progress--value {
  white-space: nowrap;
  font-weight: bold;
}

.bpf-cms-profile-completion-progress--progress {
  width: 100%;
  padding-right: 10px;
}
