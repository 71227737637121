.open-option-comfort--customer-display {
  color: var(--valiant-neutral-black);
  display: block;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 26px !important;
}

.open-option-comfort--title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px !important;
}

.open-option-comfort--disclaimer {
  color: var(--valiant-neutral-black);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.open-option-comfort--action-buttons {
  margin-top: 28px !important;
}
