.workguide-authorization-list-item {
  width: 100%;
}

.workguide-authorization {
  display: flex;
  width: 100%;
}

.workguide-authorization-checkbox {
  align-self: center;
  padding: 10px;
  height: 100%;
}

.workguide-authorization-checkbox i {
  font-size: 1.5em;
  color: #614692;
}

.workguide-authorization-description {
  display: flex;
  flex-wrap: wrap;
}

.workguide-authorization-description-item {
  padding-right: 30px;
}
