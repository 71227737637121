.provision-bvg-classification--label-row {
  padding-bottom: 0.5em;
}

.provision-bvg-classification--description-col {
  color: lightgrey;
}

.provision-bvg-classification--document-change-beneficiary-desired-col {
  padding-top: 1em;
  padding-left: 4.8em;
}
