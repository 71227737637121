.html-preview {
    line-height: 1.4em;
}

.html-preview__h1,
.html-preview__h2,
.html-preview__h3,
.html-preview__h4,
.html-preview__h5,
.html-preview__h6 {
    padding: 0 !important;
    margin: 0 0 1em 0 !important;
}

.html-preview li {
    border: none !important;
    padding: 0 !important;
    margin: 0 0 0.5em 17px !important;
}

.html-preview__blockquote {
    padding: 10px 20px !important;
    margin: 0 0 1em 0 !important;
    border-left: 5px solid #eef3f6;
}

.html-preview__table {
    border-collapse: collapse;
}

.html-preview__tr {
    border-bottom: 1px solid grey;
}

.html-preview__td,
.html-preview__th {
    padding-left: 10px;
}

.html-preview__code {
    font-size: 0.875em;
    color: #e83e8c;
    word-break: break-word;
    font-family: var(--font-family-monospace);
}

.html-preview__a {
    color: #7152ac;
    text-decoration: underline;
}

.html-preview__h1:last-child,
.html-preview__h2:last-child,
.html-preview__h3:last-child,
.html-preview__h4:last-child,
.html-preview__h5:last-child,
.html-preview__h6:last-child,
.html-preview__p:last-child,
.html-preview__blockquoute:last-child,
.html-preview__pre:last-child,
.html-preview__table:last-child,
.html-preview__ol:last-child,
.html-preview__ul:last-child,
.html-preview__li:last-child {
    margin-bottom: 0 !important;
}