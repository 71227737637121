.communication-tab-container {
  height: 100%;

  .communication-tab {
    border: none;
    padding-left: 1em;
    height: 7%;
    align-items: center;
    background-color: white;


    .communication-tab-item {

      .nav-link {
        text-decoration: none;
        border: none;
        padding: 1em 2em;

        .communication-tab-item-underline {
          padding-bottom: 0.6em;
          font-weight: 550;
        }

        &:hover {
          border: none;
        }

        &.active {
          background-color: var(---communication-tab-container);
          color: var(--default-lila);
          .communication-tab-item-underline {
            border-bottom: solid 2px var(--default-lila);
            font-weight: 700;
          }
        }
      }
    }
  }

  .tab-content {
    height: 93%;
    padding-top: 15px;
    .tab-pane {
      &.active {
        height: 100%;
        .row {
          height: 100%;
        }
      }
    }
  }

}