.customer-form-duplicates-check--duplicates-list {
  max-width: 60%;
}

.customer-form-duplicates-check--duplicates-list-inner {

}

.customer-form-duplicates-check--duplicate-items {
  display: flex;
}
