.workguide-datefield-icon {
  font-size: 1.2em;
  padding: 0px;
}

.workguide-datefield-icon i {
  font-size: 1.2em;
  line-height: 1;
  cursor: pointer;
  padding-left: 0.3em;
  padding-right: 0.3em;
  margin-top: -0.2em;
}
